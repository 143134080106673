import React from 'react';
import { object, string } from 'prop-types';
import cn from 'classnames';

import ExperienceDateAndLocation from 'components/ExperienceDateAndLocation';
import ImageNotFound from "images/no-image-box.png";

import './styles.css';

const CheckoutHeader = ({ experience, className = "", ...props }) => {
  const { description, name, images, startTime, endTime, location } = experience;
  return (
    <div className={cn('clearfix checkout-header-container', className)} {...props}>
      <ExperienceDateAndLocation
        startTime={startTime}
        endTime={endTime}
        location={location}
        isCheckout
      />
      <div className="image-title clearfix">
        <div className="checkout-header-image-wrapper">
          <img
            className="checkout-header-image"
            src={images.length ? images[0].url : ImageNotFound}
            alt={description}
          />
        </div>
        <h1 className="checkout-header-title">{name}</h1>
      </div>
    </div>
  );
};

CheckoutHeader.propTypes = {
  experience: object.isRequired,
  className: string
};

export default CheckoutHeader;
