import fetch from 'isomorphic-fetch';
import { camelizeKeys } from 'humps';
import { browserHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import _ from 'lodash';
import { getAccessToken, toUrlParameters } from 'utils/helpers';

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE';

const { REACT_APP_API_ROOT } = process.env;

// Fetches an API response
const callApi = async (method, endpoint, body, params, contentType) => {
  const headers = new Headers();
  // headers.append('Access-Control-Allow-Origin', '*');
  headers.append('Accept', 'application/json; text/csv');

  const token = getAccessToken();

  if (token) {
    headers.append('x-access-token', `${token.accessToken}`);
  }
  const options = {
    method,
    headers,
    mode: 'cors',
    cache: 'default',
    credentials: 'include'
  };

  // WORKAROUND oauth calls work with form urlencoded
  const useFormEncoded = /oauth/i.test(endpoint);
  if (useFormEncoded) {
    options.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
  } else if (!/get|delete/i.test(method)) {
    options.headers.append('Content-Type', 'application/json');
  }

  if (!isEmpty(body)) {
    if (useFormEncoded) {
      options.body = toUrlParameters(body);
    } else {
      options.body = JSON.stringify(body);
    }
  }

  let url = REACT_APP_API_ROOT + endpoint;

  if (params) {
    url += `?${toUrlParameters(params)}`;
  }

  if (contentType === 'text/csv') {
    return fetch(url, options)
      .then((response) => {
        if (response.status === 401) {
          browserHistory.push('/signup');
          return Promise.reject(response);
        }
        return response;
      });
  }
  const response = await fetch(url, options);
  if (response.status === 401) {
    browserHistory.push('/signup');
    return Promise.reject(response);
  }
  if (response.status === 204 || response.ignore) {
    return Promise.resolve();
  }
  const json = await response.json();
  if (!response.ok) {
    return Promise.reject(json);
  }
  return camelizeKeys(json);
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
export const SIGN_OUT_FROM_API = Symbol('Sign out from API');

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const middleware = store => next => (action) => {
  const signOut = () => {
    // FIXME API doesn't have a call to signout
    store.dispatch({ type: SIGNOUT_SUCCESS });

    // redirect to login
    browserHistory.push('/login');
    return;
  };

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const reject = (rejection, actionType) => {
    // dispatch error from API
    const message = rejection.error ||
      rejection.message ||
      rejection.statusText ||
      rejection.details ||
      (rejection.detail && _.get(JSON.parse(rejection.detail), 'message')) ||
      'Unknown error';

    store.dispatch(actionWith({
      type: actionType,
      error: true,
      payload: {
        ...rejection,
        _error: message,
      },
    }));
    return Promise.reject(rejection);
  };

  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    const signOutFromAPI = action[SIGN_OUT_FROM_API];
    if (typeof signOutFromAPI === 'undefined') {
      return next(action);
    }
    return signOut();
  }

  const { method, endpoint, body, params, types, contentType } = callAPI;

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const [requestType, successType, failureType] = types;

  // dispatch request
  store.dispatch(actionWith({ type: requestType }));
  return callApi(method, endpoint, body, params, contentType).then(
    (response) => {
      // dispatch response
      store.dispatch(actionWith({
        params,
        response,
        type: successType,
      }));
      return Promise.resolve(response);
    },
    rejection => reject(rejection, failureType),
  );
};

export default middleware