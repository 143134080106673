import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router';

const ChannelDescription = ({ channel: { name, images, about, color, urlSlug, website }, channel }) => (
  <DescriptionWrapper>
    <ChannelWrapper>
      <ImageWrapper backgroundColor={color}>
        <ImageList alt={name} src={images[0]} width="94px" height="94px" />
      </ImageWrapper>
      <Title>{name}</Title>
      <Description>{about}</Description>
      {
        website && (
          <ChannelURL
            onClick={() => window.open('https://' + website, '_blank')}
          >
            {website}
          </ChannelURL>
        )
      }
    </ChannelWrapper>
  </DescriptionWrapper>
);

ChannelDescription.propTypes = {
  channel: PropTypes.object,
};

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px 0px;
  background: #ffffff;
`;

const ChannelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 754px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  margin: 10px;
  margin-bottom: 15px;
  background-color: #000;
  text-align: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ImageList = styled.img`
  width: 75px;
  height: 75px;
  object-fit: 'cover';
  margin-top: 25%;
  object-position: center center;
`;

const Title = styled.h3`
  font-family: 'CircularStd Book';
  font-weight: 600;
  font-size: 24px;
  margin: 0px;
  text-align: center;
`;

const Description = styled.div`
  font-family: 'CircularStd Book';
  font-weight: 200;
  font-size: 18px;
  line-height: 33.5px;
  padding: 8px 0;
  text-align: center;
  color: #626262;
`;

const ChannelURL = styled(Link)`
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 29.5px;
  text-align: center;
  padding-bottom: 42px;
`;

export default ChannelDescription;
