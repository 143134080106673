import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from "react-redux";
import useFestivalInformation, { useUpdateFestival } from 'hooks/useFestivalInformation';
import { selectCurrentChannel } from "selectors/channels";
import Footer from 'components/WizardComponents/Footer';
import Snackbar from 'components/WizardComponents/Snackbar';
import TicketItem from 'components/WizardComponents/TicketItem';

import validate from '../Validations/tickets';
import { ticketSortFunction } from 'utils/helpers';
import { DEFAULT_TICKET_DESCRIPTIONS } from 'appconstants';

const TicketStep = ({
  params,
  nextStep,
  renderSteps,
  currentChannel
}) => {
  const { isLoading, data } = useFestivalInformation(params.id);
  const mutate = useUpdateFestival(params.id, 'tickets');

  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState('');
  const [dataTickets, setDataTickets] = useState([])
  useMemo(() => data ? setDataTickets[data.tickets] : setDataTickets([]), [data])
  useEffect(() => {
    if (data && data.tickets && data.tickets.length > 0) {

      setTickets(data.tickets.sort(ticketSortFunction));
    } else if (data && data.tickets && data.tickets.length === 0) {
      setTickets([
        {
          days: moment(data.endTime).diff(moment(data.startTime), 'days') + 1,
          description: 'All access',
          price: currentChannel?.isFree ? 0 : 0.99,
          total: 50,
          allAccess: true,
          isTicket: false,
          longDescription: DEFAULT_TICKET_DESCRIPTIONS.ALL_ACCESS,
          visibility: true,
        },
        {
          days: 1,
          description: '1-day pass',
          price: currentChannel?.isFree ? 0 : 0.99,
          allAccess: false,
          total: 50,
          isTicket: false,
          longDescription: DEFAULT_TICKET_DESCRIPTIONS.RESTRICTED_ACCESS,
          visibility: true,
        },
      ]);
    }
  }, [data]);

  const updateTicket = useCallback(
    (index, data) => {
      const newTickets = [...tickets];

      let newTicket = {
        ...newTickets[index],
        ...data,
      }

      if (data.description) {
        newTicket.allAccess = !data.isTicket && data?.description?.toLowerCase().includes('all');
      }

      newTickets[index] = newTicket;

      setTickets(newTickets);
    },
    [tickets, setTickets]
  );

  const deleteTicket = useCallback(
    (index) => {
      const newTickets = [...tickets];
      newTickets.splice(index, 1);

      setTickets(newTickets);
    },
    [setTickets, tickets]
  );

  const save = useCallback(() => {
    if (validate(tickets)) {
      const ticketsToSend = tickets.map(
        ({ description, _id, longDescription, isTicket, allAccess, price, total, visibility, days, name }) => ({
          days,
          description,
          id: _id,
          longDescription,
          price: price || 0,
          total,
          name,
          isTicket,
          allAccess,
          visibility,
        })
      );

      if (JSON.stringify(ticketsToSend) !== JSON.stringify(dataTickets)) {
        mutate.mutate({ tickets: ticketsToSend });
      }

      nextStep();
    } else {
      setError('Please check tickets information');
    }
  }, [nextStep, mutate, tickets, dataTickets]);

  const ticketOptions = useMemo(() => {
    const options = [];
    if (!data || !data.startTime) {
      return options;
    }

    const { startTime, endTime } = data;

    const start = moment(startTime);
    const end = moment(endTime);

    const days = end.diff(start, 'days');
    
    options.push({
      description: 'All access',
      days: days + 1,
      allAccess: true,
    });

    for (let i = 1; i <= days; i += 1) {
      options.push({
        description: `${i}-day pass`,
        days: i,
        allAccess: false,
      });
    }

    return options;
  }, [data]);

  const addTicket = useCallback(
    (free = false, isTicket = false) => {
      const newTickets = [...tickets];

      let newTicketOption = {
        isTicket: true,
        description: '1 on 1 coaching',
        name: '1 on 1 coaching',
      };

      let longDescription = '';

      if (!isTicket) {
        newTicketOption = ticketOptions.filter(({ description }) =>
          newTickets.findIndex(({ description: ticketDescription }) => description === ticketDescription) === -1 && !description.toLowerCase().includes('all')
        );

        if (newTicketOption.length) {
          newTicketOption = {
            ...newTicketOption[0],
            isTicket: false,
          }
        }

        longDescription = newTicketOption.description.toLowerCase().includes('all')
        ? DEFAULT_TICKET_DESCRIPTIONS.ALL_ACCESS
        : DEFAULT_TICKET_DESCRIPTIONS.RESTRICTED_ACCESS;
      }

      newTickets.push({
        free,
        price: free ? 0 : 0.99,
        total: 50,
        visibility: true,
        longDescription,
        ...newTicketOption,
      });

      setTickets(newTickets);
    },
    [tickets, setTickets, ticketOptions]
  );

  if (!(data && data._id)) {
    return null;
  }
  return (
    <>
      {renderSteps(save)}
      <Snackbar error={error} onClose={() => setError('')} />
      <Header>
        <HeaderItem width="359">Description</HeaderItem>
        <HeaderItem width="92">Qty</HeaderItem>
        <HeaderItem width="130">Cost</HeaderItem>
      </Header>
      <TicketsContainer>
        {tickets.map((ticketInfo, index) => (
          <TicketItem
            key={index}
            options={ticketOptions}
            ticketIndex={index}
            ticketInfo={ticketInfo}
            tickets={tickets}
            isTicket={ticketInfo.isTicket}
            originalTickets={data.tickets}
            updateTicket={updateTicket}
            onDelete={() => deleteTicket(index)}
          />
        ))}
        <AddTickets>
          {!currentChannel?.isFree &&
          <AddTicketButton
            onClick={() => addTicket(false, true)}
          >
            + Add paid ticket
          </AddTicketButton>
          }
                    {!currentChannel?.isFree &&
          <AddTicketButton
            disabled={tickets.filter(({ isTicket }) => !isTicket).length === ticketOptions.length}
            onClick={() => addTicket(false)}
          >
            + Add paid pass
          </AddTicketButton>
}
          <AddTicketButton
            disabled={tickets.filter(({ isTicket }) => !isTicket).length === ticketOptions.length}
            onClick={() => addTicket(true)}
          >
            + Add free pass
          </AddTicketButton>
        </AddTickets>
      </TicketsContainer>
      <Footer
        status={data.published}
        lastSaved={data.updatedAt}
        onNextStep={save}
        saving={isLoading}
      />
    </>
  );
};

const AddTicketButton = styled.button`
  background-color: #F5F5F5;
  border-radius: 17.5px;
  border: 2px solid #d5d5d5;
  box-sizing: border-box;
  color: #4d4d4d;
  cursor: pointer;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  height: 35px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 10px;
  min-width: 121px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  & + & {
    margin-left: 10px;
  }
`;

const AddTickets = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 644px;
  justify-content: space-between;
`;

const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderItem = styled.div`
  color: #c0c0c0;
  font-family: 'CircularStd Book';
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  width: ${({ width }) => width}px;
`;

const mapStateToProps = (state) => ({
  currentChannel: selectCurrentChannel(state),
});

export default connect(mapStateToProps)(TicketStep);
