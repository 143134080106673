import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Card} from 'material-ui/Card';
import Snackbar from 'material-ui/Snackbar';
import SetupStripeWrapper from 'containers/SetupStripe/SetupStripeWrapper';
import { getPayouts } from 'actions/payouts';
import PayoutsList from 'components/PayoutsList';
import { PAYOUT_LIST_TYPES } from 'appconstants';

const colors = {
  green: '#69D27C',
  red: 'rgba(255, 0, 13, 0.88)',
  yellow: '#FEBD21',
};

const styles = {
  snackbar: {
    backgroundColor: colors.red,
  },
  card: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
  },
  payoutsCard: {
    textAlign: 'left',
    marginTop: '10px',
    maxWidth: '900px',
  }
};

let PaymentSettings = ({ handleSubmit, getPayouts, error, errorMessage, submitFailed, submit, payouts, loadingPayouts, payoutError, currentChannel }) => {
  const [showErrors, setShowErrors] = useState(true);
  const message = error || errorMessage;
  const open = showErrors && submitFailed && message;

  useEffect(() => {
    if (currentChannel?.urlSlug) {
      getPayouts(currentChannel.urlSlug);
    }
  }, [currentChannel, getPayouts])

  const payoutsTable = useMemo(() => {
    if (payoutError) {
      return 'An error occurred. Please try again later.';
    }

    return <PayoutsList loading={loadingPayouts} payouts={payouts} layout={PAYOUT_LIST_TYPES.CREATOR} />
  }, [payouts, loadingPayouts, payoutError])

  return (
    <div className="container-settings">
      <form className="settings-form" onSubmit={handleSubmit((values) => submit(values))}>
        <Card style={styles.card}>
          <SetupStripeWrapper settings />
        </Card>
      </form>
      <Card style={{ ...styles.card, ...styles.payoutsCard}}>
        <Title>
          Earnings
        </Title>
        {payoutsTable}
      </Card>
      <Snackbar
        open={!!open}
        message={message}
        autoHideDuration={4000}
        bodyStyle={styles.snackbar}
        onRequestClose={() => setShowErrors(false)}
      />
    </div>
  );
}

const Title = styled.h1`
  font-family: CircularStd Book;
  font-weight: normal;
  font-size: 20px;
  color: #000000;
`;

PaymentSettings.propTypes = {
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  submitFailed: PropTypes.bool,
};

PaymentSettings = reduxForm({
  form: 'PaymentSettingsForm',
})(PaymentSettings);

const mapStateToProps = (state) => {
  const form = state.form.NotificationProfileForm;
  const errors = form && (form.syncErrors || (form.submitErrors && 'Submit failed!'));
  return {
    errors,
    errorMessage: errors && errors[Object.keys(errors)[0]],
    payouts: state.payouts.payouts,
    loadingPayouts: state.payouts.loading,
    payoutError: state.payouts.error,
    currentChannel: state.channels.currentChannel,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getPayouts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettings);
