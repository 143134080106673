import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Check from 'material-ui/svg-icons/action/check-circle';
import Loading from 'components/Loading';

import moment from 'moment-timezone';
import every from 'lodash/every';
import head from 'lodash/head';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';

const items = [{
  title: 'Description',
  getText: exp => (
    // eslint-disable-next-line no-nested-ternary
    exp.description ? (
      exp.description.length > 140 ? `${exp.description.substring(0, 140)}...` : exp.description
    ) : 'Please leave a description'
  ),
  getColor: exp => (exp.description ? 'green' : 'red'),
}, {
  title: 'Date',
  getText: (exp) => {
    const timezone = moment().tz(moment.tz.guess()).zoneAbbr();
    if (exp.startTime && exp.endTime) {
      return `${moment(exp.startTime).format('Do MMM, h:mma')} - ${moment(exp.endTime).format('Do MMM, h:mma')} ${timezone}`;
    }
    return 'A date is required to publish this experience';
  },
  getColor: exp => (exp.startTime && exp.endTime ? 'green' : 'red'),
}, {
  title: 'Tickets',
  getText: (exp) => {
    if (exp.tickets.length > 0) {
      const sortedTickets = sortBy(exp.tickets, 'price');
      const h = head(sortedTickets).price;
      const l = last(sortedTickets).price;
      if (h !== undefined) {
        if (h === l) {
          return `$${h}`;
        }
        return `$${h} - $${l}`;
      }
    }
    return 'Ticket pricing is required to publish this experience';
  },
  getColor: exp => (exp.tickets.length > 0 && exp.tickets[0].price !== undefined ? 'green' : 'red'),
}, {
  title: 'Location',
  getText: (exp) => {
    if (exp.location) {
      return `${exp.location.address}, ${exp.location.city} ${exp.location.zip}`;
    }
    return 'A location must be entered to publish this experience';
  },
  getColor: exp => (exp.location ? 'green' : 'red'),
}, {
  title: 'Artists',
  getText: (exp) => {
    const featuredArtist = exp.artists.some(item => item.name);
    if(!featuredArtist){
      return 'No artists were featured';
    }
    return `Featuring: ${exp.artists.map(artist => (artist.name)).join(', ')}`;
  },
  getColor: exp => (exp.artists.length > 0 && exp.artists.some(item => item.name) ? 'green' : 'yellow'),
}, {
  title: 'Food',
  getText: (exp) => {
    if (exp.drinks.length > 0 || exp.food.length > 0) {
      return `${exp.drinks.map(drink => (drink.name)).concat(exp.food.map(food => (food.name))).join(', ')}`;
    }
    return 'There will be no food or drinks';
  },
  getColor: exp => (exp.drinks.length > 0 || exp.food.length > 0 ? 'green' : 'yellow'),
}, {
  title: 'Guidelines',
  getText: (exp) => {
    if (exp.guidelines.length === 1) {
      return '1 guideline';
    } else if (exp.guidelines.length > 1) {
      return `${exp.guidelines.length} guidelines`;
    }
    return 'Use guidelines to let guests know how to show up';
  },
  getColor: exp => (exp.guidelines.length > 0 ? 'green' : 'yellow'),
}, {
  title: 'Cover Photo',
  getText: (exp) => {
    if (exp.images.length === 0) {
      return 'Please upload an image for your experience.';
    }
    return 'Cover successfully uploaded.';
  },
  getColor: (exp) => {
    if (exp.images.length === 0) {
      return 'red';
    }
    return 'green';
  },
}, {
  title: 'Safety Agreements',
  getText: (exp) => {
    if (every(exp.safetyAgreements, 'checked')) {
      return 'You agree to all safety agreements';
    }
    const uncheckedCount = exp.safetyAgreements.filter(safetyAgreement => (!safetyAgreement.checked)).length;
    let agreementText = 'agreement';
    if (uncheckedCount > 1) {
      agreementText += 's are';
    } else {
      agreementText += ' is';
    }
    return `${uncheckedCount} safety ${agreementText} unchecked. Please complete to publish`;
  },
  getColor: exp => (every(exp.safetyAgreements, 'checked') ? 'green' : 'red'),
}];

const colors = {
  green: '#69D27C',
  red: '#FF6767',
  yellow: '#FEBD21',
};

class ConfirmPage extends Component {

  renderItem(title, text, color, index) {
    let subtitleColor = color;
    if (subtitleColor === colors.green) {
      subtitleColor = undefined;
    }

    if (this.props.experience && this.props.experience.festival && title === 'Tickets') {
      return null;
    }

    return (
      <div className="confirm-item" key={title} onClick={() => { this.props.goToPage(index); }}>
        <Check style={{ color, height: '29px', width: '29px' }} />
        <div>
          <div className="confirm-item-title">{title}</div>
          <div className="confirm-item-subtitle" style={{ color: subtitleColor }}>{text}</div>
        </div>
      </div>
    );
  }

  render() {
    const {loading} = this.props;

    return (
      loading ?
        <Loading message="Loading Publish confirm ..." />
      :
        <div className="confirm-wrapper experience-wrapper">
          <div className="confirm-container">
            {items.map((item, index) => (this.renderItem(item.title, item.getText(this.props.experience), colors[item.getColor(this.props.experience)], index + 1)))}
          </div>
        </div>
    );
  }
}

ConfirmPage.propTypes = {
  experience: PropTypes.any,
  goToPage: PropTypes.func,
  loading: PropTypes.func,
};

ConfirmPage = connect(state => ({
  experience: state.experiences.experience,
}))(ConfirmPage);

export default ConfirmPage;
