import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Loading from 'components/Loading';

const VirtualLocalizationPage = props => {
  const { locationsVirtuals, loading } = props;

  return (
    loading
    ?
      <Loading message="Loading Locations..." />
    :
      <div className="location-wrapper experience-wrapper">
        <Field name="location.platform" component="select">
          <option value="" id="default">Select Platform</option>
          {
          locationsVirtuals.map((item, index) => {
            return (
              <option key={index} value={item.id}>{item.description}</option>);
          })
        }
        </Field>
        <Field name="location.url" placeholder="Enter URL" component="input" type="text" />
      </div>
  );
};

VirtualLocalizationPage.propTypes = {
  locationsVirtuals: PropTypes.array,
  loading: PropTypes.bool,
};


export default VirtualLocalizationPage;
