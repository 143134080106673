import React from 'react';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    margin: 20,
    flex: 1,
  },
};

const TermsPage = () => {
  return (
    <div style={styles.root}>
      <h1>Privacy Policy</h1>
      <p>&quot;REACH PRESENCE LLC&quot; only collects personal information from site visitors on a voluntary basis. This information may include but is not limited to cookies.</p>
      <p>Future renditions of the application may collect additional data, but this Privacy Policy will be updated to reflect that.</p>
      <p>This information may be shared with &quot;REACH PRESENCE LLC&quot;&apos;s licensees, agents, successors and assigns.  All personal information collected via this website will only be used for its intended purpose.</p>
      <p>If there is a change in use of such information, notification will be explicitly stated within the contents of the Applications.</p>
      <p>&quot;REACH PRESENCE LLC&quot; does not compile, buy, sell, rent or trade consumer mailing lists. </p>
      <h2>User Access</h2>
      <h2>Third party distribution</h2>
      <p>Personal information provided to &quot;REACH PRESENCE LLC&quot; generally will not be disseminated to third parties.</p>
      <h2>Use of cookies and log files</h2>
      <p>&quot;REACH PRESENCE LLC&quot; reserves the right to analyze its Web site logs in an effort to improve the value of its site (e.g., improve the content and support services on &quot;REACH PRESENCE LLC&quot; application, and to update our records). However, the log files are not personally identifiable and &quot;REACH PRESENCE LLC&quot; makes no attempt to link site logs with individuals that pass through our site. Only authorized &quot;REACH PRESENCE LLC&quot; staff have access to our site information. &quot;REACH PRESENCE LLC&quot; does not use cookies on its site. &quot;REACH PRESENCE LLC&quot; does not supplement user information with aggregate data.  &quot;REACH PRESENCE LLC&quot; will not share any of this information with any third party.  &quot;REACH PRESENCE LLC&quot; does not respond to web browser &quot;do not track&quot; signals.</p>
      <h2>Data security</h2>
      <p>The security of the information you provide to &quot;REACH PRESENCE LLC&quot; is important to us. Information is stored in a secure area and only accessible by authorized &quot;REACH PRESENCE LLC&quot; staff and the staff if its sponsors, partner organizations and artists. However, the &quot;REACH PRESENCE LLC&quot; site includes links to other sites, and when you choose to visit those sites, &quot;REACH PRESENCE LLC&quot; cannot assume responsibility for any other site¹s content or information handling practices. We encourage you to review each site¹s privacy policy prior to entering into transactions with the third-party linked sites.</p>
      <h2>&quot;REACH PRESENCE LLC&quot; contacts</h2>
      <p>We can be reached via e-mail at <a href="mailto:info@domain.com">info@domain.com</a> with privacy in the subject matter line.</p>
      <p>Last revised on January 15, 2017</p>
    </div>
  );
};

export default TermsPage;
