import React from "react";
import { object, func, node } from "prop-types";
import { StripeProvider, Elements } from 'react-stripe-elements';
import CheckoutFormMobile from "../CheckoutFormMobile";
import "./styles.css";

const PaymentMobile = ({ paymentDetails, showGuidelines, header }) => (
  <div className="payment-mobile-container">
    <StripeProvider apiKey={process.env.REACT_APP_STRIPEKEY}>
      <Elements
        fonts={[
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Roboto&display=swap",
          },
        ]}
      >
        <CheckoutFormMobile
          header={header}
          clientSecret={paymentDetails.clientSecret}
          showGuidelines={showGuidelines}
        />
      </Elements>
    </StripeProvider>
  </div>
);


PaymentMobile.propTypes = {
  paymentDetails: object.isRequired,
  showGuidelines: func.isRequired,
  header: node.isRequired
};

export default PaymentMobile;
