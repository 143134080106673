import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';

const styles = {
  root: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  icon: {
    width: 'auto',
    right: '0',
    display: 'block',
  },
  label: {
    color: '#4D4D4D',
    fontFamily: "CircularStd Book",
    fontSize: '18px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '23px',
    textAlign: 'left',
  },
  thumbStyle: {
    top: '9px',
    height: '25px',
    width: '25px',
    left: '6px',
  },
  trackStyle: {
    height: '35px',
    width: '65px',
  },
  thumbSwitched: {
    backgroundColor: '#ffffff',
    left: '38px',
  },
  trackSwitched: {
    backgroundColor: '#9B63F8',
  },
};

export default class TGNotificationToggle extends Component {
  checkNotification() {
    const { user, type, label } = this.props;

    for (let index = 0; index < user.notifications.length; index += 1) {
      if (user.notifications[index].type === type) {
        return user.notifications[index].enabled;
      }
    }

    // Add the notification to user's notification list, if he don't have it.
    user.notifications.push({ type, label, enabled: false });

    return false;
  }

  toggleNotification = () => {
    const { user, type } = this.props;

    for (let index = 0; index < user.notifications.length; index += 1) {
      if (user.notifications[index].type === type) {
        user.notifications[index].enabled = !user.notifications[index].enabled;
      }
    }
  }

  render() {
    return (
      <Toggle
        defaultToggled={this.checkNotification()}
        onToggle={this.toggleNotification}
        labelPosition="left"
        label={this.props.label}
        style={styles.root}
        iconStyle={styles.icon}
        labelStyle={styles.label}
        thumbStyle={styles.thumbStyle}
        trackStyle={styles.trackStyle}
        thumbSwitchedStyle={styles.thumbSwitched}
        trackSwitchedStyle={styles.trackSwitched}
      />
    );
  }
}

TGNotificationToggle.propTypes = {
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
};
