import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { map } from 'lodash';

const TabMenu = ({items, tabDisabled, itemSelected, handlerSelect}) => (
  <div className="container-list-tab">
    { items && map(items, (item) => {
      return tabDisabled !== item && (
        <div key={item}>
          <Link
            className={itemSelected === item ? "tab-item-menu-selected" : "tab-item-menu"}
            onClick={() => handlerSelect(item)}
          >
            {item}
          </Link>
        </div>
      );
    }) }
  </div>
);

TabMenu.propTypes = {
  items: PropTypes.object,
  tabDisabled: PropTypes.string,
  itemSelected: PropTypes.string,
  handlerSelect: PropTypes.func,
};

export default TabMenu;
