import { decamelizeKeys } from 'humps';
import localStorage from 'local-storage';
import isEmpty from 'lodash/isEmpty';
import FileSaver from 'file-saver';
import bigNumber from 'bignumber.js';
import { UserAuthWrapper as userAuthWrapper } from 'redux-auth-wrapper';
import {
  find,
  compact,
  concat,
  isString,
  reduce,
  head,
  get,
  pickBy,
  every,
  includes,
} from 'lodash';
import { pathToRegexp } from 'path-to-regexp';
import { createIntl, createIntlCache } from '@formatjs/intl';

import { EXPERIENCES_TYPES, SIGNUP_TABS, USER_TYPES, ADMIN_PATHS } from 'appconstants';
import { EXPERIENCE_CARD } from 'appconstants/experiences';
import routes from 'appconstants/routes';

import { selectUserMaxRole } from 'selectors/user';


const ACCESS_TOKEN_KEY = 'theglint:auth_token';

export function formatNumber(value) {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
}

export function formatName(object) {
  if (!object) {
    return '';
  }
  const values = compact(
    concat(
      [],
      object.firstName || object.shippingFirstName,
      object.lastName || object.shippingLastName
    )
  );
  return values.join(', ');
}

export function formatAddress(address) {
  if (!address) {
    return '';
  }
  const values = compact(
    concat(
      [],
      address.street || address.shippingStreet,
      address.city || address.shippingCity,
      address.state || address.shippingState,
      address.zip || address.shippingZip
    )
  );
  return values.join(', ');
}

export function setAccessToken(value) {
  const token = isString(value)
    ? {
        accessToken: value,
        refreshToken: value,
        expiresIn: 3600,
        tokenType: 'Bearer',
      }
    : value;
  localStorage(ACCESS_TOKEN_KEY, {
    ...token,
    expires: new Date().getTime() + token.expiresIn * 1000,
  });
}

export function getAccessToken() {
  return localStorage(ACCESS_TOKEN_KEY);
}

export function removeAccessToken() {
  localStorage.remove(ACCESS_TOKEN_KEY);
}

export function isAccessTokenExpired(token) {
  return token && token.expires <= new Date().getTime();
}

export function toUrlParameters(object) {
  const params = decamelizeKeys(object);
  return reduce(
    params,
    (res, value, key) => {
      res.push(`${key}=${encodeURIComponent(value)}`);
      return res;
    },
    []
  ).join('&');
}

export function getDefaultImage(value) {
  // use default with fallback to first image
  const images = get(value, 'images') || value;
  return (
    find(images, 'isDefault') ||
    head(images) || {
      url: '/progressive/default.jpg',
    }
  );
}

export function getDefaultImageSrc(value) {
  const image = getDefaultImage(value);
  return get(image, 'url');
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getRewardConfiguration(reward, fields) {
  const {
    template,
    additionalFields: { configurations },
  } = reward;
  if (!configurations) {
    return null;
  } else if (configurations.length === 1) {
    // return default config if only 1
    return head(configurations);
  }
  return find(configurations, (configuration) => {
    const values = pickBy(configuration.values, (value, key) => {
      return !!find(template.fields, { uid: key, editable: true });
    });
    // return config if empty and user didn't select any field
    if (!fields && every(values, isEmpty)) {
      return true;
    }
    // return config matching fields selected
    return fields
      ? reduce(
          fields,
          (res, field) => {
            const value = values[field.uid];
            return res && (field.value === value || !value);
          },
          true
        )
      : null;
  });
}

export const isAllAccessTicket = (ticket) => ticket?.description?.toLowerCase().includes('all');

export function getLocation(experience) {
  const { location, type } = experience;
  if (!location) {
    return '';
  }
  if (type === EXPERIENCES_TYPES.VIRTUAL) {
    return location.platform || '';
  }
  return location.name || '';
}

export function getRSVPLabelAndStatus(
  experience,
  tickets = [],
  rsvp,
  isFestival = false,
) {
  if (rsvp) {
    return {
      rsvpLabel: "I'm going",
      rsvpDisable: true,
    };
  }

  if (experience.festival) {
    let rsvpLabel = 'Register to festival';

    if (experience.festivalUnlocked) {
      rsvpLabel = 'Add to Schedule';
    } else if (experience.hasFestivalPass) {
      rsvpLabel = 'Upgrade your pass';
    }

    return {
      rsvpLabel,
      longText: true,
      rsvpDisable: false,
      festivalUnlocked: experience.festivalUnlocked,
    };
  }

  const ticketsAvailable = experience.remainingTickets > 0;

  const { minPrice } = tickets.reduce(
    (acc, ticket) => ({
      minPrice: ticket.quantity ? Math.min(ticket.price, acc.minPrice) : acc.minPrice,
    }),
    { minPrice: Number.MAX_SAFE_INTEGER }
  );

  if (experience.experienceLock) {
    if (experience.experienceUnlocked) {
      return {
        rsvpDisable: !ticketsAvailable,
        rsvpLabel: 'Add to Schedule',
      };
    }

    return {
      rsvpDisable: !ticketsAvailable,
      rsvpLabel: 'RSVP',
    };
  }

  let disableRsvp = false;

  let rsvpLabel = ticketsAvailable
  ? `RSVP  / ${(minPrice && `$${minPrice}`) || 'Free'}`
  : 'Sold Out';

  if (isFestival) {
    rsvpLabel = 'Register';

    if (experience.remainingTickets === 0) {
      rsvpLabel = 'Sold Out';
      disableRsvp = true;
    }

    if (experience.rsvp) {
      rsvpLabel = "I'm going";
      disableRsvp = true;
    }
  }

  return {
    rsvpDisable: !ticketsAvailable || experience.rsvp || disableRsvp,
    rsvpLabel,
  };
}

export const dashboardAccessFinder = ({ role, canEditExperiences, allowedToInvite }) => (
  allowedToInvite
  || canEditExperiences
  || [
    USER_TYPES.ADMIN,
    USER_TYPES.PRODUCER,
    USER_TYPES.CREATOR,
  ].includes(role)
);

export const getChannelPermissions = ({
  id,
  urlSlug,
  user,
 }) => {
  return user.channels.find(({ urlSlug: channelSlug, id: channelId }) => id ? id === channelId : channelSlug === urlSlug);
}

export const userHasAccess = ({ urlSlug, user, channelSettings = false }) => {
  const userChannelAccess = getChannelPermissions({ urlSlug, user });

  if (!userChannelAccess) {
    return false
  }

  if (!channelSettings) {
    return userChannelAccess.role !== USER_TYPES.GUEST;
  }

  return userChannelAccess.isOwner || [USER_TYPES.ADMIN, USER_TYPES.PRODUCER].includes(userChannelAccess.role);
}

export const getChannelsWithDashboardAccess = (channels) => channels?.filter(dashboardAccessFinder);

export function getTicketsUrl(alias, channel, experience) {
  if (experience.isFestival) {
    return `/in/${channel}/f/${alias}/checkout`;
  };

  let url = `/tickets/`;
  if (experience.festival && !experience.festivalUnlocked) {
    const {
      channel: { urlSlug },
      alias,
    } = experience.festival;

    url = `/in/${urlSlug}/f/${alias}/checkout`;
  } else if (experience.experienceLock && !experience.experienceUnlocked) {
    const channelSlug = experience.lockingChannel ? experience.lockingChannel.urlSlug : '';
    const experienceAlias = experience.lockingExperience ? experience.lockingExperience.alias : '';

    url += `${channelSlug}/${experienceAlias}`;

    const params = `?channel=${channel}&alias=${alias}`;

    url += params;
  } else {
    url += `${channel}/${alias}`;
  }

  return url;
}

export async function getCSVfromResponse({ response, csvName, text = false }) {
  let blob;

  if (text) {
    blob = response;
  } else {
    blob = await response.blob();
  }

  return FileSaver.saveAs(blob, `${csvName}`);
}

export function calculateDiscount(itemPrice, itemDiscount) {
  const price = bigNumber(itemPrice);
  const discount = bigNumber(itemDiscount);
  return price.minus(price.times(discount.div(100))).toFixed(2);
}

export function getResizedImageURL(originalImageURL, size) {
  const imageURL = new URL(originalImageURL);
  return `${process.env.REACT_APP_IMAGE_RESIZER_BASE_URL}${size}${imageURL.pathname}`;
}

export const requireAuthentication = userAuthWrapper({
  authSelector: (state, props) => {
    return {
      maxRole: selectUserMaxRole(state),
      path: props.route.path,
    };
  },
  predicate: ({ maxRole, path }) => {
    return (
      (maxRole && !(maxRole === USER_TYPES.GUEST && includes(ADMIN_PATHS, path))) ||
      includes([`/${SIGNUP_TABS.signin}`, `/${SIGNUP_TABS.signup}`], path)
    );
  },
  wrapperDisplayName: 'UserIsAuthenticated',
  failureRedirectPath: (state, params) => {
    const { location } = params;
    const maxRole = selectUserMaxRole(state);

    if (maxRole) {
      return '/';
    } else if (location.pathname.includes('/checkout/')) {
      return `/checkout/${SIGNUP_TABS.signin}`;
    } else {
      return `/${SIGNUP_TABS.signup}`;
    }
  },
});

export function hasPendingOnboarding(user) {
  const { about, photo, profession, state, channels, maxRole, stripeSetup } = user;
  const ownedChannels = channels && channels.filter((channel) => channel.isOwner);
  const channelsNeedSetup =
    ownedChannels && ownedChannels.filter((channel) => channel.setupPending);

  if ((!about || !photo || !profession || !state) && maxRole !== USER_TYPES.GUEST) {
    return true;
  }

  if ([USER_TYPES.ADMIN, USER_TYPES.CREATOR, USER_TYPES.ADMIN].includes(maxRole) && !stripeSetup) return true;

  if (channelsNeedSetup && channelsNeedSetup.length) return true;

  if (ownedChannels && ownedChannels.length && !stripeSetup) return true;

  return false;
}

export function disableSubmit(currentPath) {
  const disableSubmitPaths = [
    routes.createExperience,
    routes.selectChannel,
    routes.experiencesManager,
    routes.createFestival,
  ];

  return disableSubmitPaths.some((path) => {
    const regExp = pathToRegexp(path);
    return regExp.test(currentPath);
  });
}

export function getMaxExperiences(width) {
  return Math.floor(width / (EXPERIENCE_CARD.WIDTH + EXPERIENCE_CARD.PADDING));
}

export function canEditExperienceDates(experience) {
  return !experience.reliveUrl && !experience.hasRsvp;
}

export const replaceRelivePage = ({ channel, alias }) => {
  return routes.relive.replace(':channel', channel).replace(':alias', alias);
};

const cache = createIntlCache();
const intl = createIntl({ locale: 'en-US' }, cache);

export const formatCurrency = ({ value, currency = 'USD' }) =>
  intl.formatNumber(value, { style: 'currency', currency });

export const formatDiscount = ({ value, isPercentage }, currency) =>
  isPercentage ? `${value}%` : formatCurrency({ value, currency });



export const ticketSortFunction = (ticketA, ticketB) => {
  if (ticketA.description === 'All access') {
    return -1;
  } else if (ticketB.description === 'All access') {
    return 1;
  }

  return ticketA.days - ticketB.days;
}
