/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { object, func } from "prop-types";
import { connect } from 'react-redux';
import cn from "classnames";

import {
  loadGuestlist as fetchTicketsGuestList,
  loadReliveGuestlist,
  downloadReliveGuestlist,
  downloadGuestlist,
} from "actions/experiences";
import { selectCurrentExperience } from "selectors/experiences";

import GuestListRsvp  from "components/GuestListRsvp";
import GuestListRelive from "components/GuestListRelive";
import { getCSVfromResponse } from "utils/helpers.js";
import "./styles.css";

const TABS = {
  TICKETS: "TICKETS",
  RELIVES: "RELIVES"
};

class GuestLists extends Component {
  constructor() {
    super();
    this.state = {
      selectedTab: TABS.TICKETS,
      ticketsItems: null,
      reliveItems: null,
    };
  }

  componentDidMount = () => {
    this.loadTicketsGuestlist();
    this.loadRelivesGuestlist();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { experience: { id } } = this.props;
    const { experience: { id: newId } } = newProps;

    if (id !== newId) {
      this.loadTicketsGuestlist();
      this.loadRelivesGuestlist();
    }
  }

  onTabSelect = (tab) => {
    this.setState({ selectedTab: tab });
  };

  onDonwload = async () => {
    const { experience: { channel: { urlSlug: channel }, alias }} = this.props;
    const { selectedTab } = this.state;

    const isRelivesTab = selectedTab === TABS.RELIVES;

    const downloadAction = isRelivesTab
      ? this.props.downloadReliveGuestlist
      : this.props.downloadGuestlist;

    const guestlistCSV = await downloadAction({ channel, alias });
    getCSVfromResponse({
      response: guestlistCSV,
      csvName: `${isRelivesTab ? "relive-guestlist" : "tickets-guestlist"}.csv`,
    });
  };

  loadTicketsGuestlist = async () => {
    const {
      experience: {
        channel: { urlSlug: channel },
        alias,
      },
    } = this.props;

    try {
      const ticketsItems = await this.props.fetchTicketsGuestList({
        channel,
        alias,
      });
      this.setState({ ticketsItems });
    } catch (e) {
      this.setState({ ticketsItems: [] });
    }
  };

  loadRelivesGuestlist = async () => {
    const {
      experience: {
        channel: { urlSlug: channel },
        alias,
      },
    } = this.props;

    try {
      const reliveItems = await this.props.loadReliveGuestlist({
        channel,
        alias,
      });
      this.setState({ reliveItems });
    } catch (e) {
      this.setState({ reliveItems: [] });
    }
  };

  render() {
    const { selectedTab, ticketsItems, reliveItems } = this.state;

    const isTicketsTab = selectedTab === TABS.TICKETS;
    const isRelivesTab = selectedTab === TABS.RELIVES;
    return (
      <div>
        <div className="guestlists-header">
          <div>
            <button
              className={cn("guestlists-tab-label", {
                "guestlists-tab-label-selected": isTicketsTab,
              })}
              onClick={() => this.onTabSelect(TABS.TICKETS)}
            >
              <span>Guest orders</span>
              <span>{ticketsItems && ticketsItems.length}</span>
            </button>
            <button
              className={cn("guestlists-tab-label", {
                "guestlists-tab-label-selected": isRelivesTab,
              })}
              onClick={() => this.onTabSelect(TABS.RELIVES)}
            >
              <span>Re-lives</span>
              <span>{reliveItems && reliveItems.length}</span>
            </button>
          </div>
          <button className="guestlists-download-button" onClick={this.onDonwload}>
            Download
          </button>
        </div>
        {isTicketsTab && <GuestListRsvp items={ticketsItems} />}
        {isRelivesTab && <GuestListRelive items={reliveItems} />}
      </div>
    );
  }
}

GuestLists.propTypes = {
  experience: object.isRequired,
  fetchTicketsGuestList: func.isRequired,
  loadReliveGuestlist: func.isRequired,
  downloadReliveGuestlist: func.isRequired,
  downloadGuestlist: func.isRequired
};

export default connect(
  (state) => ({
    experience: selectCurrentExperience(state),
  }),
  {
    fetchTicketsGuestList,
    loadReliveGuestlist,
    downloadReliveGuestlist,
    downloadGuestlist
  }
)(GuestLists);
