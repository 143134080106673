import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import PresenceLogoWhite from 'images/presence_logo@2x.png';
import PresenceLogoBlue from 'images/presence_logo_blue@2x.png';
import { APP_THEMES } from 'appconstants/colors';
import routes from 'appconstants/routes';
import { CircularProgress } from 'material-ui';
import { isEmpty,  } from 'lodash-es';
const ChannelLogo = ({
  admin,
  currentChannel = {},
  theme,
}) => {
  const { channelIcon, channelName, channelAlias, channelColor } = useMemo(() => ({
    channelName: currentChannel?.name,
    channelIcon: currentChannel?.images?.[0],
    channelAlias: currentChannel?.urlSlug,
    channelColor: currentChannel?.color,
  }), [currentChannel]);

  if ((admin) || (channelName && channelIcon)) {
    if (!isEmpty(currentChannel)) {
      return (<Link to={admin ? routes.dashboard(channelAlias) : `/in/${channelAlias}`}>
      {
        channelIcon ? (
          <Logo color={channelColor} src={channelIcon} />
        ) : (
          <CircularProgress size={33} />
        )
      }
      <ChannelName darkText={theme === APP_THEMES.WHITE}>{channelName}</ChannelName>
    </Link>)
    }
  }
  return (
    <Link to={channelAlias ? `/in/${channelAlias}/` : '/'}>
      <PresenceLogo src={theme === APP_THEMES.WHITE ? PresenceLogoBlue : PresenceLogoWhite} />
    </Link>
  );
}

const Logo = styled.img`
  width: 33px;
  height: 33px;
  border-radius: 33px;
  background-color: ${({ color }) => color};
`;

const ChannelName = styled.span`
  color: ${({ darkText }) => darkText ? '#000000' : '#FFFFFF'};
  font-family: CircularStd Book;
  font-size: 16px;
  margin-left: 13px;
  display: inline-block;
  margin-right: 6px;
`;

const PresenceLogo = styled.img`
  height: 22.5px;
  width: 90px;
`;

const mapStateToProps = (state) => ({
  currentChannel: state.channels.currentChannel,
});

ChannelLogo.propTypes = {
  currentChannel: PropTypes.shape({
    name: PropTypes.string,
    alias: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.string,
  }),
  theme: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withRouter(ChannelLogo));
