import React from 'react';
import { array, string, oneOf, number, bool } from 'prop-types';
import cn from 'classnames';
import map from 'lodash/map';

import Ticket from 'components/Ticket';
import { EXPERIENCE_CARD } from 'appconstants/experiences';
import { getFormattedDateRange } from 'utils/dates';
import { DATE_RANGE_FORMATS } from 'appconstants/time';

import './styles.css';
import { CircularProgress } from 'material-ui';

const ListExperiences = ({ experiences, loading = false, title, alignment = "center", width, className = "", style }) => {
  const renderItem = ({
    id,
    _id,
    startTime,
    endTime,
    images,
    channel: { urlSlug, name: channelName },
    alias,
    name,
    isFestival = false,
  }) => {
    const date = getFormattedDateRange({startTime, endTime, format: DATE_RANGE_FORMATS.FULL_DATE});
    const image = images && images[0];

    return (
      <Ticket
        key={id || _id}
        isFestival={isFestival}
        urlSlug={urlSlug}
        alias={alias}
        title={name}
        channel={channelName}
        date={date}
        imageUrl={image}
      />
    );
  };
  const titleMargin = width && (((width / (EXPERIENCE_CARD.WIDTH + EXPERIENCE_CARD.PADDING)) % 1) * 10) + 1;

  if (!loading && !experiences?.length) {
    return null;
  }

  return (
    <div className={cn("section-experience-list", className)} style={style}>
      <div className="container-experiences-list">
        {title && (
          <h3
            className={cn("page-title experience-list-title", {
              "experience-title-left": alignment === "left",
            })}
            style={
              alignment === "left" ? { marginLeft: `${titleMargin}rem` } : {}
            }
          >
            {title}
          </h3>
        )}
        <div id="bx-pager" className="experiences-container">
          {
            loading ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              <>
                {experiences && (
                  <div
                    className={cn("experiences-list-wrapper", {
                      "experience-title": title,
                    })}
                  >
                    {map(experiences, (item) => renderItem(item))}
                  </div>
                )}
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

ListExperiences.propTypes = {
  experiences: array,
  className: string,
  isFestival: bool,
  alignment: oneOf(["center", "left"]),
  title: string,
  width: number
};

export default ListExperiences;
