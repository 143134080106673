import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectStripe, CardElement } from "react-stripe-elements";
import cn from "classnames";
import Button from "components/Button";
import { BUTTON_STYLES } from "appconstants";
import "./styles.css";

const genericErrorMessage =
  "There was an error processing your payment. Please try again later";

class CheckoutFormMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    this.setState({ loading: true, errorMessage: null });
    const cardElement = this.props.elements.getElement("card");

    try {
      const { stripe, clientSecret, showGuidelines } = this.props;
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );
      this.setState({ loading: null });
      if (paymentIntent && paymentIntent.status === "succeeded") {
        showGuidelines();
      } else if (error) {
        this.setState({
          errorMessage:
            error.type !== "validation_error"
              ? genericErrorMessage
              : error.message,
        });
      }
    } catch (e) {
      this.setState({
        loading: null,
        errorMessage: genericErrorMessage,
      });
    }
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  render() {
    const { className, header } = this.props;
    const { loading, errorMessage } = this.state;
    return (
      <div className={cn(className, "checkout-form-mobile")}>
        <div className="checkout-fields-wrapper-mobile ">
          {header}
          <div className="checkout-fields-mobile">
            <CardElement
              onChange={this.handleChange}
              style={{
                base: {
                  fontFamily: '"Roboto", serif',
                  "::placeholder": {
                    color: "#AFAFAF",
                    fontFamily: '"Roboto", serif',
                    fontSize: "13px",
                    fontWeight: "500",
                    textTransform: "uppercase"
                  },
                },
              }}
            />
          </div>
        </div>
        {errorMessage && (
          <div class="error" role="alert">
            {errorMessage}
          </div>
        )}
        <div className="ticket-footer-mob">
          <div className="btn-wrap-mob clearfix gradient-btn-mob">
            <div className="purchase-ticket-button-wrapper">
              <Button
                className="purchase-ticket-button"
                buttonStyle={BUTTON_STYLES.GREEN}
                onClick={this.handleSubmit}
                disabled={loading}
              >
                CONTINUE
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
CheckoutFormMobile.propTypes = {
  className: PropTypes.string,
  showGuidelines: PropTypes.func,
  header: PropTypes.node
};

export default injectStripe(styled(CheckoutFormMobile)`
  max-width: 500px;
  margin: auto;

  span {
    color: #6b7c93;
    letter-spacing: 0.025em;
  }

  .StripeElement {
    border-bottom: 2px solid #EBEBEB;
    margin-top: 20%;
  }

  .InputElement {
    color: red;
  };

  .error {
    color: #c23d4b;
    margin: 5px 0;
  }
`);
