import React from 'react';
import styled from 'styled-components';

const CheckoutGuidelinesMob = ({ guidelines }) => {
  return (
    <GuidelinesWrapper>
      <GuidelinesTitleWrapper>
        <GuidelinesTitle>
          Thank you for joining us!
        </GuidelinesTitle>
        <GuidelinesTitleDivider />
        <GuidelinesSubtitle>
          Here’s how you gather as a community…
        </GuidelinesSubtitle>
      </GuidelinesTitleWrapper>
      <GuidelineList>
        {guidelines.map(guideline => (
          <GuidelineItem key={guideline}>{guideline}</GuidelineItem>
        ))}
      </GuidelineList>
    </GuidelinesWrapper>
  );
}

const GuidelinesWrapper = styled.div`
  margin: 2rem 1rem 4rem 1rem;
`;

const GuidelinesTitleWrapper = styled.div``;

const GuidelinesTitle = styled.h3`
  color: #000;
`;

const GuidelinesTitleDivider = styled.div`
  box-sizing: border-box;
  height: 3px;
  width: 99px;
  border: 2px solid #3C2784;
`;

const GuidelinesSubtitle = styled.h3`
  color: #3C2784;
`;

const GuidelineList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const GuidelineItem = styled.li`
  font-family: CircularStd Book;
  border-top: 1px solid #EBEBEB;
  padding: 1rem 0;
  color: #626262;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 25px;
`;

export default CheckoutGuidelinesMob;
