import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { range } from 'lodash';
import { isAllAccessTicket } from 'utils/helpers';
import moment from 'moment';
import useCheckoutTicketLogic from 'hooks/useCheckoutTicketLogic';
import { BOX_SHADOW } from 'containers/App/constants';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#9b63f8',
    },
    '&:hover': {
      color: '#9b63f8',
    },
    paddingLeft: 0,
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CheckoutSelectable = ({
  data,
  tickets,
  selectTicketType,
  setSelectTicketType,
  selectQty,
  setSelectQty,
  canSelectMoreDays,
  selectedTicket,
  selectDays,
  setSelectDays,
  toggleDate,
  ticketsToPurchase,
  setTicketAmount,
}) => {
  const { festivalTickets, onSelectTicket, passes, selectableDays, ticketDescription } =
    useCheckoutTicketLogic({
      data,
      tickets,
      setSelectTicketType,
      selectedTicket,
      setSelectDays,
    });

  const unlockedExperiences = useMemo(() => {
    if (data?.experiencesWithDates) {
      let filteredExperiences = [...data.experiencesWithDates];
      filteredExperiences.sort(
        ({ startTime: startTimeA }, { startTime: startTimeB }) =>
          new Date(startTimeA) - new Date(startTimeB)
      );

      filteredExperiences = filteredExperiences.filter(({ startTime }) => {
        const experienceMoment = moment(startTime);

        return selectDays.some((date) => {
          const dayStart = moment(date);
          const dayEnd = moment(dayStart).add(1, 'days');

          return experienceMoment.isBetween(dayStart, dayEnd, null, '[]');
        });
      });

      return filteredExperiences;
    }

    return null;
  }, [data, selectDays]);

  const daysListRef = useRef();

  const maxHeight = useMemo(() => {
    if (daysListRef?.current) {
      return daysListRef.current.clientHeight;
    }
  }, []);

  return (
    <>
      <SelectableContainer>
        <SelectYourPassContainer>
          <UpperContainer>
            <TicketSelect>
              <YourPassTitleAndDecription>
                <YourPassTitle>Select your pass type</YourPassTitle>
                <YourPassDescription>Select number of days you want to attend</YourPassDescription>
                <StyledFormControlLarge
                  theme
                  classes={{
                    root: 'festival__checkout-select',
                  }}
                  variant="outlined"
                >
                  <Select
                    id="select-ticket-type"
                    value={selectTicketType}
                    displayEmpty
                    MenuProps={{ PaperProps: BOX_SHADOW }}
                    classes={{
                      root: 'festival__checkout-select-element',
                    }}
                    onChange={onSelectTicket}
                  >
                    {[{ _id: '', description: 'Select a pass type' }, ...passes]
                      .filter(({ quantity }) => quantity > 0)
                      .map((ticket) => (
                        <StyledMenuItem
                          value={ticket._id}
                          disabled={ticket._id === ''}
                          key={ticket._id}
                        >
                          {ticket.name || ticket.description}
                        </StyledMenuItem>
                      ))}
                  </Select>
                </StyledFormControlLarge>
              </YourPassTitleAndDecription>
              <YourPassSelectsContainer>
                <QuantityContainer>
                  <QuantityHeader>Qty</QuantityHeader>
                  <StyledFormControl>
                    <Select
                      MenuProps={{ PaperProps: BOX_SHADOW }}
                      id="select-qty"
                      value={selectQty}
                      defaultValue={1}
                      onChange={(e) => setSelectQty(e.target.value)}
                      disabled={selectTicketType === ''}
                    >
                      {range(0, selectedTicket?.total > 10 ? 11 : selectedTicket?.quantity + 1).map(
                        (qty) => (
                          <MenuItem value={qty} key={qty}>
                            {qty}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </StyledFormControl>
                </QuantityContainer>
                <PriceContainer>
                  <PriceHeader>Price</PriceHeader>
                  {selectedTicket ? (
                    selectedTicket.price === 0 ? (
                      <Free>Free</Free>
                    ) : (
                      <Price>{`$${selectedTicket.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}</Price>
                    )
                  ) : (
                    ''
                  )}
                </PriceContainer>
              </YourPassSelectsContainer>
            </TicketSelect>
            <TicketDescription>{ticketDescription}</TicketDescription>
          </UpperContainer>
          <LowerContainer>
            {selectedTicket?.isTicket ? (
              <PaidTicketDescription>
                Paid tickets do not unlock any experiences
              </PaidTicketDescription>
            ) : (
              <SelectDaysContainer>
                <DaysTitle>Select days you want to attend</DaysTitle>
                <DaysDescription>Select the days you want to attend festival.</DaysDescription>
                <DaysSelectsContainer>
                  <StyledFormControlLarge variant="outlined">
                    <div ref={daysListRef}>
                      <MenuItem
                        classes={{
                          root: 'festival-checkout__checkbox-container',
                        }}
                        disabled
                      >
                        <ListItemText
                          primary={`Select ${
                            selectedTicket?.days ? `${selectedTicket.days} ` : ''
                          } pass`}
                        />
                      </MenuItem>
                      {selectableDays.map((day) => (
                        <MenuItem
                          disableRipple
                          classes={{
                            root: 'festival-checkout__checkbox-container',
                          }}
                          key={day.value}
                          value={day.value}
                          disabled={
                            isAllAccessTicket(selectedTicket) ||
                            moment().startOf('day').isAfter(moment(day.value)) ||
                            (!selectDays.find((sday) => sday === day.value) && !canSelectMoreDays)
                          }
                          onClick={() => toggleDate(day.value)}
                        >
                          <StyledCheckbox
                            disableRipple
                            checked={!!selectDays.find((sday) => sday === day.value)}
                          />
                          <ListItemText primary={day.label} />
                        </MenuItem>
                      ))}
                    </div>
                  </StyledFormControlLarge>
                  <UnlockingExperiences height={maxHeight || 100}>
                    <UnlockingTitle>Unlocking</UnlockingTitle>
                    {unlockedExperiences.length ? (
                      unlockedExperiences.map(({ _id, name }) => (
                        <UnlockedExperience key={_id}>{name}</UnlockedExperience>
                      ))
                    ) : (
                      <UnlockingAmount>No experiences</UnlockingAmount>
                    )}
                  </UnlockingExperiences>
                </DaysSelectsContainer>
              </SelectDaysContainer>
            )}
          </LowerContainer>
        </SelectYourPassContainer>
      </SelectableContainer>
      {festivalTickets.length > 0 && (
        <SelectableContainer compact>
          <DescriptionHeader large>Add ons</DescriptionHeader>
          {festivalTickets.map(
            ({ description, _id, price, quantity, longDescription }) =>
              quantity > 0 && (
                <TicketSelect isTicket>
                  <QuantityContainer noGap>
                    <DescriptionHeader>&nbsp;</DescriptionHeader>
                    <YourPassTitle>{description}</YourPassTitle>
                    <YourTicketDescription>{longDescription}</YourTicketDescription>
                  </QuantityContainer>
                  <YourPassSelectsContainer marginBottom="auto">
                    <QuantityContainer noGap>
                      <QuantityHeader>Qty</QuantityHeader>
                      <StyledFormControl>
                        <Select
                          id="select-qty"
                          MenuProps={{ PaperProps: BOX_SHADOW }}
                          value={ticketsToPurchase[_id] || 0}
                          onChange={(e) => setTicketAmount(_id, e.target.value)}
                          disabled={selectTicketType === ''}
                        >
                          {range(
                            0,
                            selectedTicket?.total > 10 ? 11 : selectedTicket?.quantity + 1
                          ).map((qty) => (
                            <MenuItem value={qty} key={qty}>
                              {qty}
                            </MenuItem>
                          ))}
                        </Select>
                      </StyledFormControl>
                    </QuantityContainer>
                    <PriceContainer noGap>
                      <PriceHeader>Price</PriceHeader>
                      {price === 0 ? (
                        <Free>Free</Free>
                      ) : (
                        <Price>{`$${price.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}</Price>
                      )}
                    </PriceContainer>
                  </YourPassSelectsContainer>
                </TicketSelect>
              )
          )}
        </SelectableContainer>
      )}
    </>
  );
};

const SelectableContainer = styled.div`
  box-sizing: border-box;
  width: 672px;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.06);
  padding: ${({ compact }) => compact && '0'} 25px;
`;

const UnlockedExperience = styled.div`
  color: #000;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
  padding: 10px 0;
  border-bottom: 1px solid #d5d5d5;
  line-height: 1.5;
`;

const PaidTicketDescription = styled(UnlockedExperience)`
  color: #cacaca;
  border-bottom: 0;
  font-weight: 100;
  font-style: italic;
  text-align: center;
`;

const UpperContainer = styled.div`
  flex-direction: column;
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #d5d5d5;
`;

const TicketSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ isTicket }) =>
    isTicket &&
    `
    margin: 15px 0;
  `}

  & + & {
    border-top: 1px solid #cacaca;
  }
`;

const TicketDescription = styled.span`
  color: #676767;
  line-height: 21px;
  margin-top: 16px;
`;

const LowerContainer = styled.div`
  padding-top: 10px;
`;

const SelectYourPassContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const YourPassTitleAndDecription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const YourPassTitle = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
`;
const YourPassDescription = styled.div`
  color: #676767;
  font-family: 'CircularStd Book';
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 16px;
`;
const YourPassSelectsContainer = styled.div`
  display: flex;
  gap: 80px;

  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`}
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ noGap }) => (noGap ? 0 : 20)}px;
  padding: 10px;
`;

const YourTicketDescription = styled.div`
  font-size: 12px;
  margin-top: 12px;
  color: #d5d5d5;
  line-height: 1.75;
  text-align: justify;
  white-space: pre-wrap;
`;

const QuantityHeader = styled.div`
  color: #cacaca;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
  align-self: center;
`;

const DescriptionHeader = styled(QuantityHeader)`
  align-self: flex-start;

  ${({ large }) =>
    large &&
    `
    font-size: 22px;
    margin-top: 30px;
  `}
`;

const StyledFormControl = styled(FormControl)`
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #9b63f8;
    border-width: 2px;
  }

  & .MuiSelect-select {
    color: #9b63f8;
  }

  & .MuiSelect-icon {
    color: #9b63f8;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled)::before,
  & .MuiInput-underline:after {
    border-bottom: 2px solid #9b63f8;
  }
`;

const StyledFormControlLarge = styled(StyledFormControl)`
  width: 250px;
  padding: 0;
`;

const PriceContainer = styled.div`
  display: flex;
  gap: ${({ noGap }) => (noGap ? 0 : 20)}px;
  flex-direction: column;
  padding: 10px;
`;

const PriceHeader = styled.div`
  color: #cacaca;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
  align-self: center;
`;

const Price = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
  align-self: center;
`;

const Free = styled.div`
  width: max-content;
  color: silver;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  padding: 0 2rem;
  border: 2px solid #d5d5d5;
  border-radius: 8px;
  line-height: 28px;
  height: 56px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
`;

const SelectDaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const DaysTitle = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
`;
const DaysDescription = styled.div`
  color: #676767;
  font-family: 'CircularStd Book';
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 17px;
`;
const DaysSelectsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  gap: 40px;
`;
const UnlockingExperiences = styled.div`
  flex-direction: column;
  gap: 8px;
  padding-left: 27px;
  border-left: 1px solid #d5d5d5;
  margin-top: 10px;
  overflow-y: auto;
  width: 50%;
  position: relative;
  ${({ height }) => `
    height: ${height}px;
  `}
`;

const UnlockingTitle = styled.div`
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  position: sticky;
  background: white;
  top: 0;
  left: 0;
  line-height: 25px;
`;
const UnlockingAmount = styled.div`
  color: #999999;
  margin-top: 10px;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
`;

const StyledMenuItem = styled(MenuItem)`
  margin: 0 18px;
  border-bottom: 2px solid transparent;
  && {
    background: transparent;
  }

  &:hover {
    border-color: #9b63f8;

    && {
      background: transparent;
    }
  }
`;

export default CheckoutSelectable;
