import localStorage from 'local-storage';
import { CALL_API } from 'redux_store/middleware/api';

const SAVE_IMAGE_REQUEST = 'SAVE_IMAGE_REQUEST';
const SAVE_IMAGE_SUCCESS = 'SAVE_IMAGE_SUCCESS';
const SAVE_IMAGE_FAILURE = 'SAVE_IMAGE_FAILURE';

const SAVE_IMAGE_ARTIST_REQUEST = 'SAVE_IMAGE_ARTIST_REQUEST';
const SAVE_IMAGE_ARTIST_SUCCESS = 'SAVE_IMAGE_ARTIST_SUCCESS';
const SAVE_IMAGE_ARTIST_FAILURE = 'SAVE_IMAGE_ARTIST_FAILURE';

const LOAD_IMAGE_REQUEST = 'LOAD_IMAGE_REQUEST';
const LOAD_IMAGE_SUCCESS = 'LOAD_IMAGE_SUCCESS';
const LOAD_IMAGE_FAILURE = 'LOAD_IMAGE_FAILURE';

function getImageById(id) {
  const method = 'get';
  const endpoint = `/images/${id}`;

  return {
    [CALL_API]: {
      types: [
        LOAD_IMAGE_REQUEST,
        LOAD_IMAGE_SUCCESS,
        LOAD_IMAGE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function postImage(body) {
  const method = 'post';
  const endpoint = '/images';
  return {
    [CALL_API]: {
      types: [
        SAVE_IMAGE_REQUEST,
        SAVE_IMAGE_SUCCESS,
        SAVE_IMAGE_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

function postImageArtists(body) {
  const method = 'post';
  const endpoint = '/images';
  return {
    [CALL_API]: {
      types: [
        SAVE_IMAGE_ARTIST_REQUEST,
        SAVE_IMAGE_ARTIST_SUCCESS,
        SAVE_IMAGE_ARTIST_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

export function loadImages(ids) {
  return (dispatch) => {
    const getActions = ids.map((id) => {
      return dispatch(getImageById(id));
    });
    return Promise.all(getActions);
  };
}

export function saveImage(data) {
  const imageConst = `tg_images:${data.hash}`;
  const dataImage = localStorage(imageConst);


  if (!dataImage && data.hash && data.hash !== 'undefined') {
    localStorage(imageConst, {
      name: data.name,
      hash: data.hash,
      expires: (new Date().getTime() + (3 * 1000)),
    });
  }

  return (dispatch) => {
    const body = {
      ...data,
      name: Math.floor(Math.random() * 1000000) + data.name,
    };
    return dispatch(postImage(body))
      .then((res) => {
        const ret = {
          ...res,
          hash: data.hash,
        };
        return ret;
      });
  };
}

export function saveImageArtists(data) {
  const imageConst = `tg_images_artists:${data.hash}`;
  const dataImage = localStorage(imageConst);


  if (!dataImage && data.hash && data.hash !== 'undefined') {
    localStorage(imageConst, {
      name: data.name,
      hash: data.hash,
      expires: (new Date().getTime() + (3 * 1000)),
    });
  }

  return (dispatch) => {
    const body = {
      ...data,
      name: Math.floor(Math.random() * 1000000) + data.name,
    };
    return dispatch(postImageArtists(body))
      .then((res) => {
        const ret = {
          ...res,
          hash: data.hash,
        };
        return ret;
      });
  };
}

export function saveImages(artists) {
  return (dispatch) => {
    const saveActions = artists.map((item) => {
      return dispatch(saveImage(item));
    });
    return Promise.all(saveActions);
  };
}

export function saveImagesArtists(artists) {
  return (dispatch) => {
    const saveActions = artists.map((item) => {
      return dispatch(saveImageArtists(item));
    });
    return Promise.all(saveActions);
  };
}

