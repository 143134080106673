import api from 'api';
import { useDisableCheckoutButton } from 'hooks/useCheckoutTicketLogic';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

//TODO: implement discounts, implement continue

const CheckoutFooter = ({
  fee,
  onClick,
  params,
  selectDays,
  selectedTicket,
  setDiscount: setActiveDiscount,
  ticketsToPurchase,
  selectQty,
  subtotal,
}) => {
  const [discount, setDiscount] = useState('');
  const [loading, setLoading] = useState(false);
  const disabled = useDisableCheckoutButton({
    selectDays,
    selectedTicket,
    selectQty,
    ticketsToPurchase,
  })

  const { channel, id } = params

  const requestDiscount = useCallback(async () => {
    setLoading(true);
    try {
      const discountData = await api.festivals.requestFestivalDiscount(id, channel, discount);
      setActiveDiscount(discountData);
    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [discount, setLoading, channel, id, setActiveDiscount]);

  return (
    <FooterContainer>
      <TotalContainer>
        <SubTotal>Sub Total</SubTotal>
        <Value>
          {subtotal?.toLowerCase() !== 'free' && '$'}
          {subtotal}
          {fee !== '' && <Fee> + ${fee}</Fee>}
        </Value>
      </TotalContainer>
      <ButtonsContainer>
        <Discounts>
          <DiscountInput
            value={discount}
            disabled={loading}
            onChange={(e) => setDiscount(e.target.value)}
            placeholder="Enter discount code"
          />
          <ApplyDiscount disabled={loading} onClick={requestDiscount}>Apply</ApplyDiscount>
        </Discounts>
        <Continue
          disabled={disabled}
          onClick={onClick}
        >
          CONTINUE »
        </Continue>
      </ButtonsContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 600px;
  height: 150px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const SubTotal = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
`;

const Value = styled.div`
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
`;

const Fee = styled.span`
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Discounts = styled.div`
  height: 56px;
  width: 265px;
  border-radius: 8px;
  background-color: #f3f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 15px;
  box-sizing: border-box;
`;

const DiscountInput = styled.input`
  background-color: #f3f3f4;
  color: #999999;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  outline: none;
  text-align: right;
`;

const ApplyDiscount = styled.button`
  height: 31px;
  width: 82px;
  cursor: pointer;
  outline: none;
  color: #9b63f8;
  font-family: 'Circular Std';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
`;

const Continue = styled.button`
  height: 62px;
  width: 266px;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #69d27c;
  color: #ffffff;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.24px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export default CheckoutFooter;
