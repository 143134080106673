import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const CreatorField = ({ placeholder, create, buttonLabel, title }) => {
  const [value, setValue] = useState('');
  const creatorFieldRef = useRef(null);

  return (
    <Container onSubmit={() => create(value)}>
      <Title>{title}</Title>
      <CreatorInput
        value={value}
        className="simple-input"
        type="text"
        ref={creatorFieldRef}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <AddButton
        onClick={(e) => {
          e.preventDefault();
          create(value);
          setValue('')
        }}
      >
        {buttonLabel}
      </AddButton>
    </Container>
  );
};

const Container = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.div`
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 20px;
  align-self: flex-start;
  padding-left: 5%;
  padding-bottom: 10px;
`;

const CreatorInput = styled.input``;

const AddButton = styled.button`
  cursor: pointer;
  height: 25px;
  width: 114px;
  border-radius: 8px;
  border: 2px solid #9b63f8;
  background-color: #ffffff;
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  outline: none;
  transition: background-color 0.25s;
  position: absolute;
  right: 50px;
  bottom: 10px;

  &:hover {
    background-color: #9b63f8;
    border: 2px solid #9b63f8;
    color: #ffffff;
  }
`;

export default CreatorField;
