import React from "react";

const SettingsIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      opacity="0.472"
    >
      <g fill="#000" fillRule="nonzero" transform="translate(-903 -397)">
        <g transform="translate(211 194)">
          <g transform="translate(69)">
            <g transform="translate(1 182)">
              <g transform="translate(622 21)">
                <path d="M12.275 22h-3.55a.783.783 0 01-.781-.783v-2.166c0-.711-.355-1.32-.974-1.67a5.882 5.882 0 01-.2-.118 1.865 1.865 0 00-1.949-.02l-1.874 1.083a.782.782 0 01-1.067-.287L.105 14.961a.778.778 0 01-.078-.594.777.777 0 01.364-.476l1.956-1.132c.606-.35.967-1.008.967-1.76 0-.75-.36-1.407-.964-1.756L.39 8.11a.777.777 0 01-.363-.475.779.779 0 01.077-.594L1.88 3.96a.782.782 0 011.067-.288l2.039 1.18c.293.17.612.256.948.256.97 0 2.01-.79 2.01-1.966V.783c0-.432.35-.783.78-.783h3.551c.431 0 .781.351.781.783v2.36c0 1.176 1.04 1.966 2.01 1.966.336 0 .655-.086.948-.256l2.039-1.18a.784.784 0 011.067.286l1.775 3.082c.104.18.132.391.078.594a.778.778 0 01-.365.475l-1.957 1.133c-.602.348-.962 1.004-.962 1.755 0 .754.36 1.413.964 1.761l1.956 1.132a.78.78 0 01.364.475.78.78 0 01-.078.594l-1.775 3.08a.783.783 0 01-1.067.287l-1.874-1.084a1.865 1.865 0 00-1.95.021c-.066.04-.131.079-.198.117-.62.35-.975.958-.975 1.67v2.166c0 .432-.35.783-.781.783zm-6.496-5.815c.485 0 .97.14 1.402.402.058.036.116.07.176.104a2.645 2.645 0 011.377 2.36v2.166l3.532-.008V19.05c0-1.006.502-1.866 1.376-2.36.06-.033.12-.069.18-.105a2.692 2.692 0 012.752-.029l1.874 1.084 1.763-3.077-1.953-1.12c-.851-.491-1.36-1.406-1.36-2.446 0-1.037.508-1.95 1.357-2.441l1.958-1.133-1.777-3.068-2.026 1.182c-.41.237-.875.362-1.344.362-1.544 0-2.8-1.237-2.8-2.758V.782l-3.54.01.008 2.35c0 1.521-1.256 2.759-2.8 2.759-.469 0-.933-.126-1.343-.363l-2.039-1.18L.79 7.438l1.956 1.12c.851.493 1.36 1.406 1.36 2.442 0 1.038-.51 1.952-1.362 2.446L.786 14.577l1.778 3.067 1.862-1.087c.42-.243.888-.372 1.353-.372zm4.721-.798a4.291 4.291 0 010-8.581 4.291 4.291 0 010 8.581zm0-7.79a3.5 3.5 0 000 6.999 3.5 3.5 0 000-6.998z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SettingsIcon;
