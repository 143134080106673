import React from 'react';
import DropZone from 'react-dropzone';
import styled from 'styled-components';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
// import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const ImageDropZone = ({ preview = null, onSelect }) => {
  return (
    <StyledDropZone
      onDrop={onSelect}
      accept="image/*"
      multiple={false}
      preview={preview}
    >
      {
        preview && (
          <Preview src={preview} />
        )
      }
      <Placeholder
        preview={preview}
      >
        <CameraIcon htmlColor="#999" preview={preview} />
        <ImageDescription
          preview={preview}
        >
          Photos MUST be at least 1800px x 800px.
        </ImageDescription>
        <ImageDescription
          preview={preview}
        >
          Please ensure your typography fits here to avoid cropping.
        </ImageDescription>
        <UploadButton>
          Upload cover
        </UploadButton>
      </Placeholder>
    </StyledDropZone>
  )
};

const Preview = styled.img`
  height: 326px;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;

const CameraIcon = styled(CameraAltOutlinedIcon)`
  width: 84px !important;
  height: 68px;
  flex: 0 0 68px;
  opacity: ${({ preview }) => preview ? 0 : 1};
`;

// const Eye = styled(VisibilityOutlinedIcon)`
//   height: 20px;
//   width: 32px;
// `;

const ImageDescription = styled.p`
  color: #999;
  font-family: 'CircularStd Book';
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  width: 202px;
  opacity: ${({ preview }) => preview ? 0 : 1};

  & + & {
    margin-top: 24px;
  }
`;

const Placeholder = styled.div`
  align-items: center;
  border-style: dashed;
  border-width: 0 2px 0 2px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  margin: 0 auto;
  padding: 22px 18px;
  width: 237px;
  z-index: 1;
  border-color: ${({ preview }) => preview ? 'black' : 'rgba(151,151,151,0.57)'};
`;

// const PreviewEyeContainer = styled.div`
//   align-items: center;
//   background-color: #F5F5F5;
//   border-radius: 25px;
//   display: flex;
//   height: 44px;
//   justify-content: center;
//   position: absolute;
//   right: 23px;
//   top: 18px;
//   width: 68px;
// `;

const StyledDropZone = styled(DropZone)`
  border-width: 2px;
  border-style: dashed;
  display: flex;
  flex-direction: row;
  height: 326px;
  position: relative;
  width: 563px;
  border-color: ${({ preview }) => preview ? 'black' : 'rgba(151,151,151,0.57)'};
`;

const UploadButton = styled.button`
  background: none;
  border-radius: 8px;
  border: 2px solid #9B63F8;
  box-sizing: border-box;
  color: #9B63F8;
  cursor: pointer;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  height: 44px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  width: 144px;
`;

export default ImageDropZone;
