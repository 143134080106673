import React, { Component } from 'react';
import { func, arrayOf, shape, oneOf, array, object } from 'prop-types';
import { Field } from 'redux-form';
import { Dialog, Toggle } from 'material-ui';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import Button from 'components/Button';
import SettingsIcon from 'components/common/SettingsIcon';

import { BUTTON_STYLES, TICKET_TYPES } from 'appconstants';

import './styles.css';

const styles = {
  bodyStyle: {
    padding: '0',
  },
  contentStyle: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    boxShadow: '0 2px 14px 0 rgba(0,0,0,0.23)',
    maxHeight: '346px',
    width: '412px',
  },
  titleStyle: {
    borderRadius: '3px 3px 0 0',
    backgroundColor: '#F5F5F5',
    color: '#3C2784',
    fontFamily: 'CircularStd Book',
    fontSize: '14px',
    fontWeight: '500',
    height: 'auto',
    lineHeight: '24px',
    padding: '25px 18px',
  },
  toggleStyle: {
    elementStyle: {
      height: '32px',
    },
    thumbStyle: {
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
      height: '22px',
      left: '6px',
      top: '11px',
      width: '22px',
    },
    thumbSwitchedStyle: {
      backgroundColor: '#FFFFFF',
      left: 'auto',
      right: '6px',
    },
    trackStyle: {
      backgroundColor: '#D5D5D5',
      height: '32px',
      width: '66px',
    },
    trackSwitchedStyle: {
      backgroundColor: '#69D27C',
    },
  }
};

class Tickets extends Component {
  constructor() {
    super();
    this.state = {
      dialogOpen: false,
      ticketIndex: -1,
    };
  }

  addTicket = (type) => {
    this.props.fields.push({
      price: type === TICKET_TYPES.FREE_TICKET ? 0 : 0.99,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogOpen: false,
      ticketIndex: -1,
    });
  }

  openDialog = (index) => {
    const { tickets } = this.props;

    this.setState({
      description: tickets[index].longDescription || "",
      dialogOpen: true,
      ticketIndex: index,
      visibility: tickets[index].visibility,
    });
  };

  render() {
    const {
      fields,
      onTicketChange,
      tickets,
      channel: { isFree: isChannelFree },
    } = this.props;

    return (
      <div className="ticket-list">
        {fields.map((item, index) => {
          const isFree = tickets[index].price === 0;

          return (
            <div className="list-item" key={index}>
              <Field name={`${item}.id`} component="input" type="hidden" />
              <div className="field-title-container">
                <div>
                  <span className="field-label ticket-field-label">
                    TITLE
                  </span>
                </div>
                <Field
                  id={`${item}.description`}
                  name={`${item}.description`}
                  type="text"
                  component="input"
                />
              </div>
              <div>
                <div>
                  <span className="field-label ticket-field-label">
                    QTY
                  </span>
                </div>
                <Field
                  name={`${item}.total`}
                  type="text"
                  component="input"
                />
              </div>
              <div className="input-icon-container left">
                <div>
                  <span className="field-label ticket-field-label">
                    COST
                  </span>
                </div>
                {isFree ? (
                  <div>
                    <p className="free-label">FREE</p>
                  </div>
                ) : (
                  <div className="input-icon-container left">
                    <span className="input-icon left">$</span>
                    <Field
                      name={`${item}.price`}
                      type="text"
                      component="input"
                    />
                  </div>
                )}
              </div>
              <div className="settings-button-wrapper">
                <button
                  className="button-icon-wrapper"
                  onClick={() => this.openDialog(index)}
                  type="button"
                >
                  <SettingsIcon />
                </button>
              </div>
              <Dialog
                bodyClassName="ticketing-dialog-body"
                bodyStyle={styles.bodyStyle}
                contentStyle={styles.contentStyle}
                open={this.state.dialogOpen && this.state.ticketIndex === index}
                onRequestClose={this.closeDialog}
                title={(
                  <div>TICKET SETTINGS<CloseIcon color="#999999" onClick={this.closeDialog} /></div>
                )}
                titleClassName="ticketing-dialog-title"
                titleStyle={styles.titleStyle}
              >
                <div>
                  <div className="description">
                    <span>DESCRIPTION</span>
                    <textarea
                      placeholder="Share more about this ticket"
                      onChange={(e) => { this.setState({ description: e.target.value }); }}
                      value={this.state.description}
                    />
                  </div>
                  <div className="visibility">
                    <span>VISIBILITY</span>
                    <Toggle
                      className="visibility-toggle"
                      onToggle={(e, isInputChecked) => { this.setState({ visibility: isInputChecked }); }}
                      thumbStyle={styles.toggleStyle.thumbStyle}
                      thumbSwitchedStyle={styles.toggleStyle.thumbSwitchedStyle}
                      toggled={this.state.visibility}
                      trackStyle={styles.toggleStyle.trackStyle}
                      trackSwitchedStyle={styles.toggleStyle.trackSwitchedStyle}
                    />
                  </div>
                  <div className="tickets-buttons-container">
                    <button
                      onClick={() => {
                        // TODO JOAO: remove ticket from db, this is only removing it
                        // from the experience's ticket list
                        fields.remove(index);
                        this.closeDialog();
                      }}
                    >
                      Delete<DeleteIcon color="#999999" />
                    </button>
                    <button
                      onClick={() => {
                        onTicketChange(index, 'longDescription', this.state.description);
                        onTicketChange(index, 'visibility', this.state.visibility);
                        this.closeDialog();
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </Dialog>
            </div>
          );
        })}
        {!isChannelFree && (
          <Button
            onClick={() => this.addTicket(TICKET_TYPES.PAID_TICKET)}
            buttonStyle={BUTTON_STYLES.TRANSPARENT}
            type="button"
            className="add-tickets-button"
          >
          + Paid ticket
        </Button>
        )}
        <Button
          onClick={() => this.addTicket(TICKET_TYPES.FREE_TICKET)}
          buttonStyle={BUTTON_STYLES.TRANSPARENT}
          type="button"
        >
          + Free ticket
        </Button>
      </div>
    );
  }
}

Tickets.propTypes = {
  fields: arrayOf(
    shape({
      type: oneOf(Object.keys(TICKET_TYPES).map((k) => TICKET_TYPES[k])),
    })
  ),
  onTicketChange: func,
  tickets: array,
  channel: object,
};

export default Tickets;
