const validate = (tickets = []) => (
  tickets.length > 0 &&
  tickets.every(({ description, days, price, total, isTicket }) => {
    return (
      !!(description && description.trim()) &&
      (isTicket || (days > 0)) &&
      total > 0 &&
      price >= 0
    );
  })
);

export default validate;
