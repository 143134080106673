import React from 'react';
import { func, object, node } from 'prop-types';
import Button from 'components/Button';
import { BUTTON_STYLES } from 'appconstants';
import './styles.css';

const ExperienceGuidelinesMob = ({ experience, goToTickets, header }) => (
  <div className="guidelines-mobile-container">
    <div className="guidelines-mobile-wrapper">
      {header}
      <div className="guidelines-mobile-info">
        <div className="guidelines-mobile-title">
          <h3>Thank you for joining us! </h3>
          <div className="guidelines-dividor" />
          <h3>{`Here’s how you gather as a community…`}</h3>
        </div>
        <ul className="guidelines-mobile-list">
          {experience.guidelines &&
            experience.guidelines.map(
              (guideline) => (
                <li className="guidelines-mobile-item-wrapper">
                  <div
                    htmlFor="guideline1"
                    className="guidelines-mobile-item"
                    dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                      __html: guideline.guideline,
                    }}
                  />
                </li>
              ) // eslint-disable-line
            )}
        </ul>
      </div>
    </div>
    <div className="guidelines-footer-mobile">
      <div className="btn-wrap-mob clearfix gradient-btn-mob">
        <div className="guidelines-button-mobile-wrapper">
          <Button
            type="button"
            className="guidelines-button-mobile"
            onClick={goToTickets}
            buttonStyle={BUTTON_STYLES.PURPLE_SQUARE}
          >
            I agree
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default ExperienceGuidelinesMob;

ExperienceGuidelinesMob.propTypes = {
  experience: object.isRequired,
  goToTickets: func,
  header: node,
};
