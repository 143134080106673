/* eslint-disable react/no-unused-prop-types */
import React, { Component } from "react";
import { object, func } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import MediaQuery from 'react-responsive';
import { CircularProgress } from "material-ui";

import { loadExperienceProfile } from "actions/experiences";
import { saveUserRelive } from "actions/users";
import { getReliveVideo } from "actions/videos";
import { selectCurrentExperience } from "selectors/experiences";
import AdminHeaderMob from 'components/AdminHeaderMob';
import VideoPayer from "components/VideoPlayer";
import { PLAYERS } from "appconstants";
import { APP_THEMES } from "appconstants/colors";
import routes from "appconstants/routes";

import "./styles.css";

class RelivePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null
    };
    this.validateAccess();
  }

  async validateAccess() {
    await this.loadExperience();

    const experience = this.props.experience;
    if (
      !experience.reliveUrl ||
      (experience.relivePlayer !== PLAYERS.YOUTUBE &&
        experience.relivePlayer !== PLAYERS.VIMEO)
    ) {
      browserHistory.push(routes.mainPage);
    } else {
      await this.loadVideo();
    }
  }

  loadExperience = async () => {
    const { alias, channel } = this.props.params;
    await this.props.loadExperienceProfile({ alias, channel });
  };

  loadVideo = async () => {
    try {
      const { alias, channel } = this.props.params;
      const response = await this.props.getReliveVideo({ alias, channel });
      if (!response) throw Error();
      this.setState({ videoData: response, loading: false });
      await this.props.saveUserRelive({ channel, alias });
    } catch (err) {
      this.setState({ errorMessage: "This relive is not available", loading: false });
    }
  }

  render() {
    const { loading, errorMessage, videoData } = this.state;
    const isLoading = loading;
    const isSuccess = !loading && !errorMessage;
    const isError = !loading && errorMessage;

    return (
      <div className="relive-page">
        <MediaQuery query="(max-device-width: 1224px)">
          <AdminHeaderMob back namePage="Re-live" theme={APP_THEMES.DARK} />
        </MediaQuery>
        {isLoading && <CircularProgress className="relive-page-loader" color="#976efa" />}
        {isSuccess && <VideoPayer videoData={videoData} />}
        {isError && (
          <div className="relive-page-error">
            <span>{errorMessage}</span>
          </div>
        )}
      </div>
    );
  }
}

RelivePage.propTypes = {
  params: object.isRequired,
  loadExperienceProfile: func.isRequired,
  experience: object,
  getReliveVideo: func.isRequired,
  saveUserRelive: func.isRequired
};

const mapStateToProps = (state) => ({
  experience: selectCurrentExperience(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { loadExperienceProfile, getReliveVideo, saveUserRelive },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RelivePage);
