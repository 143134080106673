/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, SubmissionError, stopSubmit
} from 'redux-form';
import { connect } from 'react-redux';
import { diff } from 'deep-object-diff';
import { CircularProgress } from 'material-ui';
import Snackbar from 'material-ui/Snackbar';
// import Check from 'material-ui/svg-icons/action/check-circle';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import {
  selectCategoriesForExperienceInChannel,
  selectFoods,
  selectTags,
  selectDrinks,
} from "selectors/tags";
import { selectChannelUserById } from 'selectors/user';

import Loading from 'components/Loading';
import DialogFeedBack from 'components/DialogFeedBack';

import moment from 'moment';

import { COMPONENT_PAGES, VIRTUAL_LOCALIZATION, LOCATION, EXPERIENCES_TYPES } from "appconstants";
import {
  validateDescription,
  validateDates,
  validateLocation,
  validateTickets,
  validatePhotos,
} from "utils/validation";

import * as actionsExperiences from 'actions/experiences';
import * as actionsTags from 'actions/tags';

import {
  Navigation,
} from 'containers/ExperienceWizard';
import Footer from 'components/WizardComponents/Footer';

import {
  pagesVirtual,
  pagesIRL,
  pagesComponents,
} from './pages';
import { selectCurrentExperience } from 'selectors/experiences';

const styles = {
  constainerSnackbar: {
    position: "fixed",
    top: "0px",
    height: "25px",
  },
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
  snackbarInfo: {
    backgroundColor: 'rgb(14, 141, 186)',
  },
};
// const colors = {
//   green: '#69D27C',
//   red: '#FF6767',
//   yellow: '#FEBD21',
// };
class ExperienceManagerPage extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.publishExperience = this.publishExperience.bind(this);
    this.validate = this.validate.bind(this);
    this.submit = this.submit.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleFestivalDates = this.handleFestivalDates.bind(this)
    this.handleFestivalGuidelines = this.handleFestivalGuidelines.bind(this)
    this.state = {
      error: false,
      info: false,
      page: 1,
      pagesCompleted: 1,
      isPublishBtn: false,
      dialogFeedBack: false,
      pages: props?.experience?.type === EXPERIENCES_TYPES.PHYSICAL ? pagesIRL : pagesVirtual,
      pageComponents: pagesComponents,
      pageId: COMPONENT_PAGES.DESCRIPTION_PAGE,
      subcategories: [],
      loading: false,
      showErrors: true,
      locationsVirtuals: VIRTUAL_LOCALIZATION,
      locations: LOCATION,
    };
  }

  componentDidUpdate() {
    const {
      props: {
        experience,
      },
      state: {
        pageComponents,
        pages,
      },
    } = this;

    if (experience && experience.festival) {
      const newPages =  pages.filter(({ id }) => id !== COMPONENT_PAGES.TICKETS_PAGE);
      const newPagesComponents =  pageComponents.filter(({ id }) => id !== COMPONENT_PAGES.TICKETS_PAGE);

      if (newPages.length !== pages.length) {
        this.setState({
          pages: newPages,
          pageComponents: newPagesComponents,
        });
      }
    }
  }

  validate() {
    const {values} = this.state;
    const valid = validateTickets(values).status
    && validateLocation(values).status
    && validateDescription(values).status
    && validatePhotos(values).status
    && validateDates(values).status;
    if (!valid) {
      this.setState({
        error:
          "Your experience is not ready to be published. Check the info above for details.",
      });
    }
    return valid;
  }

  async publishExperience() {
    const { publish, experience } = this.props;
    const id = experience ? experience.id : this.props.params.id;
    if (this.validate()) {
      try {
        this.setState({loading: true});
        await publish(id);
        this.setState({loading: false});
      }
      catch(err) {
        this.setState({loading: false, showErrors: true});
        throw new SubmissionError({
          _error: `Submission error: ${err.message}`,
        });
      }
      this.setState({ isPublishBtn: true, dialogFeedBack: true, loading: false });
    }
  }

  goToPage(page){
    const { handleSubmit } = this.props;
    const { pageId } = this.state;
    let validationPage;
    handleSubmit((values) => {
      switch(pageId) {
        case COMPONENT_PAGES.DESCRIPTION_PAGE:
          validationPage = validateDescription(values);
          break;
        case COMPONENT_PAGES.DATE_AND_TIME_PAGE:
          validationPage = validateDates(values);
          break;
        case COMPONENT_PAGES.TICKETS_PAGE:
          validationPage = validateTickets(values);
          break;
        case COMPONENT_PAGES.LOCALIZATION_PAGE:
          validationPage = validateLocation(values);
          break;
        case COMPONENT_PAGES.ADD_ARTISTS_PAGE:
          validationPage = {status: true}; //this.validateArtists(values);
          break;
        case COMPONENT_PAGES.ADD_GUIDELINE_PAGE:
          validationPage = {status: true};
          break;
        case COMPONENT_PAGES.PRESENTATION_PAGE:
          validationPage = validatePhotos(values);
          break;
        case COMPONENT_PAGES.VIRTUAL_LOCALIZATION_PAGE:
          validationPage = validateLocation(values);
          break;
        default:
          validationPage = {status: true};
      }

      if(validationPage.status){
        const newState = {
          page,
          pageId: pagesComponents[page - 1].id,
          values
        };
        return this.updateData(values, newState);
      }
      return this.showErrorMessage(validationPage.errors[0]);
    })();
  }

  showErrorMessage(message){
    this.setState({
      error: message,
    });
  }

  nextPage(e) {
    e.preventDefault();
    const { handleSubmit } = this.props;
    const {
      page, pagesCompleted, pages, pageId
    } = this.state;
    handleSubmit((values) => {
      let validationPage;
      switch(pageId) {
        case COMPONENT_PAGES.DESCRIPTION_PAGE:
          validationPage = validateDescription(values);
          break;
        case COMPONENT_PAGES.DATE_AND_TIME_PAGE:
          validationPage = validateDates(values);
          break;
        case COMPONENT_PAGES.TICKETS_PAGE:
          validationPage = validateTickets(values);
          break;
        case COMPONENT_PAGES.LOCALIZATION_PAGE:
          validationPage = validateLocation(values);
          break;
        case COMPONENT_PAGES.ADD_ARTISTS_PAGE:
          validationPage = {status: true}; //this.validateArtists(values);
          break;
        case COMPONENT_PAGES.ADD_GUIDELINE_PAGE:
          validationPage = {status: true};
          break;
        case COMPONENT_PAGES.PRESENTATION_PAGE:
          validationPage = validatePhotos(values);
          break;
        case COMPONENT_PAGES.VIRTUAL_LOCALIZATION_PAGE:
          validationPage = validateLocation(values);
          break;
        default:
          validationPage = {status: true};
      }

      if(validationPage.status){
        const newState = {
          page: pagesCompleted === pages.length ? pages.length : page + 1,
          pageId: pages[page].id,
          values
        };
        return this.updateData(values, newState);
      }
      return this.showErrorMessage(validationPage.errors[0]);
    })();
  }

  async submit(payload) {
    const data = payload;
    const {saveExperience, loadExperienceProfile} = this.props;
    if(this.state.isPublishBtn) {
      loadExperienceProfile({channel: data.channel, alias: data.alias});
    } else{
      data.images = data.images && data.images.length
        ? data.images.filter((i) => {
          return i;
        })
        : data.images;
      this.setState({loading: true, showErrors: true});
      try {
        await saveExperience({data});
        this.setState({loading: false});
      }
      catch(err) {
        this.setState({loading: false});
        throw new SubmissionError({
          _error: `Submission error: ${err.message}`,
        });
      }
    }
  }

  async updateData(data, newState){
    console.log('updating data')
    const { initialValues, saveExperience, router } = this.props;
    data.images = data.images && data.images.length
      ? data.images.filter((i) => {
        return i;
      })
      : data.images;
    let changedValues = diff(this.state.lastUpdatedValues || initialValues, data);
    if (data.subcategories?.length) {
      changedValues.subcategories = data.subcategories
    }
    if(!Object.keys(changedValues).length){
      this.setState(newState);
      return;
    }

    // To remove deleted images, we'll do the images diff manually.
    const unSavedImages = (data.images || []).filter(img => img.data);
    const savedImages = (data.images || []).filter(img => img.url);
    changedValues.images = {savedImages, unSavedImages};
    if (data.subcategories) {
      delete data.subcategory
    }
    this.setState({loading: true, showErrors: true});
    if (!data.id) {
      data.id = router.location.pathname.split('/')[2]
    }
    try {
      await saveExperience({data, changedValues});
      this.setState(newState);
    }
    catch(err) {
      this.setState({loading: false});
      throw new SubmissionError({
        _error: `Submission error: ${err.message}`,
      });
    }
    this.setState({lastUpdatedValues: data, loading: false});
  }

  handleStartDateChange = (event, newValue) => {
    const m = moment(newValue);
    this.props.change('endDate', m.toDate());
  };

  handleStartTimeChange = (event, newValue) => {
    this.props.change('startTime', moment(newValue).toDate());
    this.props.change('endTime', moment(newValue).add(2, 'hours').toDate());
  };

  handleKeyDown(event){
    // Avoid submit on enter, but allow newlines in textareas
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();
    }
  }

  async handleFestivalDates(start, end){
    const { experience, saveExperience, change } = this.props;
    let dateValues = {
      startTime: start,
      startDate: start,
      endTime: end,
      endDate: end
    }
    let changedValues = diff(experience, dateValues);
    try {
      await saveExperience({data: experience, changedValues})
      change('startTime', start)
      change('startDate', start)
      change('endTime', end)
      change('endDate', end)
    } catch (err) {
      console.log('err', err)
    }
  }

  async handleFestivalGuidelines() {
    const { experience, saveExperience, change } = this.props
    let values
    if (experience.fullFestival && experience.fullFestival.guidelines.length >= experience.guidelines.length) {
      values = {}
      values.guidelines = experience.fullFestival.guidelines
      try {
        await saveExperience({data: experience, changedValues: values})
        change('guidelines', values.guidelines)
      } catch (err) {
        console.log('err', err)
      }
    }
  }

  handleTicketChange = (index, field, newValue) => {
    this.props.change(`tickets[${index}].${field}`, newValue);
  };

  handleRequestClose = () => {
    this.setState({
      showErrors: false,
      error: ''
    });
  };

  renderSpinner() {
    return (
      <div style={{
        textAlign: 'center', width: '100%', position: 'absolute', zIndex: 1000, height: '100%', fontFamily: 'CircularStd Bold', background: 'linear-gradient(24deg, rgb(125, 152, 241) 13%, rgb(229, 120, 162) 100%)', color: '#fff'
      }}
      >
        <CircularProgress size={250} thickness={12} color="rgb(36, 106, 178)" style={{ paddingTop: '70px' }} />
        <p style={{ fontSize: '25px', fontWeight: 'bold', paddingTop: '50px' }}>Your experience is uploading... </p>
        <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Please do not close this window</p>
      </div>
    );
  }

  renderSnackbar() {
    const { error: submitError, submitFailed } = this.props;
    const { showErrors, error } = this.state;
    const message = (submitFailed && submitError) || error;
    const open = showErrors && message;
    return (
      <Snackbar
        open={!!open}
        message={message}
        autoHideDuration={4000}
        bodyStyle={styles.snackbar}
        onRequestClose={this.handleRequestClose}
        style={styles.constainerSnackbar}
      />
    );
  }

  renderInfoUploading() {
    return <Loading message="Uploading images..." />;
  }

  render() {
    const {
      page,
      locations,
      locationsVirtuals,
      pages,
      pageComponents,
    } = this.state;

    const {
      handleSubmit,
      experienceCategories,
      subcategories,
      tagsList,
      drinksList,
      foodsList,
      category,
      subcategory,
      images,
      imagesArtists,
      tickets,
      experience,
      change,
      channel,
      experienceSubcategories,
      router
    } = this.props;
    const allProps = {
      onSubmit: this.nextPage,
      experienceCategories,
      subcategories,
      category,
      subcategory,
      tagsList,
      submit: this.submit,
      renderInfoUploading: this.renderInfoUploading,
      onStartDateChange: this.handleStartDateChange,
      onStartTimeChange: this.handleStartTimeChange,
      onTicketChange: this.handleTicketChange,
      goToPage: this.goToPage,
      locations,
      locationsVirtuals,
      foods: foodsList,
      drinks: drinksList,
      tickets,
      loading: this.state.loading,
      channel,
      change,
      experienceId: this.props.params.id,
      experienceSubcategories,
      experience,
      router,
      handleFestivalDates: this.handleFestivalDates,
      handleFestivalGuidlines: this.handleFestivalGuidelines,
      experienceId: experience?.id
    };

    const pageId = pages[page - 1].id;

    const PageComponent = pageComponents.find(({ id: componentsId }) => pageId === componentsId).component;
    let buttons;
    if (page === pages.length) {
      buttons = (
        <Footer
          buttonLabel={experience?.status === 'draft' ? "Publish" : "Save"}
          lastSaved={experience?.updatedOn}
          status={experience?.published}
          onNextStep={handleSubmit(this.publishExperience)}
          saving={this.state.loading}
        />
      );
    } else {
      buttons = (
        <Footer
          lastSaved={experience?.updatedOn || experience?.createdOn}
          status={experience?.published}
          onNextStep={this.nextPage}
          saving={this.state.loading}
        />
      );
    }

    return (
      <div className="page-container page-container-flex clearfix">
        { this.state.dialogFeedBack && <DialogFeedBack channelSlug={this.props.channel.urlSlug} /> }
        <form
          onSubmit={page !== pages.length && handleSubmit(values => this.submit(values))}
          onKeyDown={(e) => this.handleKeyDown(e)}
        >
          <Field name="id" component="input" type="hidden" />
          <section className="page-content">
            <div className="container">
              <div className="experience-container clearfix">
                <Navigation
                  currentPage={page}
                  items={pages.map(({ label }) => label)}
                  goToPage={this.goToPage}
                />
                <div className="container-right">
                  <div className="page-title">{ pageComponents[page - 1].title }</div>
                  {(images.loading || imagesArtists.loading)
                    ? this.renderInfoUploading()
                    : (
                      <div>
                        <PageComponent {...allProps} />
                        {!this.state.loading && buttons }
                      </div>
                    )}
                  {this.renderSnackbar()}
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    );
  }
}

ExperienceManagerPage.propTypes = {
  id: PropTypes.any,
  experience: PropTypes.any,
  params: PropTypes.any,
  publish: PropTypes.func,
  loadExperienceProfile: PropTypes.func,
  handleSubmit: PropTypes.func,
  saveExperience: PropTypes.func,
  category: PropTypes.string,
  subcategory: PropTypes.string,
  experienceCategories: PropTypes.array,
  subcategories: PropTypes.array,
  tagsList: PropTypes.array,
  drinksList: PropTypes.array,
  foodsList: PropTypes.array,
  submitFailed: PropTypes.bool,
  error: PropTypes.string,
  images: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  imagesArtists: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  change: PropTypes.func,
  tickets: PropTypes.array,
  initialValues: PropTypes.object,
  channel: PropTypes.object,
  experienceSubcategories: PropTypes.array
};

ExperienceManagerPage = reduxForm({
  form: 'ExperienceForm',
  forceUnregisterOnUnmount: true,
  onChange: (values, dispatch, props) => {
    if (props.submitFailed && !props.submitting) {
      dispatch(stopSubmit('ExperienceForm'));
    }
  },
  // enableReinitialize: true
})(ExperienceManagerPage);

const selector = formValueSelector('ExperienceForm');

ExperienceManagerPage = connect((state) => {
  const initialValues = selectCurrentExperience(state);
  if (initialValues) delete initialValues.status;
  const channelId = get(initialValues, 'channel.id');
  const channel = selectChannelUserById(state, { channelId });
  return {
    initialValues,
    experiences: state.experiences,
    images: state.images,
    imagesArtists: state.imagesArtists,
    artists: state.artists,
    tickets: selector(state, 'tickets'),
    tagsList: selectTags(state),
    foodsList: selectFoods(state),
    drinksList: selectDrinks(state),
    festival: selector(state, 'festival.id') || state.experiences?.experience?.id,
    festivals: state.festivals.userFestivals,
    experienceCategories: channel && channel.experienceCategories,
    subcategories: selectCategoriesForExperienceInChannel(state, { channelId }),
    experience: selectCurrentExperience(state),
    channel,
    experienceSubcategories: channel && channel.experienceSubcategories
  };
}, {
  ...actionsTags,
  ...actionsExperiences,
})(withRouter(ExperienceManagerPage));

export default ExperienceManagerPage;
