import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, object, array, string, func } from 'prop-types';
import styled from 'styled-components';
import { CircularProgress } from 'material-ui';
import { isEmpty } from 'lodash';

import ScheduleIcon from '../../images/list-icon.jsx';
import TiledIcon from '../../images/tile-icon.jsx';
import {
  selectLoadingExperiences,
  selectUpcomingExperiences,
  selectReliveExperiences,
} from 'selectors/experiences';
import { selectCurrentChannel } from 'selectors/channels';

import AdminHeaderMob from 'components/AdminHeaderMob';
import ExperienceMobileCard from 'components/ExperienceMobileCard';
import ExperienceScheduleMobileCard from '../ExperienceScheduleMobileCard';
import IconButton from 'material-ui/IconButton';
import { getFullDate } from 'utils/dates';
import { browserHistory } from 'react-router';

import './styles.css';
import { selectUpcomingFestivals } from 'selectors/festivals.js';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
class MainPageMob extends Component {
  constructor(props) {
    super(props);
    this.renderItem = this.renderItemMob.bind(this);
    this.renderScheduleItem = this.renderScheduleItemMob(this);
    this.state = {}
  }
  componentDidMount() {
    this.setState({
      infinitePlaya: /infiniteplaya/.test(window.location.pathname),
    });
  }
  renderItemMob(experience, index) {
    const { channel, name, startTime, images, alias, isFestival } = experience;
    return (
      <ExperienceMobileCard
        key={index}
        channel={channel}
        title={name}
        date={startTime}
        imageUrl={!isEmpty(images) && images[0]}
        alias={alias}
        isFestival={isFestival}
      />
    );
  }

  renderScheduleItemMob(experience, isLastItem, lastStartTime, index) {
    const experienceDate = new Date(experience.startTime);

    return (
      <div>
        {lastStartTime === '' ||
        experienceDate.toDateString() !== new Date(lastStartTime).toDateString() ? (
          <TitleContainer key={index}>
            <ScheduledContainer>
              {lastStartTime === '' ? <ScheduleTitle>Schedule</ScheduleTitle> : <ScheduleTitle />}
              <Dot />
            </ScheduledContainer>
            <DateContainer>{getFullDate(experienceDate)}</DateContainer>
          </TitleContainer>
        ) : null}
        <ExperienceScheduleMobileCard experience={experience} isLastItem={isLastItem} />
      </div>
    );
  }

  renderSpinner() {
    return (
      <div className="loader-mobile-container">
        <CircularProgress size={150} thickness={10} color={'#976efa'} />
      </div>
    );
  }
  
  render() {
    const {
      upcomingFestivals,
      upcomingExperiences,
      loading,
      currentChannel,
      reliveExperiences,
      view,
      setView,
    } = this.props;
    const noFestivalUpcomingExperiences = upcomingExperiences.filter(el => !el.festival)
    return (
      <PageWrapper>
        <div>
          <AdminHeaderMob />
          <div className="first-divider" />
          {currentChannel && (
            <div className="header-mobile-channel-logo">
              <img
                src={currentChannel.images[0]}
                alt={currentChannel.name}
                style={{
                  backgroundColor: currentChannel.color,
                }}
              />
            </div>
          )}
          {loading && this.renderSpinner()}
          <ExperiencesBackground>
            {
              <TopBar>
                <IconButton
                  style={{ border: '0', borderRight: '1px solid ##EBEBEB' }}
                  onClick={() => {
                    browserHistory.push(`${window.location.pathname}`);
                    setView('tiled');
                  }}
                >
                  <StyledIcon>
                    <TiledIcon active={view === 'tiled'} />
                  </StyledIcon>
                </IconButton>
                <IconButton
                  onClick={() => {
                    browserHistory.push(`${window.location.pathname}#schedule`);
                    setView('schedule');
                  }}
                >
                  <StyledIcon>
                    <ScheduleIcon active={view === 'schedule'} />
                  </StyledIcon>
                </IconButton>
              </TopBar>
            }
            {!loading && !!upcomingExperiences.length && (
              <div>
                {view === 'tiled' ? (
                  <>
                    {(upcomingFestivals && upcomingFestivals.length) ? (
                      <div>
                        <h3 className="list-title-mobile">{this.state.infinitePlaya ? 'Featured Camps' : 'Upcoming Festivals'}</h3>
                        {upcomingFestivals?.map((experience, index) =>
                          this.renderItemMob(experience, index)
                        )}
                      </div>
                    ): null}
                    {(noFestivalUpcomingExperiences && noFestivalUpcomingExperiences.length) ? (<div>
                      <h3 className="list-title-mobile">Upcoming Experiences</h3>
                      {noFestivalUpcomingExperiences.map((experience, index) =>
                        this.renderItemMob(experience, index)
                      )}
                    </div>) : null}
                  </>
                ) : (
                  <ScheduleContainer>
                    {upcomingExperiences.map((experience, index) =>
                      this.renderScheduleItemMob(
                        experience,
                        upcomingExperiences.length - 1 === index,
                        index !== 0 ? upcomingExperiences[index - 1].startTime : '',
                        index
                      )
                    )}
                  </ScheduleContainer>
                )}
              </div>
            )}
            {view === 'tiled' && !loading && !!reliveExperiences.length && (
              <div>
                <h3 className="list-title-mobile">Re-live</h3>
                {reliveExperiences.map((experience) => this.renderItemMob(experience))}
              </div>
            )}
          </ExperiencesBackground>
        </div>
      </PageWrapper>
    );
  }
}

MainPageMob.propTypes = {
  loading: bool,
  upcomingExperiences: array,
  currentChannel: object,
  reliveExperiences: array,
  view: string,
  setView: func,
};

const mapStateToProps = (state) => ({
  upcomingExperiences: selectUpcomingExperiences(state),
  upcomingFestivals: selectUpcomingFestivals(state),
  loading: selectLoadingExperiences(state),
  currentChannel: selectCurrentChannel(state),
  reliveExperiences: selectReliveExperiences(state),
});

const TopBar = styled.div`
  height: 39px;
  width: 100px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 40px;
    border-right: 1px solid #ebebeb;
  }
`;

const ExperiencesBackground = styled.div`
  background-color: #f3f3f4;
  display: flex;
  justify-content: center;
  padding: 12px;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: 18px;
    width: 18px;
  }
`;

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 95vw;
`;

const TitleContainer = styled.div`
  height: 104px;
  padding: 0 16px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 142px auto;
  align-items: center;
`;

const ScheduledContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  position: relative;
  align-items: center;
`;

const ScheduleTitle = styled.div`
  height: 20px;
  width: 84px;
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
`;

const Dot = styled.div`
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 8px solid #f3f3f4;
  background-color: #999999;
  z-index: 14;

  &::before {
    content: '';
    position: absolute;
    border-left: 1px solid #ebebeb;
    top: 24px;
    z-index: 10;
    height: 64px;
    right: 26px;
  }
`;

const DateContainer = styled.div`
  height: 20px;
  width: 100%;
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
`;

export default connect(mapStateToProps)(MainPageMob);
