import React from 'react';
import { Card, CardHeader } from 'material-ui/Card';
import { FontIcon } from 'material-ui';
import PropTypes from "prop-types";


const styles = {
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '477px',
    marginTop: '10px',
    cursor: 'pointer',
  },
  titleStyle: {
    marginTop: '15px',
    marginLeft: "25px",
    color: '#000000',
    fontFamily: 'CircularStd Book',
    fontSize: '21px',
    letterSpacing: '0',
    lineHeight: '20px',
  },
  arrowIcon: {
    fontSize: '27px',
    float: 'right',
    paddingTop: '10px',
  }
};

const CardChannel = ({ image, name, background, hover }) => (
  <div style={styles.container}>
    <Card className={`cardSelector ${hover ? 'cardSelector--hover' : ''}`}>
      <CardHeader
        title={name}
        titleStyle={styles.titleStyle}
        style={{ height: "81px" }}
      >
        <img
          alt=""
          src={image}
          style={{ backgroundColor: background }}
          width="50px"
          className="image-card-channel"
        />
        <FontIcon
          className="material-icons"
          color="#D5D5D5"
          style={styles.arrowIcon}
        >
          keyboard_arrow_right
        </FontIcon>
      </CardHeader>
    </Card>
  </div>
);


CardChannel.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  background: PropTypes.string
};

export default CardChannel;
