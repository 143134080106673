import React from 'react';
import PropTypes from 'prop-types';
import assign from 'lodash/assign';
import keys from 'lodash/keys';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { selectUpcomingExperiencesCMS, selectPastExperiences, selectDraftExperiences } from 'selectors/experiences';

const styles = {
  container: {
    margin: '27px 0',
    textAlign: 'center',
  },
  centralized: {
    display: 'inline-block',
    margin: '0 auto',
  },
  filters: {
    borderRadius: '3px',
    backgroundColor: '#EEEEEE',
    display: 'flex',
  },
  filter: {
    border: '1px solid #E1E1E1',
    borderRight: 'none',
    color: '#A2A2A2',
    cursor: 'pointer',
    fontFamily: 'CircularStd Book',
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '18px',
    padding: '10px',
    width: '112px',
    textAlign: 'center',
  },
  selected: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
  },
  submit: {
    backgroundColor: '#9B63F8',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '0 3px 3px 0',
    padding: '11px 10px',
  },
  upcoming: {
    borderRadius: '3px 0 0 3px',
  },
  past: {
    borderRadius: '0 3px 3px 0',
    borderRight: '1px solid #E1E1E1'
  }
};

const filters = {
  upcoming: 'Upcoming',
  draft: 'Draft',
  past: 'Past',
};

class TGExperienceFilter extends React.Component { // eslint-disable-line react/prefer-stateless-function
  renderFilter(filterName, count) {
    let style = styles.filter;
    if (filterName === this.props.selectedFilter) {
      style = assign({}, style, styles.selected);
    }

    if (filterName === 'upcoming') {
      style = assign({}, style, styles.upcoming);
    }

    if (filterName === 'past') {
      style = assign({}, style, styles.past);
    }

    return (
      <div
        key={filterName}
        onClick={() => { this.props.onSelectFilter(filterName); }}
        style={style}
      >
        {count[filterName]} {filters[filterName]}
      </div>
    );
  }

  render() {
    const { upcomingExperiences, pastExperiences, draftExperiences } = this.props;
    const count = {
      upcoming: upcomingExperiences.length,
      past: pastExperiences.length,
      draft: draftExperiences.length
    };

    return (
      <div style={styles.container}>
        <div style={styles.centralized}>
          <div style={styles.filters}>
            {map(keys(filters), (filterName) => { return this.renderFilter(filterName, count); })}
          </div>
        </div>
      </div>
    );
  }
}

TGExperienceFilter.propTypes = {
  upcomingExperiences: PropTypes.array,
  pastExperiences: PropTypes.array,
  draftExperiences: PropTypes.array,
  onSelectFilter: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedFilter: PropTypes.string,
};

const mapStateToProps = (state) => ({
  upcomingExperiences: selectUpcomingExperiencesCMS(state, true),
  pastExperiences: selectPastExperiences(state, true),
  draftExperiences: selectDraftExperiences(state, true)
});

export default connect(mapStateToProps)(TGExperienceFilter);
