import localStorage from 'local-storage';
import forEach from 'lodash/forEach';
import { CALL_API } from 'redux_store/middleware/api';
import { saveImagesArtists } from './images';

const SAVE_ARTIST_REQUEST = 'SAVE_ARTIST_REQUEST';
const SAVE_ARTIST_SUCCESS = 'SAVE_ARTIST_SUCCESS';
const SAVE_ARTIST_FAILURE = 'SAVE_ARTIST_FAILURE';
const UPDATE_ARTIST_REQUEST = 'UPDATE_ARTIST_REQUEST';
const UPDATE_ARTIST_SUCCESS = 'UPDATE_ARTIST_SUCCESS';
const UPDATE_ARTIST_FAILURE = 'UPDATE_ARTIST_FAILURE';
const LOAD_ARTIST_REQUEST = 'LOAD_ARTIST_REQUEST';
const LOAD_ARTIST_SUCCESS = 'LOAD_ARTIST_SUCCESS';
const LOAD_ARTIST_FAILURE = 'LOAD_ARTIST_FAILURE';

const IMAGES_ARTISTS = 'tg_images_artists:';

function getArtistById(payload) {
  const method = 'get';
  const {id} = payload;
  const endpoint = `/artists/${id}`;
  return {
    [CALL_API]: {
      types: [
        LOAD_ARTIST_REQUEST,
        LOAD_ARTIST_SUCCESS,
        LOAD_ARTIST_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function postArtist(body) {
  const isUpdate = body.id;
  const method = isUpdate ? 'put' : 'post';
  const types = isUpdate ?
  [
    UPDATE_ARTIST_REQUEST,
    UPDATE_ARTIST_SUCCESS,
    UPDATE_ARTIST_FAILURE,
  ]
    :
  [
    SAVE_ARTIST_REQUEST,
    SAVE_ARTIST_SUCCESS,
    SAVE_ARTIST_FAILURE,
  ];
  const endpoint = isUpdate ? `/artists/${body.id}` : '/artists';

  return {
    [CALL_API]: {
      types,
      method,
      endpoint,
      body,
    },
  };
}

/**
 * @param {*} ids
 */
export function loadArtists(ids) {
  return (dispatch) => {
    const getActions = ids.map((id) => {
      return dispatch(getArtistById(id));
    });
    return Promise.all(getActions);
  };
}

/**
 * @param {*} id
 */
export function loadArtist(id) {
  return (dispatch) => {
    return dispatch(getArtistById(id));
  };
}

/**
 * @param {*} data
 */
export function saveArtistImage(data) {
  const artist = data;
  return (dispatch) => {
    const actions = [];

    artist.images = artist.images ? artist.images : [];

    const unSavedImages = artist.images.filter((t) => { return t && !t.createdBy && !t.delete && !localStorage(IMAGES_ARTISTS + t.hash); });
    const savedImages = artist.images.filter((t) => { return t && t.createdBy && !t.delete; });

    actions.push(dispatch(saveImagesArtists(unSavedImages ? unSavedImages.map((i) => { return { default: i.default ? 1 : 0, data: i.data ? i.data : '', name: i.name ? i.name : 'image.jpg', extension: i.name ? i.name.split('.')[1] : 'jpg', hash: i.hash }; }) : [])));
    return Promise.all(
      actions
    ).then((result) => {
      const [newImages] = result;

      const images = savedImages.concat(newImages);

      const body = {
        ...artist,
      };

      if (newImages && newImages.length > 0) {
        forEach(newImages, (item) => {
          localStorage.remove(IMAGES_ARTISTS + item.hash);
          localStorage(IMAGES_ARTISTS + item.hash, {
            name: item.name,
            id: item.id,
            hash: item.hash,
            expires: (new Date().getTime() + (3 * 1000)),
          });
        });
      }

      body.images = images ? images.map((t) => { return t.id; }) : [];

      if ((!body.images || body.images.length === 0) && artist.images && artist.images.length > 0) {
        body.images = artist.images.map((item) => {
          const img = localStorage(IMAGES_ARTISTS + item.hash);
          if (img && img.id && !item.delete) {
            return img.id;
          }
          return null;
        }).filter((t) => { return t !== null; });
      }

      return dispatch(postArtist(body));
    });
  };
}

/**
 * @param {*} data
 */
export function saveArtist(data) {
  return (dispatch) => {
    const body = {
      ...data,
    };
    body.images = [];
    return dispatch(postArtist(body))
       .then((res) => {
         return res;
       });
  };
}

/**
 *  @param {*} artists
 */
export function saveArtists(artists) {
  return (dispatch) => {
    const saveActions = artists.map((item) => {
      return dispatch(saveArtistImage(item));
    });
    return Promise.all(saveActions);
  };
}
