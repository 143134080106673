import React from 'react';
import { CircularProgress, Dialog } from 'material-ui';
import styled from 'styled-components';
import Button from 'components/Button';

const ConfirmDialog = ({
  isOpen,
  loading,
  onAccept,
  onClose,
}) => {
  return (
    <Dialog
      bodyClassName="ticketing-dialog-body"
      contentClassName="ticketing-dialog-content"
      open={isOpen}
      paperProps={{
        style: {
          borderRadius: 12,
          margin: '0 auto',
          maxWidth: 450,
          padding: 30,
        }
      }}
      titleStyle={{
        marginBottom: 30,
        padding: 0,
      }}
      onRequestClose={onClose}
      title={(
        <Title>Notice</Title>
      )}
    >
      <Description>
        Please ensure all creators in your channel have setup their Stripe account to receive payouts.
      </Description>
      <Description>
        If they haven't setup an account on Stripe, their payouts will be delegated to you.
      </Description>
      <StyledButton onClick={!loading && onAccept}>
        {
          loading ? (<CircularProgress />) : 'I understand'
        }
      </StyledButton>
    </Dialog>
  )
}

const Title = styled.div`
  && {
    color: #F85C62 !important;
    font-family: CircularStd Book;
    font-size: 28px !important;
    text-align: center;
  }
`;

const Description = styled.div`
 && {
   color: #4D4D4D;
   font-family: CircularStd Book;
   font-size: 22px;
   margin: 0 auto;
   margin-bottom: 32px;
   max-width: 90%;
   text-align: center;
 }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #A460FF;
    letter-spacing: 0;
    font-family: CircularStd Book;
    font-size: 18px;
    height: 55px;
    width: 100%;
  }
`;

export default ConfirmDialog;
