import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import Avatar from 'components/Avatar';
import { getResizedImageURL } from 'utils/helpers';
import { IMAGE_SIZES } from 'appconstants';

const UserInfo = ({ user }) => {
  const image = user.photo && getResizedImageURL(user.photo, IMAGE_SIZES.SMALL);

  const goToMyTickets = () => {
    browserHistory.push('/tickets');
  };

  return (
    <div className="header-mobile-avatar">
      {user.email && (
        <Avatar
          image={image}
          onClick={goToMyTickets}
          className="user-icon-mobile"
          email={user.email}
          size={30}
        />)}
    </div>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object
};

export default UserInfo;
