import moment from 'moment-timezone';
import find from 'lodash/find';
import filter from 'lodash/filter';
import localStorage from 'local-storage';
import forEach from 'lodash/forEach';
import clone from 'lodash/clone';
import { omitBy, isUndefined, isNull, isEmpty, isArray } from 'lodash';

import { CALL_API } from 'redux_store/middleware/api';
import {
  FETCH_EXPERIENCES,
  EXPERIENCES_TYPES,
  TICKET_TYPES,
  GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES,
  CREATE_NEW_EXPERIENCE,
} from 'appconstants';
import { saveImages } from './images';
import { saveTickets } from './tickets';
import { saveArtists, loadArtists } from './artists';
import { saveFlows } from './flows';
import { saveLocation } from './locations';
import { saveTags, loadTags } from './tags';
import { saveGuidelines, loadDefaultGuidelines } from './guidelines';

export function createNewExperience({ channel, type = EXPERIENCES_TYPES.VIRTUAL }) {
  const endpoint = '/experiences/new';
  const method = 'post';

  const action = {
    [CALL_API]: {
      endpoint,
      method,
      body: {
        channel,
        type,
      },
      types: [
        CREATE_NEW_EXPERIENCE.CREATE_NEW_EXPERIENCE_REQUEST,
        CREATE_NEW_EXPERIENCE.CREATE_NEW_EXPERIENCE_SUCCESS,
        CREATE_NEW_EXPERIENCE.CREATE_NEW_EXPERIENCE_FAILURE,
      ],
    },
  };

  return (dispatch) => dispatch(action);
}

export function loadingExperienceFinish(response) {
  const experience = response.response;
  if (experience) {
    experience.startTime = new Date(experience.startTime);
    experience.endTime = new Date(experience.endTime);
    experience.startDate = new Date(experience.startTime);
    experience.endDate = new Date(experience.endTime);
  }
  return {
    type: FETCH_EXPERIENCES.LOAD_EXPERIENCE_FINISH,
    experience,
  };
}

function loadingCmsExperienceFinish(experience) {
  return {
    type: FETCH_EXPERIENCES.LOAD_CMS_EXPERIENCE_FINISH,
    experience,
  };
}

function putPublishExperience(id) {
  const method = 'put';
  const endpoint = `/experiences/${id}/publish`;

  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.PUBLISH_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.PUBLISH_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.PUBLISH_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function putUnpublishExperience(id) {
  const method = 'put';
  const endpoint = `/experiences/${id}/unpublish`;

  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.UNPUBLISH_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.UNPUBLISH_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.UNPUBLISH_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function getGuestlist({ channel, alias, csv }) {
  const method = 'GET';
  const query = csv ? '?csv=true' : '';
  const contentType = csv ? { contentType: 'text/csv' } : {};
  const endpoint = `/experiences/${channel}/${alias}/guestlist${query}`;

  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.GUESTLIST_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.GUESTLIST_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.GUESTLIST_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
      ...contentType,
    },
  };
}

function getReliveGuestlist({ channel, alias, csv }) {
  const method = 'GET';
  const query = csv ? '?csv=true' : '';
  const contentType = csv ? { contentType: 'text/csv' } : {};
  const endpoint = `/experiences/${channel}/${alias}/relive-guestlist${query}`;

  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.RELIVE_GUESTLIST_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.RELIVE_GUESTLIST_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.RELIVE_GUESTLIST_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
      ...contentType,
    },
  };
}

function getExperience({ channel, alias, password = null, preview = false }) {
  const method = 'get';
  let endpoint = `/experiences/${channel}/${alias}?preview=${preview}`;

  if (password) {
    endpoint += `&password=${password}`;
  }

  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.LOAD_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.LOAD_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.LOAD_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function getCmsExperience(id) {
  const method = 'get';
  const endpoint = `/cms/experiences/${id}`;
  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.LOAD_CMS_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.LOAD_CMS_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.LOAD_CMS_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function postRsvpExperience({ alias, channel, tickets, lockedExperience, discount = null }) {
  const discountId = discount ? discount.id : null;

  const body = {
    tickets,
    discount: discountId,
  };

  if (lockedExperience) {
    body.lockedExperience = lockedExperience;
  }

  const endpoint = `/users/rsvp/${channel}/${alias}/`;
  const method = 'post';

  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.RSVP_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.RSVP_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.RSVP_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

function putExperienceChannel(body) {
  const { id, channel } = body;
  const method = 'put';
  const endpoint = `/experiences/${id}/channel`;
  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.SAVE_CHANNEL_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.SAVE_CHANNEL_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.SAVE_CHANNEL_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
      body: { channel },
    },
  };
}

function putExperience(body) {
  const { id } = body;
  delete body.startDate;
  delete body.endDate;
  delete body.channel;
  delete body.id;
  const method = 'put';
  const endpoint = `/experiences/${id}`;
  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

function postExperience(body, emptyExperience = false) {
  const endpoint = `/experiences?emptyExperience=${emptyExperience}`;
  const method = 'post';
  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_REQUEST,
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_SUCCESS,
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

export function fetch(channel, nextPage = '', reset, category = '', relive) {
  let endpoint = channel
    ? `/v2.0/channels/${channel}${
        nextPage && nextPage !== '' && !reset ? `?nextPage=${nextPage}` : ''
      }`
    : `/v2.0/experiences${nextPage && nextPage !== '' && !reset ? `?nextPage=${nextPage}` : ''}`;

  if (category) {
    endpoint += (reset ? '?' : '&') + `category=${category}`;
  }

  if (relive) {
    endpoint += (endpoint.indexOf('?') !== -1 ? '&' : '?') + 'relive=true';
  }

  const types = relive
    ? [
        FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_REQUEST,
        FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_SUCCESS,
        FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_FAILURE,
      ]
    : [
        FETCH_EXPERIENCES.FETCH_EXPERIENCES_REQUEST,
        FETCH_EXPERIENCES.FETCH_EXPERIENCES_SUCCESS,
        FETCH_EXPERIENCES.FETCH_EXPERIENCES_FAILURE,
      ];

  return {
    [CALL_API]: {
      types,
      method: 'get',
      endpoint,
    },
    reset,
    channel,
  };
}

function fetchCmsExperiences(channel) {
  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.FETCH_CMS_EXPERIENCES_REQUEST,
        FETCH_EXPERIENCES.FETCH_CMS_EXPERIENCES_SUCCESS,
        FETCH_EXPERIENCES.FETCH_CMS_EXPERIENCES_FAILURE,
      ],
      method: 'get',
      endpoint: `/cms/experiences${channel ? `/${channel}` : ''}`,
    },
  };
}

function fetchUsersExperiences() {
  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.FETCH_USER_EXPERIENCES_REQUEST,
        FETCH_EXPERIENCES.FETCH_USER_EXPERIENCES_SUCCESS,
        FETCH_EXPERIENCES.FETCH_USER_EXPERIENCES_FAILURE,
      ],
      method: 'get',
      endpoint: '/users/experiences',
    },
  };
}

function fetchAvailableSubcategories(id) {
  const endpoint = `/experiences/${id}/available-subcategories`;

  return {
    [CALL_API]: {
      types: [
        GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES.GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_REQUEST,
        GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES.GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_SUCCESS,
        GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES.GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_FAILURE,
      ],
      endpoint,
    },
  };
}

function putExperienceRelive(data) {
  const { id, reliveUrl, relivePlayer } = data;
  const body = { reliveUrl, relivePlayer };

  const method = 'put';
  const endpoint = `/experiences/${id}/relive`;

  return {
    [CALL_API]: {
      types: [
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_RELIVE_REQUEST,
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_RELIVE_SUCCESS,
        FETCH_EXPERIENCES.SAVE_EXPERIENCE_RELIVE_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

export function fetchSafetyAgreements() {
  return [
    {
      id: '58a452ec3309b896650f7c29',
      safety: 'Be able to leave the venue with ease incase of an emergency.',
    },
    { id: '58a453043309b896650f7c2a', safety: 'There are accessible fire extinguishers.' },
    { id: '58a4530f3309b896650f7c2b', safety: 'Smoke detectors is functional on location.' },
    {
      id: '58a4531b3309b896650f7c2c',
      safety: 'Carbon monoxide detectors are functional on location.',
    },
    { id: '58a4532c3309b896650f7c2d', safety: 'First aid kit is available on site.' },
    { id: '58a453353309b896650f7c2e', safety: 'Location is accessible to emergency response.' },
    { id: '58a4533f3309b896650f7c2f', safety: 'Location is generally safe and secure for guests.' },
  ];
}

export function publish(id) {
  return (dispatch) => {
    return dispatch(putPublishExperience(id));
  };
}

export function unpublish(id) {
  return (dispatch) => {
    return dispatch(putUnpublishExperience(id));
  };
}

export function downloadGuestlist({ channel, alias }) {
  return (dispatch) => {
    return dispatch(getGuestlist({ channel, alias, csv: true }));
  };
}

export function loadGuestlist({ channel, alias }) {
  return (dispatch) => {
    return dispatch(getGuestlist({ channel, alias }));
  };
}

export function downloadReliveGuestlist({ channel, alias }) {
  return (dispatch) => {
    return dispatch(getReliveGuestlist({ channel, alias, csv: true }));
  };
}

export function loadReliveGuestlist({ channel, alias }) {
  return (dispatch) => {
    return dispatch(getReliveGuestlist({ channel, alias }));
  };
}

export function rsvpExperience({
  channel,
  alias,
  tickets,
  lockedExperience = null,
  discount = null,
}) {
  return (dispatch) => {
    return dispatch(postRsvpExperience({ channel, alias, tickets, discount, lockedExperience }));
  };
}

export function loadExperience({ channel, alias }) {
  return (dispatch) => {
    return dispatch(getExperience({ channel, alias })).then((experience) => {
      experience.startTime = new Date(experience.startTime);
      experience.endTime = new Date(experience.endTime);
      experience.rsvp = (experience.userexperiences && experience.userexperiences.length) || false;
      experience.startDate = new Date(experience.startTime);
      experience.endDate = new Date(experience.endTime);

      if (experience.flow) {
        experience.flow = experience.flow.map((item) => {
          const f = item;
          return {
            id: f.id,
            activity: f.activity,
            duration: f.duration,
          };
        });
      }
      if (experience.safetyAgreements) {
        const safetyAgreements = fetchSafetyAgreements();
        experience.safetyAgreements = safetyAgreements.map((sa) => {
          const checked = find(experience.safetyAgreements, (e) => {
            return e === sa.id;
          });
          return {
            id: sa.id,
            safety: sa.safety,
            checked: !!checked,
          };
        });
      }
      dispatch(loadingExperienceFinish(experience));
      return experience;
    });
  };
}

export function loadExperienceProfile({ channel, alias, password }) {
  return (dispatch) => {
    return dispatch(getExperience({ channel, alias, password, preview: true })).then(
      (experienceResponse) => {
        const experience = experienceResponse;
        const withRsvp = find(experience.userexperiences, (e) => e.id === experience.id);
        experience.startTime = new Date(experience.startTime);
        experience.endTime = new Date(experience.endTime);
        experience.rsvp = withRsvp ? withRsvp.length : false;
        experience.startDate = new Date(experience.startTime);
        experience.endDate = new Date(experience.endTime);
        if (experience.safetyAgreements) {
          const safetyAgreements = fetchSafetyAgreements();
          experience.safetyAgreements = safetyAgreements.map((sa) => {
            const checked = find(experience.safetyAgreements, (e) => {
              return e === sa.id;
            });
            return {
              id: sa.id,
              safety: sa.safety,
              checked: !!checked,
            };
          });
        }
        experience.artists.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));

        dispatch(loadingExperienceFinish(experience));
        return experience;
      }
    );
  };
}

export function loadCmsExperience(id) {
  return (dispatch) => {
    return dispatch(getCmsExperience(id)).then((experienceResponse) => {
      const experience = clone(experienceResponse);
      const actions = [];
      actions.push(dispatch(loadArtists(experience.artists || [])));
      actions.push(dispatch(loadTags(experience.tags ? experience.tags : [])));
      return Promise.all(actions).then((result) => {
        const [artists, tags] = result;
        experience.name = experience.name === ' ' ? '' : experience.name;
        experience.description = experience.description === ' ' ? '' : experience.description;
        experience.artists = artists;
        experience.tags = tags;
        experience.startTime = new Date(experience.startTime);
        experience.endTime = new Date(experience.endTime);
        experience.startDate = new Date(experience.startTime);
        experience.endDate = new Date(experience.endTime);

        if (artists.length === 0) {
          experience.artists = [{}];
        }

        if (experience.flow) {
          experience.flow = experience.flow.map((item) => {
            const f = item;
            return {
              id: f.id,
              activity: f.activity,
              duration: f.duration,
            };
          });
        }
        if (experience.safetyAgreements) {
          const safetyAgreements = fetchSafetyAgreements();
          experience.safetyAgreements = safetyAgreements.map((sa) => {
            const checked = find(experience.safetyAgreements, (e) => {
              return e === sa.id;
            });
            return {
              id: sa.id,
              safety: sa.safety,
              checked: !!checked,
            };
          });
        }

        dispatch(loadingCmsExperienceFinish(experience));
        return experience;
      });
    });
  };
}

// export function createNewEmptyExperience(type = EXPERIENCES_TYPES.VIRTUAL) {
//   const data = {};
//   return (dispatch) => {
//     const actions = [];

//     actions.push(dispatch(loadDefaultGuidelines()));

//     return Promise.all(actions).then((guidelinesRes) => {
//       const [guidelines] = guidelinesRes;
//       const actions2 = [];

//       const guidelinesDefault = guidelines.response.filter((t) => {
//         return t.system === 1;
//       });
//       actions2.push(
//         dispatch(
//           saveGuidelines(
//             guidelinesDefault
//               ? guidelinesDefault.map((i) => {
//                   return { guideline: i.guideline };
//                 })
//               : []
//           )
//         )
//       );
//       return Promise.all(actions2).then((result) => {
//         const [guide] = result;
//         data.name = ' ';
//         data.description = ' ';
//         data.type = type;
//         data.startTime = data.startTime || moment().toDate();
//         data.endTime = data.endTime || moment().add(7, 'days').toDate();
//         data.guidelines = guide
//           ? guide.map((t) => {
//               return t.id;
//             })
//           : [];
//         data.tickets = [];
//         data.alias = data.name;
//         const safetyAgreements = fetchSafetyAgreements();
//         data.safetyAgreements = safetyAgreements.map((sa) => sa.id);
//         const body = {
//           ...data,
//         };

//         return dispatch(postExperience(body, true)).then((result2) => {
//           const keepId = result2.id;
//           return dispatch(loadCmsExperience(result2.id)).then((experience) => {
//             const result3 = clone(experience);
//             result3.id = keepId;

//             return result3;
//           });
//         });
//       });
//     });
//   };
// }

export function saveExperience({ data, stopPublishing, changedValues }) {
  const experience = data;
  return async (dispatch) => {
    delete experience.presentation;
    if (!stopPublishing) {
      delete experience.status;
    }

    if (changedValues.discounts) {
      const body = { id: data.id, discounts: changedValues.discounts };
      return dispatch(putExperience(body)).then((result) => {
        return dispatch(loadingExperienceFinish({ response: result }));
      });
    }

    const actions = [];
    experience.tags = experience.tags ? experience.tags : [];
    experience.images = experience.images ? experience.images : [];

    const unSavedTags = experience.tags.filter((t) => {
      return !t.createdBy;
    });
    const savedTags = experience.tags.filter((t) => {
      return t.createdBy;
    });
    let newImages, images;
    if (changedValues.images) {
      const { unSavedImages, savedImages } = changedValues.images;

      if (unSavedImages && unSavedImages.length) {
        newImages = await dispatch(
          saveImages(
            unSavedImages.map((i) => {
              return {
                default: i.default ? 1 : 0,
                data: i.data,
                name: i.name,
                extension: i.name.split('.')[1],
                hash: i.hash,
              };
            })
          )
        );
      }
      images = (newImages && savedImages.concat(newImages)) || savedImages;
      if (newImages && newImages.length > 0) {
        forEach(newImages, (item) => {
          localStorage.remove(`tg_images:${item.hash}`);
          localStorage(`tg_images:${item.hash}`, {
            name: item.name,
            id: item.id,
            hash: item.hash,
            expires: new Date().getTime() + 3 * 1000,
          });
        });
      }  
    }

    actions.push(
      dispatch(
        saveTags(
          unSavedTags
            ? unSavedTags.map((t) => {
                return { type: 'tag', name: t.name, description: t.name };
              })
            : []
        )
      )
    );
    actions.push(dispatch(saveArtists(changedValues.artists ? experience.artists : [])));
    actions.push(dispatch(saveFlows(changedValues.flow ? experience.flow : [])));
    actions.push(dispatch(saveTickets(changedValues.tickets ? experience.tickets : [])));
    actions.push(dispatch(saveGuidelines(changedValues.guidelines ? experience.guidelines : [])));
    actions.push(dispatch(saveLocation(changedValues.location && experience.location)));

    const result = await Promise.all(actions);

    const [newTags, artists, flow, tickets, guidelines, location] = result;
    const tags = (newTags && savedTags.concat(newTags)) || savedTags;
    

    const body = {
      ...changedValues,
      private: !!changedValues.private,
      password: changedValues.private ? changedValues.password : '',
    };

    const { startDate, startTime, endTime, endDate } = changedValues;
    if (startDate || startTime) {
      body.startTime = moment(startTime).set({
        year: startDate.getFullYear(),
        month: startDate.getMonth(),
        date: startDate.getDate()
      }).format()

    }
    if (endDate || endTime) {
      body.endTime = moment(endTime).set({
        year: endDate.getFullYear(),
        month: endDate.getMonth(),
        date: endDate.getDate()
      }).format()
    }
    body.location = location ? location.id : null;
    body.tags = tags
      ? tags.map((t) => {
          return t.id;
        })
      : [];
    body.artists = artists
      ? artists.map((t) => {
          return t.id;
        })
      : [];
    body.flow = flow
      ? flow.map((t) => {
          return t.id;
        })
      : [];
    body.guidelines = guidelines
      ? guidelines.map((t) => {
          return t.id;
        })
      : [];
    body.tickets = tickets
      ? tickets.map((t) => {
          return t.id;
        })
      : [];
    body.safetyAgreements = body.safetyAgreements
      ? filter(body.safetyAgreements, { checked: true }).map((i) => {
          return i.id;
        })
      : [];

    body.food = body.food
      ? body.food.map((t) => {
          return t.id;
        })
      : [];

    body.drinks = body.drinks
      ? body.drinks.map((t) => {
          return t.id;
        })
      : [];


    body.images = images
      ? images.map((t) => {
          return t.id;
        })
      : [];

    const cleanBody = omitBy(
      body,
      (key) => isUndefined(key) || isNull(key) || (isArray(key) && isEmpty(key))
    );
    if (cleanBody.subcategories && cleanBody.subcategories.length) {
      delete cleanBody.subcategory;
      if (typeof cleanBody.subcategories[0] !== 'string') {
        const flattenedSubcategories = cleanBody.subcategories.map((el) => el.id);
        cleanBody.subcategories = flattenedSubcategories;
      }
    }
    cleanBody.id = data.id || data.idOriginal;
    return dispatch(putExperience(cleanBody)).then((result2) => {
      return dispatch(loadingExperienceFinish({ response: result2 }));
    });
  };
}

export function saveChannelExperience({ channel, id, isFree }) {
  return (dispatch) => {
    return dispatch(putExperienceChannel({ channel, id })).then(async () => {
      const ticket = {
        description: isFree ? TICKET_TYPES.FREE_TICKET : TICKET_TYPES.PAID_TICKET,
        total: 30,
        price: isFree ? 0 : 0.99,
        visibility: true,
      };
      const ticketResponse = await dispatch(saveTickets([ticket]));
      const experienceResponse = await dispatch(
        putExperience({ id, tickets: ticketResponse.map((t) => t.id) })
      );
      experienceResponse.name = experienceResponse.name === ' ' ? '' : experienceResponse.name;
      experienceResponse.description =
        experienceResponse.description === ' ' ? '' : experienceResponse.description;
      experienceResponse.total = experienceResponse.total > 0 ? experienceResponse.total : null;

      return dispatch(loadingExperienceFinish({ response: experienceResponse }));
    });
  };
}

/**
 *
 */

function buildLoadExperienceEndpoint(channel, nextPage, reset = false, category, relive = false) {
  let endpoint = `/v2.0/${channel ? `channels/${channel}` : 'experiences'}${
    relive ? '?relive=true' : ''
  }`;

  if (!reset && !!nextPage) {
    endpoint += (endpoint.includes('?') ? '&' : '?') + `nextPage=${nextPage}`;
  }

  if (category) {
    endpoint += (endpoint.includes('?') ? '&' : '?') + `category=${category}`;
  }

  return endpoint;
}

export function loadExperiences(channel, nextPage = '', reset = false, category, relive) {
  const endpoint = buildLoadExperienceEndpoint(channel, nextPage, reset, category, false);

  const types = [
    FETCH_EXPERIENCES.FETCH_EXPERIENCES_REQUEST,
    FETCH_EXPERIENCES.FETCH_EXPERIENCES_SUCCESS,
    FETCH_EXPERIENCES.FETCH_EXPERIENCES_FAILURE,
  ];

  const action = {
    [CALL_API]: {
      types,
      method: 'get',
      endpoint,
    },
    reset,
    channel,
  };

  return (dispatch) => dispatch(action);
}

export function loadReliveExperiences(channel, nextPage = '', reset = false) {
  const endpoint = buildLoadExperienceEndpoint(channel, nextPage, reset, null, true);

  const types = [
    FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_REQUEST,
    FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_SUCCESS,
    FETCH_EXPERIENCES.FETCH_RELIVE_EXPERIENCES_FAILURE,
  ];

  const action = {
    [CALL_API]: {
      types,
      method: 'get',
      endpoint,
    },
    reset,
    channel,
  };

  return (dispatch) => dispatch(action);
}

export function loadFeaturedExperiences(channel) {
  const endpoint = `/v2.0/${channel ? `channels/${channel}` : 'experiences'}?featured=true`;

  const types = [
    FETCH_EXPERIENCES.FETCH_FEATURED_EXPERIENCES_REQUEST,
    FETCH_EXPERIENCES.FETCH_FEATURED_EXPERIENCES_SUCCESS,
    FETCH_EXPERIENCES.FETCH_FEATURED_EXPERIENCES_FAILURE,
  ];

  const action = {
    [CALL_API]: {
      types,
      method: 'get',
      endpoint,
    },
    reset: true,
    channel,
  };

  return (dispatch) => dispatch(action);
}

/**
 *
 */
export function loadCmsExperiences(channel) {
  return (dispatch) => {
    return dispatch(fetchCmsExperiences(channel));
  };
}

/**
 *
 */
export function loadExperiencesByUser() {
  return (dispatch) => {
    return dispatch(fetchUsersExperiences());
  };
}

export function saveExperienceRelive({ id, reliveUrl, relivePlayer }) {
  return (dispatch) => {
    return dispatch(putExperienceRelive({ id, reliveUrl, relivePlayer }));
  };
}

export function getExperienceAvailableSubcategories(id) {
  return (dispatch) => {
    return dispatch(fetchAvailableSubcategories(id));
  };
}
