import { SIGNOUT_SUCCESS, SIGNOUT_FAILURE } from 'redux_store/middleware/api';
import {
  USER_NOTIFICATIONS,
  USER_NEW_PASSWORD,
  USER_REQUEST,
  FETCH_PROFILE,
  SUBMIT_FORGOT_PASSWORD,
  SUBMIT_RESET_PASSWORD,
  SUBMIT_LOGIN,
  USERS_ACTIONS_TYPES,
} from 'appconstants';

const LOADING_USER_START = 'LOADING_USER_START';
const LOADING_USER_FINISH = 'LOADING_USER_FINISH';
const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

const initialState = {
  loading: false,
  email: '',
  user: {
    subscription: 'guest',
    id: 'guest',
    email: 'guest'
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUBMIT_LOGIN.SUBMIT_LOGIN_SUCCESS:
    case FETCH_USER_FAILURE:
    case SUBMIT_LOGIN.SUBMIT_LOGIN_FAILURE:
    case USER_NOTIFICATIONS.SUBMIT_NOTIFICATIONS_FAILURE:
    case USER_NEW_PASSWORD.SUBMIT_NEW_PASSWORD_FAILURE:
    case USER_REQUEST.SUBMIT_CREATEUSER_FAILURE:
    case FETCH_PROFILE.SAVE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_LOGIN.SUBMIT_LOGIN_REQUEST:
    case SIGNOUT_FAILURE:
    case LOADING_USER_START:
    case USER_NOTIFICATIONS.SUBMIT_NOTIFICATIONS_REQUEST:
    case USER_NEW_PASSWORD.SUBMIT_NEW_PASSWORD_REQUEST:
    case USER_REQUEST.SUBMIT_CREATEUSER_REQUEST:
    case FETCH_PROFILE.SAVE_PROFILE_REQUEST:
    case SUBMIT_FORGOT_PASSWORD.SUBMIT_FORGOT_PASSWORD_REQUEST:
    case SUBMIT_RESET_PASSWORD.SUBMIT_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_NOTIFICATIONS.SUBMIT_NOTIFICATIONS_SUCCESS:
    case FETCH_PROFILE.SAVE_PROFILE_SUCCESS:
    case USERS_ACTIONS_TYPES.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.response,
      };
    case USER_NEW_PASSWORD.SUBMIT_NEW_PASSWORD_SUCCESS:
    case SUBMIT_FORGOT_PASSWORD.SUBMIT_FORGOT_PASSWORD_SUCCESS:
    case SUBMIT_FORGOT_PASSWORD.SUBMIT_FORGOT_PASSWORD_FAILURE:
    case SUBMIT_RESET_PASSWORD.SUBMIT_RESET_PASSWORD_SUCCESS:
    case SUBMIT_RESET_PASSWORD.SUBMIT_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SIGNOUT_SUCCESS:
    case LOADING_USER_FINISH:
    case USER_REQUEST.SUBMIT_CREATEUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case USER_REQUEST.LOGOUT: return initialState;
    default:
      return state;
  }
}
