import React from 'react';
import styled from 'styled-components';
import { getResizedImageURL } from 'utils/helpers';
import { IMAGE_SIZES } from 'appconstants';
import { TIME_FORMATS } from 'appconstants/time2';
import { dateFormat } from 'utils/dates';
const CheckoutContent = ({ data }) => (
  <ContentContainer>
    <DateContainer>
      <DateMonth>{dateFormat(TIME_FORMATS.EVENT_MONTH, data.startTime).toUpperCase()}</DateMonth>
      <DateDay>{dateFormat(TIME_FORMATS.EVENT_DAY, data.startTime)}</DateDay>
    </DateContainer>
    <FestivalPicture alt="festival-picture" src={getResizedImageURL(data.pageDesign.heroPicture, IMAGE_SIZES.SMALL)} />
  </ContentContainer>
);

const ContentContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
`;

const DateContainer = styled.div`
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  border: 2px solid #9b63f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  align-self: flex-end;
`;

const DateMonth = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1.8px;
  line-height: 18px;
`;

const DateDay = styled.div`
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 36px;
`;

const FestivalPicture = styled.img`
  border-radius: 8px;
  height: 157px;
  object-fit: cover;
  object-position: center;
  width: 286px;
`;

export default CheckoutContent;
