import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import find from "lodash/find";
import bigNumber from "bignumber.js";
import MediaQuery from "react-responsive";
import { Snackbar, CircularProgress } from "material-ui";
import { StripeProvider } from "react-stripe-elements";

import * as actionsExperiences from "actions/experiences";
import * as actionsAuth from "actions/auth";
import * as actionsAccounts from "actions/accounts";
import * as actionsDiscounts from "actions/discounts";

import SelectFieldShowingError from "components/common/SelectFieldShowingError";
import Button from "components/Button";

import { SELECT_STYLES, BUTTON_STYLES } from "appconstants";
import routes from "appconstants/routes";

import { calculateDiscount } from "utils/helpers";

import CheckoutHeader from "./CheckoutHeader";
import ExperienceGuidelines from "./ExperienceGuidelines";
import ExperienceGuidelinesMob from "./ExperienceGuidelinesMob";
import SelectTicketMobile from "./SelectTicketMobile";
import MobileHeader from "./MobileHeader";
import PaymentMobile from "./PaymentMobile";
import StripeWrapper from "./StripeWrapper";
import "./styles.css";

import { selectUser } from "selectors/user";
import { fetchChannels, setCurrentChannel } from "actions/channels";

const styles = {
  modalSpinner: {
    display: "block",
    zIndex: "1001",
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    filter: "alpha(opacity=00)",
    background:
      "linear-gradient(24deg, rgba(125, 152, 241, 0.9) 13%, rgba(229, 120, 162, 0.9) 100%)",
  },
  snackbar: {
    backgroundColor: "rgba(255, 0, 13, 0.88)",
  },
};

class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    const { state } = props.location;

    this.state = {
      cart: [],
      subtotal: 0,
      total: 0,
      totalTickets: 0,
      discounts: [],
      discountApplied: null,
      upgradeModalOpened: false,
      guidelinesModalOpened: false,
      spinnerModalOpened: false,
      discountsLoading: false,
      discountsLoaded: false,
      purchaseOnMount: false,
      ...(state && state.redirectState),
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { experience } = nextProps;
    const { purchaseOnMount } = this.state;
    if (!purchaseOnMount) this.setDefaultTicket(experience);
  }

  componentDidUpdate() {
    const { experience, setCurrentChannel, fetchChannels, channels } = this.props;

    if (experience?.channel?.urlSlug) {
      if (!channels.length) {
        fetchChannels().then(() => {
          setCurrentChannel(experience.channel.urlSlug);
        });
      } else {
        setCurrentChannel(experience.channel.urlSlug);
      }
    }
  }

  onCloseMobile() {
    browserHistory.goBack();
  }

  onBackMobile = () => {
    const { experience } = this.props;

    this.setState(
      {
        paymentDetails: null,
        transactionSuccessful: false,
        cart: [],
      },
      () => this.setDefaultTicket(experience)
    );
  };

  setDefaultTicket = (experience) => {
    if (experience && experience.tickets && experience.tickets.length > 0) {
      if (experience.tickets[0].quantity > 0) {
        this.updateCart({
          id: experience.tickets[0].id,
          quantity: 1,
          cardPrice: experience.tickets[0].price,
          discount: experience.tickets[0].discount,
        }, true);
      }
    }
  };

  getTotalWithFee = (subtotal) => {
    // TODO: MOVE VALUES TO CONSTANTS
    return subtotal === 0 ? subtotal : bigNumber(subtotal).plus(0.3).div(0.971);
  };

  getFee = () => {
    const { total, subtotal } = this.state;
    return total && Number(bigNumber(total).minus(subtotal).toFixed(2));
  };

  getDiscountFromCode = (code) => {
    const discounts = this.state.discounts;
    let discount = discounts.find((d) => d.code === code);

    if (discount && discount.total && discount.used >= discount.total) {
      discount = null;
    }

    return discount;
  };

  loadData() {
    const { loadExperience } = this.props;
    const { channel, alias } = this.props.params;

    loadExperience({ channel, alias }).then((experience) => {
      this.setState(
        {
          discounts: experience.discounts,
          discountsLoading: false,
          discountsLoaded: true,
        },
        this.state.discountsLoading ? this.clickApplyDiscount : null
      );

      if (this.state.purchaseOnMount) {
        this.purchaseMemberTickets();
      }
    });
  }

  purchaseMemberTickets = () => {
    const { rsvpExperience, location } = this.props;
    const { alias, channel } = this.props.params;
    const { query } = location;

    this.setState({ spinnerModalOpened: true });
    rsvpExperience({
      alias,
      channel,
      tickets: this.state.cart,
      discount: this.state.discountApplied,
      lockedExperience: query,
    })
      .then(({ order }) => {
        if (order.total === 0) {
          this.presentGuidelines();
        }
        this.setState({ paymentDetails: order, spinnerModalOpened: false });
      })
      .catch((e) => {
        this.setState({ errorMessage: e.message, spinnerModalOpened: false });
      });
  };

  goToTickets = () => {
    browserHistory.push(routes.tickets);
  };

  presentGuidelines = () => {
    this.setState({ transactionSuccessful: true });
  };

  closeGuidelinesModal = () => {
    this.setState({ guidelinesModalOpened: false });
  };

  closePurchaseModal = () => {
    this.setState({ upgradeModalOpened: false });
  };

  goToSignUp = () => {
    const { cart, discountApplied } = this.state;
    browserHistory.push({
      pathname: routes.checkoutSignUp,
      state: {
        redirectPath: window.location.pathname,
        redirectState: { cart, discountApplied, purchaseOnMount: true },
      },
    });
  };

  checkout = () => {
    const { cart } = this.state;
    const { user } = this.props;

    if (cart.length === 0) {
      return this.setState({ errorMessage: "Please select a ticket type" });
    }
    if (user.email === "guest") {
      return this.goToSignUp();
    }

    return this.purchaseMemberTickets();
  };

  applyDiscount = (subtotal) => {
    const discount = this.state.discountApplied;
    if (!discount) {
      return subtotal;
    }

    let result;
    if (discount.isPercentage) {
      const discountMultiplier = bigNumber(1).minus(
        bigNumber(discount.amount).div(100)
      );
      result = bigNumber(subtotal).times(discountMultiplier);
    } else {
      result = subtotal.minus(discount.amount);
    }
    return result;
  };

  updateBalance() {
    let subtotal = bigNumber(0);
    let totalTickets = bigNumber(0);
    this.state.cart.forEach((cartItem) => {
      let { price, discount, quantity } = cartItem;
      if (discount) {
        const discountMultiplier = bigNumber(1).minus(
          bigNumber(discount).div(100)
        );
        price = bigNumber(price).times(discountMultiplier);
      }
      if (quantity > 0) {
        subtotal = subtotal.plus(bigNumber(quantity).times(price));
      }
      totalTickets = totalTickets.plus(quantity);
    });

    subtotal = this.applyDiscount(subtotal);
    subtotal = Number(bigNumber.max(0, subtotal).toFixed(2));
    const total = this.getTotalWithFee(subtotal);

    this.setState({
      total: Number(total.toFixed(2, bigNumber.ROUND_UP)),
      subtotal,
      totalTickets: Number(totalTickets.toFixed(0)),
    });
  }

  updateCart = ({ id, quantity, cardPrice, discount }, reset = false) => {
    const { cart } = this.state;
    const cartItem = find(cart, (item) => item.id === id);

    const price = cardPrice;

    if (cartItem) {
      cartItem.quantity = quantity;
      if (!quantity) {
        const newCart = cart.filter((item) => item.id !== id);
        this.setState({ cart: newCart });
      }
    } else if (reset) {
      this.setState({
        cart: [{ id, quantity, price, discount }],
      });
    } else {
      cart.push({ id, quantity, price, discount });
    }

    this.updateBalance();
  };

  closeErrorMessage = () => {
    this.setState({
      errorMessage: null,
    });
  };

  clickApplyDiscount = () => {
    if (this.state.discountsLoaded) {
      const discountApplied = this.getDiscountFromCode(this.code.value);
      const errorMessage = discountApplied ? null : "Invalid discount code";

      this.setState({ discountApplied, errorMessage }, this.updateBalance);
    } else {
      // Apply discount once loaded
      this.setState({ discountsLoading: true });
    }
  };

  renderSnackbar() {
    return (
      <Snackbar
        open={!!this.state.errorMessage}
        message={this.state.errorMessage || ""}
        autoHideDuration={4000}
        onRequestClose={this.closeErrorMessage}
        bodyStyle={styles.snackbar}
      />
    );
  }

  renderDropDownTickets(item, index) {
    const { experience } = this.props;

    const handleChange = (e) => {
      this.updateCart({
        id: item.id,
        quantity: Number(e.target.value),
        cardPrice: item.price,
        discount: item.discount,
      });
    };

    const experienceQuantity = experience.totalTickets - experience.sold;
    let maxQuantity = 2;

    if (item.price > 0) {
      maxQuantity = Math.min(
        item.quantity > 11 ? 11 : item.quantity,
        experienceQuantity
      );
    }

    const options = [...Array(maxQuantity).keys()];

    return (
      <SelectFieldShowingError
        className="ticket-dropdown"
        onChange={handleChange}
        defaultValue={index === 0 ? options[1] : options[0]}
        selectStyle={SELECT_STYLES.TRANSPARENT}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SelectFieldShowingError>
    );
  }

  renderNormalPrice({ price }) {
    return (
      <div className="ticket-item-common">
        {price ? <h4>${price}</h4> : <p className="ticket-select-free-label">FREE</p>}
      </div>
    );
  }

  renderDiscountPrice = (item) => (
    <div className="ticket-price ticket-item-common">
      <small className="original-price">${item.price}</small>
      <h4 className="ticket-item-price">
        ${calculateDiscount(item.price, item.discount)}
      </h4>
    </div>
  );

  renderSpinnerModal() {
    return (
      <div style={styles.modalSpinner}>
        <div
          style={{
            textAlign: "center",
            width: "100%",
            position: "relative",
            top: "30%",
          }}
        >
          <CircularProgress size={250} thickness={12} color={"#fff"} />
        </div>
      </div>
    );
  }

  renderDiscount = () => {
    const discount = this.state.discountApplied;
    if (discount == null) return null;

    const amount = discount.amount;
    let value;
    if (discount.isPercentage) {
      value = `${amount}%`;
    } else {
      value = `$${amount}`;
    }

    return <span className="discount-label">Discount applied: {value}</span>;
  };

  renderApplyDiscount = () => {
    return (
      <div className="discount-code-container">
        <input
          ref={input => (this.code = input)}
          className="input-discount-code"
          type="text"
          placeholder="Enter discount code"
        />
        {this.state.discountsLoading ? (
          <button className="apply-discount-btn">
            <div style={{ marginTop: "4px" }}>
              <CircularProgress size={19} thickness={3} />
            </div>
          </button>
          ) : (
            <button
              onClick={this.clickApplyDiscount}
              className="apply-discount-btn"
            >
            Apply
          </button>
        )}
      </div>
    );
  }

  renderDesktop() {
    const { experience } = this.props;

    return (
      <div className="page-container page-container-flex clearfix ticket-info-details ticket-select-container">
        <div className="container-inner ticket-select-wrapper">
          <CheckoutHeader experience={experience} />
          <div className="ticket-select-list-wrapper clearfix">
            <div className="ticket-list">
              <div className="ticket-list-titles">
                <div className="ticket-item-common ticket-select-description-title">
                  <span>desc</span>
                </div>
                <div className="ticket-item-common ticket-select-qty-title">
                  <span>qty</span>
                </div>
                <div className="ticket-item-common ticket-select-price-title">
                  <span>price</span>
                </div>
              </div>
              {experience.tickets &&
                experience.tickets.map((item, index) => {
                  if (!item.visibility) return null;

                  return (
                    <div key={item.id} className="ticket-item clearfix">
                      <div className="ticket-item-name ticket-item-common">
                        <h4>{item.description}</h4>
                        <h5>{item.longDescription}</h5>
                      </div>
                      <div className="num-of-ticket ticket-item-common">
                        {this.renderDropDownTickets(item, index)}
                      </div>
                      <div className="ticket-price ticket-item-common">
                        {item.price && item.discount && item.discount !== 0
                          ? this.renderDiscountPrice(item)
                          : this.renderNormalPrice(item)}
                      </div>
                    </div>
                  );
                })}
            </div>
            {this.renderApplyDiscount()}
            {this.renderDiscount()}
            <div className="total-wrapper clearfix">
              <div className="total-price-wrap">
                <span className="sub-total">Sub total</span>
                <div className="total-price">
                  ${this.state.subtotal.toFixed(2)}{" "}
                  {(this.state.subtotal && (
                    <span className="fee">+ $ {this.getFee()}</span>
                  )) ||
                    ""}
                </div>
              </div>
              <Button
                className="btn-checkout"
                onClick={() => this.checkout(experience.id)}
                buttonStyle={BUTTON_STYLES.GREEN}
              >
                CONTINUE »
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobileHeader = () => (
    <MobileHeader
      experience={this.props.experience}
      onClose={this.onCloseMobile}
      onBack={this.state.paymentDetails && this.onBackMobile}
    />
  );

  render() {
    const { experience, loading } = this.props;
    const {
      paymentDetails,
      transactionSuccessful,
      totalTickets,
      subtotal,
    } = this.state;

    return (
      <div className="checkout-page-container">
        <MediaQuery query="(min-device-width: 1224px)">
          {
            loading ? (
              <CircularProgress />
            ) : (
              <div>
                {!paymentDetails && experience && this.renderDesktop()}
                {paymentDetails && transactionSuccessful && (
                  <div style={{ "padding-top": "20px" }}>
                    <ExperienceGuidelines
                      experience={experience}
                      goToTickets={this.goToTickets}
                    />
                  </div>
                )}
                {paymentDetails && !transactionSuccessful && (
                  <div style={{ "padding-top": "20px" }}>
                    <StripeProvider apiKey={process.env.REACT_APP_STRIPEKEY}>
                      <StripeWrapper
                        clientSecret={paymentDetails.clientSecret}
                        showGuidelines={this.presentGuidelines}
                        total={paymentDetails.total}
                      />
                    </StripeProvider>
                  </div>
                )}
              </div>
            )
          }
        </MediaQuery>
        <MediaQuery
          query="(max-device-width: 1224px)"
          style={{
            display: "flex",
            "flex-direction": "column",
          }}
        >
          <div className="ticket-page-wrapper">
            {!paymentDetails && experience && (
              <SelectTicketMobile
                header={this.renderMobileHeader()}
                discountCode={this.renderApplyDiscount()}
                appliedDiscount={this.renderDiscount()}
                experience={experience}
                totalTickets={totalTickets}
                subTotal={subtotal}
                fee={this.getFee()}
                closeRoute={browserHistory.goBack}
                onSubmit={this.checkout}
                onChange={this.updateCart}
              />
            )}
            {paymentDetails && transactionSuccessful && (
              <ExperienceGuidelinesMob
                header={this.renderMobileHeader()}
                experience={experience}
                goToTickets={this.goToTickets}
              />
            )}
            {paymentDetails && !transactionSuccessful && (
              <PaymentMobile
                header={this.renderMobileHeader()}
                paymentDetails={paymentDetails}
                showGuidelines={this.presentGuidelines}
              />
            )}
          </div>
        </MediaQuery>
        {this.state.spinnerModalOpened && this.renderSpinnerModal()}
        {this.renderSnackbar()}
      </div>
    );
  }
}

CheckoutPage.propTypes = {
  params: PropTypes.object,
  experience: PropTypes.object,
  rsvpExperience: PropTypes.func.isRequired,
  loadExperience: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    experience: state.experiences.experience,
    user: selectUser(state),
    channels: state.channels.channels,
    loading: state.experiences.loading
  }),
  {
    ...actionsExperiences,
    ...actionsAuth,
    ...actionsAccounts,
    ...actionsDiscounts,
    setCurrentChannel,
    fetchChannels,
  }
)(CheckoutPage);
