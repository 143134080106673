import React, { useMemo } from 'react';
import { IMAGE_SIZES } from 'appconstants';
import { getResizedImageURL } from 'utils/helpers';
import { Chart } from 'react-google-charts'; // @NOTE: Error loading: https://github.com/rakannimer/react-google-charts/issues/195

import ImageNotFound from 'images/no-image-box.png';
import { withRouter } from 'react-router';
import { dateFormat } from 'utils/dates';

const chartOptions = {
  enableInteractivity: false,
  legend: { position: 'none' },
  pieSliceText: 'none',
  pieSliceBorderColor: 'transparent',
  colors: ['#69D27C', '#F3F3F3'],
};

const FestivalHeader = ({
  alias,
  channel,
  endTime,
  heroPicture,
  name,
  netSales,
  router,
  section,
  setSection,
  sold,
  startTime,
  tickets,
}) => {
  const totalTickets = useMemo(() => tickets.reduce((prev, curr) => curr.total + prev, 0), [tickets]);
  const attendingPercentage = useMemo(() => (sold / totalTickets) * 100, [sold, totalTickets]);
  const notAttendingPercentage = useMemo(() => 100 - attendingPercentage, [attendingPercentage]);
  const img = useMemo(
    () => (heroPicture ? getResizedImageURL(heroPicture, IMAGE_SIZES.THUMBNAIL) : ImageNotFound),
    [heroPicture]
  );
  const { from, to } = useMemo(() => ({
    from: dateFormat(
      {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
      },
      startTime
    ),
    to: dateFormat(
      {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
      },
      endTime
    ),
  }), [endTime, startTime]);
  // NO DATA UPDATE HERE
  const sections = useMemo(
    () => [
      {
        label: 'Tickets',
        value: 'guestlist',
      },
      {
        label: 'Discounts',
        value: 'discounts',
      },
      {
        label: 'Experiences',
        value: 'experiences',
      },
      {
        label: 'Edit',
        value: 'edit',
      },
    ],
    []
  );

  return (
    <div>
      <div className="experience-info">
        <div className="experience-info-inner">
          <div className="experience-img">
            <img width="88px" height="58px" alt="experience" src={img} />
          </div>
          <div className="experience-name-wrapper">
            <div className="experience-name">{name}</div>
            <div className="experience-date">
              {from} to {to}
            </div>
          </div>
          <div className="float-right">
            <div className="experience-guests-wrapper">
              <div className="pie-chart">
                <Chart
                  chartType="PieChart"
                  data={[
                    ['Guest', 'Percentage'],
                    ['attending', attendingPercentage],
                    ['not-attending', notAttendingPercentage],
                  ]}
                  options={chartOptions}
                  width="50px"
                  height="50px"
                />
              </div>
              <div className="info">
                <div className="info-values">
                  <span className="attending-value">{sold}</span>
                  <span className="total-value">/{totalTickets}</span>
                </div>
                <div className="info-label">Guests attending</div>
              </div>
            </div>
            <div className="info net-sales">
              <div className="info-values">${netSales.toFixed(2)}</div>
              <div className="info-label">Net Sales</div>
            </div>
            <div className="info">
              <div className="info-values">${netSales.toFixed(2)}</div>
              <div className="info-label">Current Payout</div>
            </div>
          </div>
        </div>
      </div>
      <div className="experience-menu">
        <div className="experience-menu-inner">
          <div className="menu-items">
            {sections.map(({ label, value }) => (
              <button
                className={`menu-item ${section === value ? 'menu-item-selected' : ''}`}
                onClick={() => setSection(value)}
              >
                {label}
              </button>
            ))}
            <a
              className="menu-item menu-item--float-right"
              onClick={() => router.push(`/in/${channel}/f/${alias}`)}
            >
              View Festival
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FestivalHeader);
