import React, { useState, useCallback } from 'react';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';

import SettingsIcon from 'components/common/SettingsIcon';

import { labelStyle } from './styles';
import TicketDialog from './Dialog';
import { Input } from '@material-ui/core';

const TicketItem = ({
  onDelete,
  options,
  ticketIndex,
  isTicket = false,
  ticketInfo,
  tickets,
  updateTicket,
}) => {
  const isOptionAvailable = useCallback((days) => tickets.findIndex(({ days: ticketDays, deleted }) => !deleted && days === ticketDays) !== -1, [tickets])
  const [dialogOpen, setDialogOpen] = useState(false);
  const updateTicketInfo = useCallback((data) => {
    updateTicket(ticketIndex, data);
  }, [updateTicket, ticketIndex]);

  const validatePrice = useCallback(
    ({ target: { value }}) => {
      if (!isNaN(value)) {
        updateTicketInfo({ price: value });
      }
    },
    [updateTicketInfo],
  );

  return (
    <TicketWrapper>
      {
        isTicket ? (
          <StyledInput
            type="text"
            onChange={({ target: { value }}) => updateTicketInfo({ name: value, description: value })}
            value={ticketInfo.name}
          />
        ) : (
          <Select
            classes={{
              root: 'select',
              icon: 'select__icon'
            }}
            disableUnderline
            displayEmpty
            fullWidth
            label="invite"
            labelStyle={labelStyle}
            onChange={({ target: { value }}) => updateTicketInfo(JSON.parse(value))}
            renderValue={() => {
              return ticketInfo.description;
            }}
            MenuProps={{
              classes: {
                paper: 'select__paper'
              }
            }}
            value={JSON.stringify({ days: ticketInfo.days, description: ticketInfo.description })}
          >
            {
              options.map(({ days, description }) => (
                <MenuItem
                  value={JSON.stringify({ days, description })}
                  key={days}
                  disabled={isOptionAvailable(days)}
                >
                  <ListItemText primary={description} />
                </MenuItem>
              ))
            }
          </Select>
        )
      }
      <Quantity
        type="number"
        min="0"
        defaultValue="0"
        onChange={({ target: { value }}) => updateTicketInfo({ total: value })}
        value={ticketInfo.total}
      />
      {
        (!ticketInfo.free && (!ticketInfo._id || parseFloat(ticketInfo.price))) ? (
          <Cost>
            <span>$</span>
            <input
              onChange={validatePrice}
              type="text"
              value={ticketInfo.price}
              defaultValue="0.99"
              min={0}
            />
          </Cost>
        ) : (
          <FreeTicket>Free</FreeTicket>
        )
      }
      <Button onClick={() => setDialogOpen(true)}>
        <SettingsIcon />
      </Button>
      <TicketDialog
        longDescription={ticketInfo.longDescription}
        visibility={ticketInfo.visibility}
        open={dialogOpen}
        name={ticketInfo.name}
        updateTicket={updateTicketInfo}
        onClose={() => setDialogOpen(false)}
        onDelete={onDelete}
      />
    </TicketWrapper>
  );
}

const StyledInput = styled(Input)`
  box-sizing: border-box;
  width: 359px;


  & input {
    height: 69px;
  }

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }
`;

const Button = styled.button`
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  display: flex;
  width: 30px;
  align-items: center;
  margin-bottom: 14px;
  padding: 0;
  margin-left: 10px;
`;

const Cost = styled.div`
  width: 130px;
  flex: 0 0 130px;
  position: relative;

  & span {
    position: absolute;
    left: 16px;
    top: 18px;
    color: #D5D5D5;
    font-size: 20px;
    font-family: 'CircularStd Book';
  }

  & input {
    padding-left: 30px !important;
  }
`;

const FreeTicket = styled.div`
  align-items: center;
  border-radius: 8px;
  border: 2px solid #D5D5D5;
  box-sizing: border-box;
  color: #C0C0C0;
  display: flex;
  flex: 0 0 130px;
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: bold;
  height: 69px;
  justify-content: center;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
`;

const Quantity = styled.input`
  flex: 0 0 92px;
  padding: 20px !important;
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const Select = styled(SelectField)`
  flex: 0 0 359px;
`;

const TicketWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 644px;
`;

export default TicketItem;
