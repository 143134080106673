import StripeWrapper from 'containers/CheckoutPage/StripeWrapper';
import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import styled from 'styled-components';

const Payment = ({ payment, nextStep }) => (
  <Container>
    <StripeProvider apiKey={process.env.REACT_APP_STRIPEKEY}>
      <StripeWrapper
        clientSecret={payment.clientSecret}
        showGuidelines={() => nextStep()}
        total={payment.total}
      />
    </StripeProvider>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  flex: 1;
  & > div {
      width: 100%;
  }
`;

export default Payment;
