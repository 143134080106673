import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorIcon from '@material-ui/icons/MoreHoriz';

const KebabMenu = ({ options, icon: Icon = null }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {
          Icon ? <Icon /> : <MoreHorIcon />
        }
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {options.map(({ label, onClick }) => (
          <MenuItem key={label} onClick={onClick}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default KebabMenu;
