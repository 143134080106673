import { OWNER_SETTINGS_TABS, SIGNUP_TABS } from "appconstants";

const routes = {
  createExperience: "/create-experience",
  createFestival: "/create-festival",
  dashboard: (channel = '') => `/${channel}/dashboard`,
  experiencesManager: "/experiences/:id",
  experiences: '/experiences',
  mainPage: "/",
  onBoarding: "/welcome",
  signIn: `/${SIGNUP_TABS.signin}`,
  signUp: `/${SIGNUP_TABS.signup}`,
  selectChannel: `/select-channel/:id`,
  relive: "/in/:channel/:alias/relive",
  tickets: "/tickets",
  festivalsManager: "/festivals/:id(/:step)",
  campsManager: "/camps/:id(/:step)",
  checkoutSignIn: `/checkout/${SIGNUP_TABS.signin}`,
  checkoutSignUp: `/checkout/${SIGNUP_TABS.signup}`,
  accountPage: '/account/settings',
  channelPage: (channel = '') => `/${channel}/settings`,
  paymentPage: '/account/payouts',
  notificationsPage: '/account/notifications',
  passwordPage: '/account/password',
  splitPage: (channel = '') => `/${channel}/rev-share`,
  teamPage: (channel = '') => `/${channel}/team`,
};

export const SETTINGS_TABS_MAP = (currentChannel) => ({
  [routes.accountPage]: OWNER_SETTINGS_TABS.ACCOUNT,
  [routes.channelPage(currentChannel)]: OWNER_SETTINGS_TABS.CHANNELS,
  [routes.paymentPage]: OWNER_SETTINGS_TABS.PAYMENT,
  [routes.notificationsPage]: OWNER_SETTINGS_TABS.NOTIFICATIONS,
  [routes.passwordPage]: OWNER_SETTINGS_TABS.PASSWORD,
  [routes.splitPage(currentChannel)]: OWNER_SETTINGS_TABS.SPLIT,
  [routes.teamPage(currentChannel)]: OWNER_SETTINGS_TABS.TEAM,
});

export default routes;
