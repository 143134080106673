/*======================================
=            Vendor Imports            =
======================================*/
import React      from 'react';
import PropTypes  from 'prop-types';
/*=====  End of Vendor Imports  ======*/

/*===================================
=            App Imports            =
===================================*/
import styles from './styles.css';
/*=====  End of App Imports  ======*/

/*===============================
=            Helpers            =
===============================*/
/**
 * Get's empty props (modifers)
 * @param  {object}   props
 * @return {array}    Array of strings
 */
let getEmptyProps = (props) => {
  let arr = [];
  for(let key in props){
    if(props[key] === true){
      arr.push(key);
    }
  }
  return arr;
};

let generateStyles = (props) => {
  let arr = getEmptyProps(props);
  arr = arr.map(item => styles[item]).join(' ');
  return arr;
};
/*=====  End of Helpers  ======*/

/*=============================
=            Setup            =
=============================*/
/*=====  End of Setup  ======*/

/*=================================
=            Component            =
=================================*/
export default function Text(props){
  let css = generateStyles(props);
  return <span class={css}>{props.children}</span>;
}
/*=====  End of Component  ======*/

/*==================================
=            Prop Types            =
==================================*/
Text.propTypes = {
  children: PropTypes.node,
};
/*=====  End of Prop Types  ======*/
