import React, { Component } from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from "redux-form";
import { RaisedButton, Divider } from "material-ui";

import { submitResetPassword } from "actions/auth";
import { selectUserLoading, selectUser } from "selectors/user";

import AppWrapper from "components/AppWrapper";
import TGSignupHeader from "components/SignupHeader";
import Snackbar from "components/common/Snackbar";
import FormFieldShowingError from "components/common/FormFieldShowingError";
import { SNACKBAR_TYPES } from 'appconstants';

import { createValidator, required, match, stringMin } from "utils/validation";

import "./styles.css";

const styles = {
  submitButton: {
    style: {
      height: "54px",
      borderRadius: "8px",
    },
    label: {
      paddingTop: "10px",
      color: "#FFFFFF",
      fontFamily: "CircularStd Book",
      fontSize: "13.5px",
      fontWeight: "bold",
      letterSpacing: "1.6px",
      lineHeight: "58px",
      textAlign: "center",
    },
  },
};


const validate = createValidator({
  newPassword: [required("new password"), stringMin({ fieldName: "new password", min: 6 })],
  confirmNewPassword: [required("confirm new password"), match("newPassword")],
});

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null
    };
  }

  goToSignIn = () => {
    browserHistory.push(`/signin`);
  };

  handleChangePassword = async (data) => {
    try {
      const { params: { token } } = this.props;
      await this.props.submitResetPassword({ password: data.newPassword }, token);
      this.setState({
        snackbarType: SNACKBAR_TYPES.SUCCESS,
        success: "The password was changed successfully!"
      });
    } catch(err) {
      this.setState({
        snackbarType: SNACKBAR_TYPES.ERROR,
        error: "It’s not you, it’s us. We will look into it."
      });
    }
  };

  closeSnackBar = () => {
    if (this.state.success) {
      browserHistory.push('/signin');
    }
    this.setState({
      success: null,
      error: null
    });
  };

  render() {
    const {
      user,
      loading,
      pristine,
      submitting,
      handleSubmit,
      error,
    } = this.props;
    const { error: errorState, success } = this.state;

    return (
      <AppWrapper>
        <div className="page-signup">
          <div className="body-container">
            <TGSignupHeader
              className="signup-header"
              user={user}
              loading={loading}
              goToSignIn={this.goToSignIn}
            />
            <div className="form-container">
              <div className="reset-password-form-wrapper">
                <div className="reset-password-form">
                  <div className="form-head-container">
                    <div className="form-header">
                      <h5>Create new password</h5>
                    </div>
                  </div>
                  <Divider style={{ marginTop: "4rem" }} />
                  <form onSubmit={handleSubmit(this.handleChangePassword)}>
                    <div className="containerInput container-sign-up">
                      <div className="labelContainer">
                        <div className="label-sign-up">New password</div>
                      </div>
                      <Field
                        name="newPassword"
                        component={FormFieldShowingError}
                        type="password"
                        placeholder=""
                        className="simple-input"
                      />
                    </div>
                    <div className="containerInput container-sign-up">
                      <div className="labelContainer">
                        <div className="label-sign-up">Confirm new password</div>
                      </div>
                      <Field
                        name="confirmNewPassword"
                        component={FormFieldShowingError}
                        type="password"
                        placeholder=""
                        className="simple-input"
                      />
                    </div>
                    <RaisedButton
                      className="signup-button"
                      type="submit"
                      label="Submit"
                      style={styles.submitButton.style}
                      labelStyle={styles.submitButton.label}
                      disabled={loading || pristine || submitting}
                    />
                  </form>
                </div>
                <Snackbar
                  open={!!(error || errorState || success)}
                  title={success ? "Success!" : "Something went wrong"}
                  type={success ? SNACKBAR_TYPES.SUCCESS : SNACKBAR_TYPES.ERROR}
                  message={error || errorState || success}
                  autoHideDuration={4000}
                  onRequestClose={this.closeSnackBar}
                />
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    );
  }
}

ResetPasswordPage.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitResetPassword: PropTypes.func,
  error: PropTypes.bool,
};


export default connect(
  (state) => ({
    user: selectUser(state),
    loading: selectUserLoading(state),
  }),
  (dispatch) => bindActionCreators({ submitResetPassword }, dispatch)
  )(
    reduxForm({
      form: "ResetPasswordForm",
      validate,
    })(ResetPasswordPage)
  );
