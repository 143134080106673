import React from "react";
import cn from "classnames";

import "./styles.css";
import { array, string } from "prop-types";

const Breadcrumbs = ({ steps, className, selected }) => (
  <div className={cn("breadcrumb-container", className)}>
    {steps.map(({ id, label, onClick }, index) => {
      const isLast = index === steps.length - 1;
      const isSelected = selected === id;

      return (
        <div key={id} className="breadcrumb-step-container">
          <div className="breadcrumb-step-wrapper">
            <button
              className={cn(
                {
                  "breadcrumb-button-background-selected": isSelected,
                  "breadcrumb-button-background": !isSelected,
                },
                "breadcrumb-button"
              )}
              onClick={onClick}
            >
              {index + 1}
            </button>
            <h4
              className={cn(
                {
                  "breadcrumb-label-color-selected": isSelected,
                  "breadcrumb-label-color": !isSelected,
                },
                "breadcrumb-label"
              )}
            >
              {label}
            </h4>
          </div>
          {!isLast && (
            <div className="breadcrumb-divisor-wrapper">
              <div className="breadcrumb-divisor" />
            </div>
          )}
        </div>
      );
    })}
  </div>
);

Breadcrumbs.propTypes = {
  steps: array.isRequired,
  className: string,
  selected: string.isRequired,
};

export default Breadcrumbs;
