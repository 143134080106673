import React from 'react';
import { CircularProgress } from 'material-ui';
import styled from 'styled-components';
import { IMAGE_SIZES } from 'appconstants';
import ListItemMobile from 'components/ListItemMobile'
import { getResizedImageURL } from 'utils/helpers';
import { getDateRange } from 'utils/dates';

import { withRouter } from 'react-router';

const FestivalLandingMobile = ({
  festival,
  topbar,
  content,
  experiences,
  router,
  view,
  backgroundColor
}) => {
  if (!festival) {
    return <CircularProgress />
  }

  const noExperiences = experiences.length === 0;
  const isRegistered = festival.rsvp;

  return (
    <Container
      backgroundColor={backgroundColor}
      noExperiences={noExperiences}
    >
      <Header
        background={getResizedImageURL(festival.pageDesign?.heroPicture, IMAGE_SIZES.LARGE)}
        noExperiences={noExperiences}
        descriptionColor={festival.pageDesign?.descriptionColor}
      >
        {
          noExperiences && (
            <>
              <FestivalDate>
                {getDateRange(festival.startTime, festival.endTime)}
              </FestivalDate>
              <FestivalDescription>
                {festival.description}
              </FestivalDescription>
            </>
          )
        }

      </Header>
      {
        experiences.length > 0 && (
          <>
            <FestivalDate>
                {getDateRange(festival.startTime, festival.endTime)}
            </FestivalDate>
            <FestivalDescription>
              {festival.description}
            </FestivalDescription>
            {topbar}
            <ScheduleContainer view={view}>
              {
                experiences.map((experience, index) =>
                  <ListItemMobile
                    experience={experience}
                    isLastItem={index === experiences.length - 1}
                    lastStartTime={index === 0 ? '' : experiences[index-1].startTime}
                    view={view}
                  />
                )
              }
            </ScheduleContainer>
          </>
        )
      }
      <Footer>
        <Button onClick={
          () => router.push(
            isRegistered
            ? `/tickets`
            : `/in/${festival.channel.urlSlug}/f/${festival.alias}/checkout`
          )}
          registered={isRegistered}
        >
            {
              isRegistered ? 'My schedule' : 'Register to festival'
            }
        </Button>
      </Footer>
    </Container>
  )
}

const FestivalDate = styled.div`
  width: calc(100% - 49px);
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin: 15px auto;
`;

const FestivalDescription = styled(FestivalDate)`
  margin: 20px auto;
`;

const Footer = styled.div`
  height: 102px;
  background-color: #FFFFFF;
  box-shadow: 0 -7px 26px 0 rgba(0,0,0,0.09)
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  box-sizing: border-box;
  z-index: 15;
`;

const Button = styled.button`
  background-color: #9B63F8;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: lighter;
  height: 62px;
  line-height: 25px;
  outline: none;
  text-align: center;
  width: 100%;
  z-index: 15;
  ${({ registered }) => !registered && 'letter-spacing: 1.24px;'}

  &:disabled {
    opacity: .5;
  }
`;

const Container = styled.div`
  margin-top: 69px;
  overflow-y: auto;
  min-height: calc(100vh - 69px);
  background-color: whitesmoke;
  padding-bottom: ${({ noExperiences }) => noExperiences ? 0 : 122}px;
`;

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 95vw;
  margin: 35px auto 0;
`;

const Header = styled.div`
  align-items: center;
  background: url(${({ background }) => background}) center no-repeat;
  background-size: cover;
  color: ${({ descriptionColor }) => descriptionColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  ${({ noExperiences }) => noExperiences ? `
    height: calc(100vh - 171px);
  ` : `
    height: 200px;
    max-height: 400px;
  `}
`;

export default withRouter(FestivalLandingMobile);
