import { GET_PAYOUTS_INFO_TYPES }  from 'appconstants';

const initialState = {
  loading: false,
  error: false,
  payouts: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PAYOUTS_INFO_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_PAYOUTS_INFO_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        payouts: action.response,
      };
    case GET_PAYOUTS_INFO_TYPES.FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        payouts: [],
      };
    default:
      return state;
  }
}
