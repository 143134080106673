import isEmpty from 'lodash/isEmpty';
import { CALL_API } from 'redux_store/middleware/api';

const SAVE_LOCATION_REQUEST = 'SAVE_LOCATION_REQUEST';
const SAVE_LOCATION_SUCCESS = 'SAVE_LOCATION_SUCCESS';
const SAVE_LOCATION_FAILURE = 'SAVE_LOCATION_FAILURE';

const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';

const LOAD_LOCATION_REQUEST = 'LOAD_LOCATION_REQUEST';
const LOAD_LOCATION_SUCCESS = 'LOAD_LOCATION_SUCCESS';
const LOAD_LOCATION_FAILURE = 'LOAD_LOCATION_FAILURE';

function getLocationById(id) {
  const method = 'get';
  const endpoint = `/locations/${id}`;
  return {
    [CALL_API]: {
      types: [
        LOAD_LOCATION_REQUEST,
        LOAD_LOCATION_SUCCESS,
        LOAD_LOCATION_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function putLocation(body) {
  const { id } = body;
  const method = 'put';
  const endpoint = `/locations/${id}`;

  if (!body.url) {
    delete body.url;
  }

  return {
    [CALL_API]: {
      types: [
        UPDATE_LOCATION_REQUEST,
        UPDATE_LOCATION_SUCCESS,
        UPDATE_LOCATION_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

function postLocation(body) {
  const method = 'post';
  const endpoint = '/locations';
  return {
    [CALL_API]: {
      types: [
        SAVE_LOCATION_REQUEST,
        SAVE_LOCATION_SUCCESS,
        SAVE_LOCATION_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

export function loadLocation(id) {
  return (dispatch) => {
    return dispatch(getLocationById(id));
  };
}

export function saveLocation(data) {
  return (dispatch) => {
    if (!data || isEmpty(data)) {
      return Promise.resolve(null);
    }
    const body = {
      ...data,
    };
    const action = body.id ? putLocation : postLocation;
    return dispatch(action(body))
      .then((res) => {
        return res;
      });
  };
}
