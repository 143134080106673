import React, { useMemo } from 'react';
import styled from 'styled-components';
import { browserHistory } from 'react-router';
import { getResizedImageURL, getRSVPLabelAndStatus } from 'utils/helpers';
import { IMAGE_SIZES } from 'appconstants';
import { getDateRange } from 'utils/dates';

const HeroView = ({festival, hasContent}) => {
  const  srcSet = useMemo(() => [
    `${getResizedImageURL(festival.pageDesign.heroPicture, IMAGE_SIZES.X_LARGE)}`,
    `${getResizedImageURL(festival.pageDesign.heroPicture, IMAGE_SIZES.XX_LARGE)} 2x`,
    `${getResizedImageURL(festival.pageDesign.heroPicture, IMAGE_SIZES.XXX_LARGE)} 3x`,
  ].join(', '), [festival]);

  const { rsvpDisable, rsvpLabel } = getRSVPLabelAndStatus(festival, [], null, true);

  return (
    <HeroImageContainer>
      <HeroImage
        src={getResizedImageURL(festival.pageDesign.heroPicture, IMAGE_SIZES.LARGE)}
        srcSet={srcSet}
        alt="hero-picture"
        hasContent={hasContent}
      ></HeroImage>
      <HeroDescription>
        <FestivalDate descriptionColor={festival.pageDesign.descriptionColor}>
          {getDateRange(festival.startTime, festival.endTime)}
        </FestivalDate>
        <FestivalDescription descriptionColor={festival.pageDesign.descriptionColor}>
          {festival.description}
        </FestivalDescription>
        <RegisterButton
          disabled={rsvpDisable}
          onClick={() => browserHistory.push(`${window.location.pathname}/checkout`)}
          actionButtonColor={festival.pageDesign.actionButtonColor}
        >
          {
            rsvpLabel
          }
        </RegisterButton>
      </HeroDescription>
    </HeroImageContainer>
  );
};

const FestivalDate = styled.div`
  color: ${({ descriptionColor }) => descriptionColor};
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
`;

const FestivalDescription = styled.div`
  color: ${({ descriptionColor }) => descriptionColor};
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  white-space: pre-wrap;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
`;

const HeroDescription = styled.div`
  position: absolute;
  bottom: 56px;
  left: 50%;
  transform: translateX(-50%);
  width: 595px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`;

const HeroImageContainer = styled.div`
  position: relative;
`;

const HeroImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: ${({hasContent}) => (hasContent ? '570px' : 'calc(100vh - 70px)')};
`;

const RegisterButton = styled.button`
  height: 64px;
  width: 184px;
  border-radius: 36px;
  background-color: ${({ actionButtonColor }) => actionButtonColor};
  outline: none;
  border: none;
  color: inherit;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
  cursor: pointer;
  transition: opacity .25s;
  box-shadow: rgb(0 0 0 / 0%) -18px 2px 7px 11px, rgb(0 0 0 / 26%) 0px 11px 20px 0px;

  &:hover {
    opacity: .8;
  }
`;

  export default HeroView;
