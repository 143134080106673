import React, { Component } from "react";
import { func, bool, string, object } from "prop-types";
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { get, isEmpty } from 'lodash';
import Check from 'material-ui/svg-icons/action/check-circle';
import CircularProgress from 'material-ui/CircularProgress';
import Snackbar from 'material-ui/Snackbar';

import { saveExperienceRelive } from 'actions/experiences';
import { selectCurrentExperience } from 'selectors/experiences';

import Button from "components/Button";
import FormFieldShowingError from 'components/common/FormFieldShowingError';

import { BUTTON_STYLES, PLAYERS } from "appconstants";
import { replaceRelivePage } from "utils/helpers";
import {
  required,
  createValidator,
  validOrEmptyURL,
} from 'utils/validation';

import "./styles.css";

const styles = {
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
  snackbarInfo: {
    backgroundColor: '#69D27C',
  }
};

const players = [
  { id: PLAYERS.YOUTUBE, description: PLAYERS.YOUTUBE},
  { id: PLAYERS.VIMEO, description: PLAYERS.VIMEO},
  { id: PLAYERS.OTHER, description: PLAYERS.OTHER}
];

const validate = createValidator({
  url: [validOrEmptyURL],
  player: [required('PLAYER')]
});

class ReliveForm extends Component {
  constructor() {
    super();
    this.state = {
      openSnackbar: false,
      snackbarMessage: ""
    };
  }

  onSubmit = async ({ url, player }) => {
    const {
      experience: {
        id,
        alias,
        channel: { urlSlug },
      },
      saveRelive,
    } = this.props;
    try {
      const urlIsSet = url && !isEmpty(url);
      const relivePlayer =  (urlIsSet) ? player : "";
      await saveRelive({ id, reliveUrl: url, relivePlayer });
      if (urlIsSet) {
        if (player === PLAYERS.YOUTUBE || player === PLAYERS.VIMEO) {
          browserHistory.push(replaceRelivePage({ alias, channel: urlSlug }));
        } else {
          window.location.assign(url);
        }
      }
      this.setState({
        openSnackbar: true,
        snackbarMessage: "The relive was submitted succesfully!"
      });
    } catch(err) {
      const validationKeys = get(err, 'validation.keys', []);
      let keys = {};
      validationKeys.forEach((key) => (keys[key] = err.message));
      this.setState({ openSnackbar: true });

      throw new SubmissionError({
        ...keys,
        _error: err.message || 'Submit Failed',
      });
    }
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    const { openSnackbar, snackbarMessage } = this.state;

    return (
      <div className="relive-form-container">
        <h2 className="relive-form-title">
          Submit a recording of this experience for your community to re-live
        </h2>
        <form
          className="relive-form-form"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <Field
            name="player"
            id="relive-form-input"
            className={"relive-form-select"}
            component="select"
          >
            <option value="" selected disabled hidden>
              Select player
            </option>
            {players.map((item, index) => (
              <option key={index} value={item.id}>
                {item.description}
              </option>
            ))}
          </Field>
          <Field
            name="url"
            placeholder="Enter URL"
            id="relive-form-input"
            component={FormFieldShowingError}
            type="text"
          />
          {submitting ? (
            <CircularProgress color="#976efa" />
          ) : (
            <Button
              className="relive-form-button"
              type="submit"
              buttonStyle={BUTTON_STYLES.PURPLE_PILL}
            >
              <div className="relive-form-button-content">
                <Check
                  color="#FFFFFF"
                  style={{ height: "24px", width: "24px" }}
                />
                <span>Publish to re-live</span>
              </div>
            </Button>
          )}
        </form>
        <Snackbar
          open={openSnackbar}
          message={snackbarMessage || error}
          autoHideDuration={4000}
          onRequestClose={() => this.setState({ openSnackbar: false, snackbarMessage: "" })}
          bodyStyle={snackbarMessage ? styles.snackbarInfo : styles.snackbar}
        />
      </div>
    );
  }
}

ReliveForm.propTypes = {
  handleSubmit: func.isRequired,
  saveRelive: func.isRequired,
  submitting: bool.isRequired,
  experience: object,
  error: string
};

ReliveForm = reduxForm({
  form: 'ReliveForm',
  validate
})(ReliveForm);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveRelive: saveExperienceRelive }, dispatch);

const mapStateToProps = (state) => ({
  experience: selectCurrentExperience(state),
  initialValues: {
    player: selectCurrentExperience(state).relivePlayer || "",
    url: selectCurrentExperience(state).reliveUrl || ""
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReliveForm);
