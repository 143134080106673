import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Check from 'material-ui/svg-icons/action/check-circle';
import Loading from 'components/Loading';
import { validateDescription, validateDates, validateLocation, validateTickets, validatePhotos } from 'utils/validation';


import moment from 'moment-timezone';
import{head, last, sortBy } from 'lodash';

const items = [{
  title: 'Description',
  getText: exp => {
    const validate = validateDescription(exp);
    return (validate.status ? (
     `${exp.description.substring(0, 140)}...`
    ) : validate.errors[0]);
  },
  getColor: exp => (validateDescription(exp).status ? 'green' : 'red'),
}, {
  title: 'Date',
  getText: (exp) => {
    const validate = validateDates(exp);
    const {startTime, endTime} = exp;
    const timezone = moment().tz(moment.tz.guess()).zoneAbbr();

    if(!validate.status) return validate.errors[0];

    if (validate.status && startTime && endTime) {
      return `${moment(startTime).format('Do MMM, h:mma')} - ${moment(endTime).format('Do MMM, h:mma')} ${timezone}`;
    }

    return 'A date is required to publish this experience';
  },
  getColor: exp => {
    return validateDates(exp).status ? 'green' : 'red';
  },
}, {
  title: 'Tickets',
  getText: (exp) => {
    const validate = validateTickets(exp);
    if(!validate.status) return validate.errors[0];

    const sortedTickets = sortBy(exp.tickets, 'price');
    const h = head(sortedTickets).price;
    const l = last(sortedTickets).price;
    if (h !== undefined) {
      if (h === l) {
        return `$${h}`;
      }
      return `$${h} - $${l}`;
    }

    return 'Ticket pricing is required to publish this experience';
  },
  getColor: (exp) => {
    return validateTickets(exp).status ? 'green' : 'red';
  },
}, {
  title: 'Location',
  getText: (exp) => {
    const validate = validateLocation(exp);
    if(!validate.status) return validate.errors[0];

    if (exp.location && exp.type === 'virtual') {
      return `${exp.location.platform}, ${exp.location.url}`;
    }
    return 'A location must be entered to publish this experience';
  },
  getColor: exp => (validateLocation(exp).status ? 'green' : 'red'),
}, {
  title: 'Featured',
  getText: (exp) => {
    const featuredArtist = exp.artists.some(item => item.name);
    if(!featuredArtist){
      return 'No artists were featured';
    }
    return `Featuring: ${exp.artists.map(artist => (artist.name)).join(', ')}`;
  },
  getColor: exp => (exp.artists.length > 0 && exp.artists.some(item => item.name) ? 'green' : 'yellow'),
}, {
  title: 'Guidelines',
  getText: (exp) => {
    if (exp.guidelines.length === 1) {
      return '1 guideline';
    } else if (exp.guidelines.length > 1) {
      return `${exp.guidelines.length} guidelines`;
    }
    return 'Use guidelines to let guests know how to show up';
  },
  getColor: exp => (exp.guidelines.length > 0 ? 'green' : 'yellow'),
}, {
  title: 'Cover Photo',
  getText: (exp) => {
    const validate = validatePhotos(exp);
    if(!validate.status) return validate.errors[0];

    return 'Cover successfully uploaded.';
  },
  getColor: exp => (validatePhotos(exp).status ? 'green' : 'red')
}];

const colors = {
  green: '#69D27C',
  red: '#FF6767',
  yellow: '#FEBD21',
};

class ConfirmPageVirtual extends Component {

  renderItem(title, text, color, index) {
    const {
      experience
    } = this.props;

    let subtitleColor = color;
    if (subtitleColor === colors.green) {
      subtitleColor = undefined;
    }

    if (experience && experience.festival && title === 'Tickets') {
      return null;
    }

    return (
      <div className="confirm-item" key={title} onClick={() => { this.props.goToPage(index); }}>
        <Check style={{ color, height: '29px', width: '29px' }} />
        <div>
          <div className="confirm-item-title">{title}</div>
          <div className="confirm-item-subtitle" style={{ color: subtitleColor }}>{text}</div>
        </div>
      </div>
    );
  }

  render() {
    const {loading} = this.props;

    return (
      loading ?
        <Loading message="Loading Publish confirm ..." />
      :
        <div className="confirm-wrapper experience-wrapper">
          <div className="confirm-container">
            {items.map((item, index) => (this.renderItem(item.title, item.getText(this.props.experience), colors[item.getColor(this.props.experience)], index + 1)))}
          </div>
        </div>
    );
  }
}

ConfirmPageVirtual.propTypes = {
  experience: PropTypes.any,
  goToPage: PropTypes.func,
  loading: PropTypes.func,

};

ConfirmPageVirtual = connect(state => ({
  experience: state.experiences.experience,
}))(ConfirmPageVirtual);

export default ConfirmPageVirtual;
