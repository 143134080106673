import isEmpty from 'lodash/isEmpty';
import { CALL_API } from 'redux_store/middleware/api';

const SAVE_GUIDELINE_REQUEST = 'SAVE_GUIDELINE_REQUEST';
const SAVE_GUIDELINE_SUCCESS = 'SAVE_GUIDELINE_SUCCESS';
const SAVE_GUIDELINE_FAILURE = 'SAVE_GUIDELINE_FAILURE';

const UPDATE_GUIDELINE_REQUEST = 'UPDATE_GUIDELINE_REQUEST';
const UPDATE_GUIDELINE_SUCCESS = 'UPDATE_GUIDELINE_SUCCESS';
const UPDATE_GUIDELINE_FAILURE = 'UPDATE_GUIDELINE_FAILURE';

const LOAD_GUIDELINE_REQUEST = 'LOAD_GUIDELINE_REQUEST';
const LOAD_GUIDELINE_SUCCESS = 'LOAD_GUIDELINE_SUCCESS';
const LOAD_GUIDELINE_FAILURE = 'LOAD_GUIDELINE_FAILURE';

function getById(id) {
  const method = 'get';
  const endpoint = `/guidelines/${id}`;
  return {
    [CALL_API]: {
      types: [
        LOAD_GUIDELINE_REQUEST,
        LOAD_GUIDELINE_SUCCESS,
        LOAD_GUIDELINE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function getDefault() {
  const method = 'get';
  const endpoint = '/guidelines';
  return {
    [CALL_API]: {
      types: [
        LOAD_GUIDELINE_REQUEST,
        LOAD_GUIDELINE_SUCCESS,
        LOAD_GUIDELINE_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function post(body) {
  const isUpdate = body.id;
  const method = isUpdate ? 'put' : 'post';
  const types = isUpdate ?
  [UPDATE_GUIDELINE_REQUEST, UPDATE_GUIDELINE_SUCCESS, UPDATE_GUIDELINE_FAILURE] :
  [SAVE_GUIDELINE_REQUEST, SAVE_GUIDELINE_SUCCESS, SAVE_GUIDELINE_FAILURE];

  const endpoint = isUpdate ? `/guidelines/${body.id}` : '/guidelines';
  return {
    [CALL_API]: {
      types,
      method,
      endpoint,
      body,
    },
  };
}

export function loadGuidelines(ids) {
  return (dispatch) => {
    const getActions = ids.map((id) => {
      return dispatch(getById(id));
    });
    return Promise.all(getActions);
  };
}

export function loadGuideline(id) {
  return (dispatch) => {
    return dispatch(getById(id));
  };
}

export function loadDefaultGuidelines() {
  return (dispatch) => {
    return dispatch(getDefault());
  };
}

export function saveGuideline(data) {
  return (dispatch) => {
    const body = {
      ...data,
    };
    return dispatch(post(body))
      .then((res) => {
        return res;
      });
  };
}

export function saveGuidelines(flows) {
  return (dispatch) => {
    const saveActions = flows.map((item) => {
      if (!isEmpty(item)) {
        return dispatch(saveGuideline(item));
      }
      return () => {};
    });
    return Promise.all(saveActions);
  };
}
