import axios from 'axios';
import { getAccessToken } from 'utils/helpers';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
});

client.interceptors.request.use(
  function (config) {
    const token = getAccessToken();
    if (token && token.accessToken) {
      config.headers['x-access-token'] = token.accessToken;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  function onSuccess({ data }) {
    return data;
  },
  function onError(error) {
    return Promise.reject(error)
  },
);

export default client;
