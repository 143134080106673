import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, change } from 'redux-form';
import { Card } from 'material-ui/Card';
import Check from 'material-ui/svg-icons/action/check-circle';
import FileUploader from 'components/FileUploader';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';
import { saveProfileAction } from 'actions/auth';
import { VALIDATION, FILE_UPLOADER_TYPES } from 'appconstants';
import { get, isEmpty } from 'lodash';
import professions from 'utils/professions';
import locations from 'utils/locations';
import countries from 'utils/countries';
import { createValidator, required, stringMin } from 'utils/validation';
import TextAreaShowingError from 'components/common/TextAreaShowingError';
import SelectFieldShowingError from 'components/common/SelectFieldShowingError';
import FormFieldShowingError from 'components/common/FormFieldShowingError';
import { selectUser, selectUserLoading } from 'selectors/user';
import { useRadioGroup } from '@material-ui/core';

const colors = {
  green: '#69D27C',
  red: 'rgba(255, 0, 13, 0.88)',
  yellow: '#FEBD21',
};

const styles = {
  snackbar: {
    backgroundColor: colors.red,
  },
  snackbarInfo: {
    backgroundColor: colors.green,
  },
  card: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
  },
  label: {
    color: '#999999',
    fontFamily: 'CircularStd Book',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '20px',
    marginTop: '25px',
    marginBottom: '10px',
  },
  h3: {
    fontSize: '20px',
    marginTop: '10px',
  },
  p: {
    color: '#626262',
    fontFamily: 'CircularStd Book',
    fontSize: '13px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '16px',
  },
};
const validate = createValidator({
  about: [stringMin({ fieldName: 'about', min: VALIDATION.DESCRIPTION })],
  profession: [required('profession')],
  // state: [required('state')],
});

class CreateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoMessage: false,
      message: null,
      dataSet: false,
    };
    this.setData = this.setData.bind(this);
    this.resetShowErrors = this.resetShowErrors.bind(this);
  }

  componentDidUpdate() {
    if (this.props.settings && !this.state.dataSet) {
      this.setData();
      this.setState({ dataSet: true });
    }
  }

  setData() {
    const { change: changeData, settings, user } = this.props;
    const { email, name, photo, about, profession, state, facebook, instagram, country, phone } =
      user;
    if (!settings) return;

    const photoProfile = photo && [{ name: photo, url: photo, default: true }];

    changeData('email', email);
    changeData('name', name);
    changeData('photo', photoProfile);
    changeData('about', about);
    changeData('state', state);
    changeData('profession', profession);
    changeData('facebook', facebook);
    changeData('instagram', instagram);
    changeData('country', country);
    changeData('phone', phone);
  }

  resetShowErrors() {
    this.setState({
      infoMessage: false,
      message: null,
    });
  }

  async submit(data) {
    const { onSuccess } = this.props;
    const payload = {
      ...data,
      photo: data.photo && data.photo.length ? data.photo[0].data : null,
    };

    if (!payload.photo) {
      delete payload.photo;
    }

    if (payload.email) {
      delete payload.email;
    }
    try {
      await this.props.saveProfileAction(payload);
      this.setState({ infoMessage: true, message: 'Your profile was successfully saved.' });

      if (onSuccess) onSuccess();
    } catch (err) {
      const validationKeys = get(err, 'validation.keys', []);
      let keys = {};
      validationKeys.forEach((key) => (keys[key] = err.message));
      throw new SubmissionError({
        ...keys,
        _error: err.message || 'Submit Failed',
      });
    }
  }

  render() {
    const { handleSubmit, error, errorMessage, submitFailed, settings, submitting, loading, user } =
      this.props;

    const { infoMessage, message: infoMsg } = this.state;
    const message = error || errorMessage || infoMsg;
    const open = submitFailed && message;
    if (settings && (loading || isEmpty(user))) {
      return (
        <div className="loader-container-settings">
          <CircularProgress size={80} thickness={5} color={'#9764f0'} />
        </div>
      );
    }
    return (
      <div className="container-settings">
        {!settings && <h3 style={styles.h3}>Create your Profile</h3>}
        <form
          className="settings-form"
          onSubmit={handleSubmit((values) => this.submit({ ...{ photo: user.photo }, ...values }))}
        >
          <Card style={styles.card}>
            <div className="photos-wrapper">
              <div className="upload-file-container">
                <div className="data-col upload-file">
                  <div style={styles.label}>Upload Photo</div>
                  <Field
                    name="photo"
                    minWidth={800}
                    type="select-multiple"
                    uploaderType={FILE_UPLOADER_TYPES.SQUARE}
                    component={FileUploader}
                    // props={{photo: user?.photo}}
                    validate={!settings && [required('photo')]}
                  />
                  <p style={styles.p}>Your photo must be at least 800px</p>
                </div>
              </div>
            </div>
            {settings && (
              <div>
                <div className="containerInput">
                  <div className="labelContainer">
                    <div style={styles.label}>Full Name</div>
                  </div>
                  <Field
                    name="name"
                    className="simple-input"
                    component={FormFieldShowingError}
                    placeholder=""
                    type="text"
                  />
                </div>
                <div className="containerInput">
                  <div className="labelContainer">
                    <div style={styles.label}>Email</div>
                  </div>
                  <Field
                    name="email"
                    className="simple-input"
                    component={FormFieldShowingError}
                    placeholder=""
                    type="text"
                    disabled={settings}
                  />
                </div>
                <div className="container-select-input">
                  <div className="container-select">
                    <div className="labelContainer">
                      <div style={styles.label}>Country</div>
                    </div>
                    <Field
                      name="country"
                      className="select-profile"
                      component={SelectFieldShowingError}
                    >
                      {!settings && (
                        <option id="default" value="">
                          Select Country
                        </option>
                      )}
                      {countries.map((item, index) => {
                        return (
                          <option key={index} selected={user.country === item.id} value={item.id}>
                            {item.description}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="container-select">
                    <div className="labelContainer">
                      <div style={styles.label}>State / Province</div>
                    </div>
                    <Field
                      name="state"
                      className="select-profile"
                      component={SelectFieldShowingError}
                    >
                      {!settings && (
                        <option id="default" value="">
                          Select locations
                        </option>
                      )}
                      {locations.map((item, index) => {
                        return (
                          <option key={index} selected={user.state === item.id} value={item.id}>
                            {item.description}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </div>
              </div>
            )}
            <div className="containerInput">
              <div className="labelContainer">
                <div style={styles.label}>Bio</div>
              </div>
              <Field
                name="about"
                className="aboutTextarea"
                component={TextAreaShowingError}
                placeholder=""
              />
            </div>
            <div className="container-general">
              <div className="container-select">
                <div className="labelContainer">
                  <div style={styles.label}> Type of creator</div>
                </div>
                <Field
                  name="profession"
                  className="select-profile"
                  component={SelectFieldShowingError}
                >
                  <option id="default" value="">
                    Select Type
                  </option>
                  {professions.map((item, index) => {
                    return (
                      <option key={index} selected={user.profession === item.id} value={item.id}>
                        {item.description}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="container-select">
                <div className="labelContainer">
                  <div style={styles.label}>Phone</div>
                </div>
                <Field
                  name="phone"
                  className="simple-input"
                  component={FormFieldShowingError}
                  placeholder=""
                  type="text"
                />
              </div>
            </div>
            <div className="containerInput">
              <div className="labelContainer">
                <div style={styles.label}>Instagram handle</div>
              </div>
              <Field
                name="instagram"
                className="simple-input"
                component={FormFieldShowingError}
                placeholder="@"
                type="text"
              />
            </div>
            <div className="containerInput">
              <div className="labelContainer">
                <div style={styles.label}>Facebook</div>
              </div>
              <Field
                name="facebook"
                className="simple-input"
                component={FormFieldShowingError}
                placeholder=""
                type="text"
              />
            </div>
            {submitting ? (
              <CircularProgress style={{ marginTop: '1rem' }} color={'#976efa'} />
            ) : (
              <button
                className="continueProfile-button button-settings"
                onClick={this.resetShowErrors}
              >
                <Check color="#FFFFFF" className="check" />
                <div className={settings ? 'container-save' : 'container-continue'}>
                  {settings ? 'Save' : 'Continue'}
                </div>
              </button>
            )}
          </Card>
        </form>
        <Snackbar
          open={!!open || infoMessage}
          message={message}
          autoHideDuration={4000}
          bodyStyle={infoMessage ? styles.snackbarInfo : styles.snackbar}
        />
      </div>
    );
  }
}

CreateProfile.propTypes = {
  handleSubmit: PropTypes.func,
  saveProfileAction: PropTypes.func,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  submitFailed: PropTypes.bool,
  settings: PropTypes.bool,
  user: PropTypes.object,
  loading: PropTypes.bool,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  onSuccess: PropTypes.func,
};

CreateProfile = reduxForm({
  form: 'CreateProfileForm',
  validate,
})(CreateProfile);

const mapStateToProps = (state) => {
  const form = state.form.CreateProfileForm;
  const errors = form && (form.syncErrors || (form.submitErrors && 'Submit failed!'));
  return {
    errors,
    errorMessage: errors && errors[Object.keys(errors)[0]],
    user: selectUser(state),
    loading: selectUserLoading(state),
    initialValues: selectUser(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveProfileAction, change }, dispatch);

CreateProfile = connect(mapStateToProps, mapDispatchToProps)(CreateProfile);

export default CreateProfile;
