import React, { useEffect, useState } from 'react';
import { Dialog, Toggle } from 'material-ui';
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete';

const styles = {
  bodyStyle: {
    padding: '0',
  },
  contentStyle: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    boxShadow: '0 2px 14px 0 rgba(0,0,0,0.23)',
    maxHeight: '346px',
    width: '412px',
  },
  titleStyle: {
    borderRadius: '3px 3px 0 0',
    backgroundColor: '#F5F5F5',
    color: '#3C2784',
    fontFamily: 'CircularStd Book',
    fontSize: '14px',
    fontWeight: '500',
    height: 'auto',
    lineHeight: '24px',
    padding: '25px 18px',
  },
  toggleStyle: {
    elementStyle: {
      height: '32px',
    },
    thumbStyle: {
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
      height: '22px',
      left: '6px',
      top: '11px',
      width: '22px',
    },
    thumbSwitchedStyle: {
      backgroundColor: '#FFFFFF',
      left: 'auto',
      right: '6px',
    },
    trackStyle: {
      backgroundColor: '#D5D5D5',
      height: '32px',
      width: '66px',
    },
    trackSwitchedStyle: {
      backgroundColor: '#69D27C',
    },
  }
};

const TicketDialog = ({
  longDescription,
  onClose,
  onDelete,
  open,
  updateTicket,
  visibility,
  name: initialName,
}) => {
  const [description, setDescription] = useState(longDescription);
  const [name, setName] = useState(initialName);
  const [isVisible, setIsVisible] = useState(visibility);

  useEffect(() => {
    setName(initialName);
  }, [initialName, setName]);

  return (
    <Dialog
      bodyClassName="ticketing-dialog-body"
      bodyStyle={styles.bodyStyle}
      contentStyle={styles.contentStyle}
      open={open}
      onRequestClose={onClose}
      title={(
        <div>PASS SETTINGS<CloseIcon color="#999999" onClick={onClose} /></div>
      )}
      titleClassName="ticketing-dialog-title"
      titleStyle={styles.titleStyle}
    >
    <div>
      <div className="description">
        <span>NAME</span>
        <input
          placeholder="Name of the ticket"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </div>
      <div className="description">
        <span>DESCRIPTION</span>
        <textarea
          placeholder="Share more about this ticket"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
      </div>
      <div className="visibility">
        <span>VISIBILITY</span>
        <Toggle
          className="visibility-toggle"
          onToggle={(e, isInputChecked) => setIsVisible(isInputChecked)}
          thumbStyle={styles.toggleStyle.thumbStyle}
          thumbSwitchedStyle={styles.toggleStyle.thumbSwitchedStyle}
          toggled={isVisible}
          trackStyle={styles.toggleStyle.trackStyle}
          trackSwitchedStyle={styles.toggleStyle.trackSwitchedStyle}
        />
      </div>
      <div className="tickets-buttons-container">
        <button
          onClick={() => {
            onDelete();
            onClose();
          }}
        >
          Delete<DeleteIcon color="#999999" />
        </button>
        <button
          onClick={() => {
            updateTicket({
              longDescription: description,
              visibility: isVisible,
              name,
            });
            onClose();
          }}
        >
          Ok
        </button>
      </div>
    </div>
    </Dialog>
  );
}

export default TicketDialog;
