import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import { unpublish } from 'actions/experiences';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { useFestivalCMS } from 'hooks/useFestivalInformation';
import KebabMenu from 'components/KebabMenu';
import Loader from './Loader';

const FestivalExperiences = ({
  channel,
  alias,
  router,
  unpublish,
}) => {
  const { isLoading, data, refetch } = useFestivalCMS(alias, channel, 'experiences');
  const unpublishExperience = useCallback(async (id) => {
    try {
      await unpublish(id);
      refetch();
    } catch (error) {
      console.log(error);
    }
  }, [unpublish, refetch]);
  const getExperienceMenuItems = useCallback(({ _id, channel, alias }) => {
    return [
      {
        label: 'Edit',
        onClick: () => router.push(`/in/${channel.urlSlug}/${alias}/dashboard?initial-tab=edit`)
      },
      {
        label: 'Unpublish',
        onClick: () => unpublishExperience(_id),
      },
      {
        label: 'Visit Experience',
        onClick: () => router.push(`/in/${channel.urlSlug}/${alias}`)
      }
    ];
  }, [router, unpublishExperience])

  if (isLoading) {
    return (
      <Loader />
    )
  }

  return (
    <>
      <div className="discounts-title-container">
        <span className="discounts-title">Experiences</span>
      </div>
      <div className="content">
        <table>
          <thead>
            <th>NAME</th>
            <th>CREATOR</th>
            <th>GUESTS</th>
            <th>REFERRALS</th>
            <th></th>
          </thead>
          <tbody>
            {
              data.map((experience) => (
                <tr key={experience._id}>
                  <td>
                    <a
                      href={`/in/${experience.channel.urlSlug}/${experience.alias}`}
                      target='_blank'
                      rel="nofollow noreferrer"
                      className="festival-dashboard__experience-name"
                    >
                      {experience.name}
                    </a>
                  </td>
                  <td>{experience.creator || '-'}</td>
                  <td>{experience.guests}</td>
                  <td>{experience.referralsCount}</td>
                  <td>
                    <KebabMenu
                      options={getExperienceMenuItems(experience)}
                    />
                  </td>
                </tr>
              ))
            }
            {
              data.length === 0 && (
                <tr>
                  <td colSpan="4">
                    <span className="discounts--empty-state">
                      No experiences have been added as yet.
                      <button className="discounts-new-btn" onClick={() => router.push('/create-experience')}>Submit experience</button>
                    </span>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </>
  )
}


const mapDispatchToProps = (dispatch) => bindActionCreators(
  { unpublish },
  dispatch,
);

export default withRouter(connect(() => ({}), mapDispatchToProps)(FestivalExperiences));
