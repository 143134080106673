import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FieldCheckbox from 'components/FieldCheckbox';
import Loading from 'components/Loading';
// import { TIME_OUT } from 'appconstants';

const LocationPage = props => {
  const { locations, loading } = props;

  return (
    loading
    ?
      <Loading message="Loading Locations..." />
    :
      <div className="location-wrapper experience-wrapper">
        <Field name="location.space" component="select">
          <option>Type of location</option>
          {
          locations.map((item, index) => {
            return (
              <option key={index} value={item.id}>{item.description}</option>);
          })
        }
        </Field>
        <Field name="location.name" placeholder="Name of location" component="input" type="text" />
        <Field name="location.address" placeholder="Address" component="input" type="text" />
        <Field name="location.city" placeholder="City" component="input" type="text" />
        <div className="state-zipcode-container">
          <div className="state-container">
            <Field name="location.state" placeholder="State" component="input" type="text" />
          </div>
          <div className="zipcode-container">
            <Field name="location.zip" placeholder="Zipcode" component="input" type="text" />
          </div>
        </div>
        <div className="single-check">
          <Field
            name="hideLocationPriorPurchase"
            component={FieldCheckbox}
            label="Show address only when ticket is purchased"
          />
        </div>
      </div>
  );
};

LocationPage.propTypes = {
  locations: PropTypes.array,
  loading: PropTypes.bool,
};


export default LocationPage;
