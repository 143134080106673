import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'
import Loading from 'components/Loading';

const renderGuidelines = ({ fields }) => {
  return (<div>
    { fields.map((item, index) => <Field key={index} name={`${item}.guideline`} component="input" placeholder="Add guideline" type="text" />
    )}
    <button
      onClick={(e) => { 
        e.preventDefault()
        fields.push({}); 
      }}
      // href="javascript:void(0);"
      className="add-field-wrapper"
    >
      <b>+ ADD GUIDELINE</b>
    </button>
  </div>)
};

const AddGuidelinesPage = props => {
  const {loading, festival, festivals, handleFestivalDates} = props;
  useEffect(() => {
    if (festival && festivals.length) {
      const selectedFestival = festivals.find(({ id }) => id === festival);

      if (!selectedFestival) {
        return;
      }
      handleFestivalDates()
    }
  }, [
    festival,
  ])
  return (
    loading ?
      <Loading message="Loading Guidelines ..." />
    :
      <div className="experience-guidelines experience-wrapper">
        <FieldArray name="guidelines" component={renderGuidelines} />
      </div>
  );
};

AddGuidelinesPage.propTypes = {
  loading: PropTypes.bool,
};


renderGuidelines.propTypes = {
  fields: PropTypes.object,
};

const selector = formValueSelector('ExperienceForm');

const mapStateToProps = (state) => {
  return {
  festival: selector(state, 'festival'),
  festivals: state.festivals.userFestivals,
}};

export default connect(mapStateToProps)(AddGuidelinesPage);
