import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, stopSubmit, change, formValueSelector } from 'redux-form';
import { Card } from 'material-ui/Card';
import CircularProgress from 'material-ui/CircularProgress';
import Check from 'material-ui/svg-icons/action/check-circle';
import { i18nTimezones } from 'react-timezone-select'
import FileUploader from 'components/FileUploader';
import FieldCheckbox from 'components/FieldCheckbox';
import Snackbar from 'material-ui/Snackbar';
import { saveChannelAction, setCurrentChannel, fetchChannels, createSubcategory, deleteSubcategory, getSubcategories } from 'actions/channels';
import { updateUser } from 'actions/auth';
import { fetchAllTags } from 'actions/tags';
import { VALIDATION, FILE_UPLOADER_TYPES } from 'appconstants';
import { get } from 'lodash';
import locations from 'utils/locations';
import countries, { US_COUNTRY } from 'utils/countries';
import {
  required,
  createValidator,
  validOrEmptyURL,
  stringMin,
  stringMinMax,
} from 'utils/validation';
import FormFieldShowingError from 'components/common/FormFieldShowingError';
import SelectFieldShowingError from 'components/common/SelectFieldShowingError';
import TextAreaShowingError from 'components/common/TextAreaShowingError';
import { selectDefaultCategories, selectSubCategories } from 'selectors/tags';
import { selectChannelsForUserToEdit } from 'selectors/user';
import './styles.css';
import CreatorField from './CreatorField';
import RemovableList from './RemovableList';
import { selectChannelSubcategories } from 'selectors/channels';
import { userHasAccess } from 'utils/helpers';
import { withRouter } from 'react-router';
import routes from 'appconstants/routes';


const styles = {
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
  snackbarInfo: {
    backgroundColor: '#69D27C',
  },
  card: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
  },
  cardCategory: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '30px',
    marginTop: '10px',
    minHeight: '260px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.04)',
  },
};

const validate = createValidator({
  name: [stringMin({ fieldName: 'name', min: VALIDATION.NAME })],
  about: [
    stringMinMax({ fieldName: 'about', min: VALIDATION.MIN_ABOUT, max: VALIDATION.MAX_ABOUT }),
  ],
  country: [stringMin({ fieldName: 'country', min: VALIDATION.COUNTRY })],
  // state: [required('location')],
  urlSlug: [required('url slug')],
  website: [validOrEmptyURL],
  category: [required('category')],
  subCategory: [required('sub category')],
  defaultTimezone: [stringMin({ fieldName: 'defaultTimezone', min: VALIDATION.NAME})]
});

class CreateChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoMessage: false,
      message: null,
    };
    this.setData = this.setData.bind(this);
    this.handlerChangeChannel = this.handlerChangeChannel.bind(this);
    this.resetShowErrors = this.resetShowErrors.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { categories, settings, channels, currentChannel } = this.props;

    if (!categories.length) {
      this.props.fetchAllTags();
    }

    if (settings) {
      if (currentChannel?.id) {
        this.setData(currentChannel.id);
      } else {
        this.setData(channels[0].id);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentChannel, router, user } = this.props;

    if (prevProps.currentChannel !== currentChannel) {
      if (currentChannel?.id) {
        if (userHasAccess({ user, urlSlug: currentChannel.urlSlug, channelSettings: true })) {
          this.setData(currentChannel.id);
        } else {
          router.replace(routes.dashboard());
        }
      }
    }
  }

  setData(channelId) {
    const { change: changeData, channels, getSubcategories } = this.props;
    const channel = channels.find((c) => c.id === channelId);
    this.setState({ channelId });

    const {
      name,
      images,
      about,
      country,
      state,
      urlSlug,
      website,
      color,
      adultContent,
      isFree,
      defaultTimezone
      //todo: add subcategories
    } = channel;
    const photo = images.length && [{ name: images[0], url: images[0], default: true }];
    getSubcategories(channelId);

    changeData('name', name || '');
    changeData('photo', photo || '');
    changeData('about', about || '');
    changeData('country', country);
    changeData('color', color || '');
    changeData('state', state);
    changeData('urlSlug', urlSlug);
    changeData('website', website || '');
    changeData('category', get(channel, 'category.id', ''));
    changeData('subCategory', get(channel, 'subCategory.id', ''));
    changeData('adultContent', adultContent);
    changeData('isFree', isFree);
    changeData('defaultTimezone', defaultTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
    // TODO: add subCategory List
    // changeData('subCategoryList', []);
  }

  async submit(data) {
    const { onSuccess } = this.props;
    const payload = {
      ...data,
      photo: data.photo && data.photo.length ? data.photo[0].data : null,
    };
    const { channelId, settings, currentChannel } = this.props;
    const id = settings ? currentChannel.id : channelId
    if (!payload.photo) delete payload.photo;
    try {
      const newChannelData = await this.props.saveChannelAction({ payload, id });
      await this.props.updateUser();
      await this.props.fetchChannels();
      this.props.setCurrentChannel(newChannelData.urlSlug);
    } catch (err) {
      const validationKeys = get(err, 'validation.keys', []);
      let keys = {};
      validationKeys.forEach((key) => (keys[key] = err.message));
      throw new SubmissionError({
        ...keys,
        _error: err.message || 'Submit Failed',
      });
    }
    if (!payload.isFree || settings) this.setState({ infoMessage: true, message: 'Your Channel was successfully saved.' });
    if (onSuccess) onSuccess(payload.isFree, { infoMessage: true, message: 'Your Channel was successfully saved.' });
  }

  async handlerChangeChannel(e) {
    const channelSelected = e.target.value;
    this.setData(channelSelected);
    this.props.getSubcategories(channelSelected);
  }

  resetShowErrors() {
    this.setState({
      infoMessage: false,
      message: null,
    });
  }

  render() {
    const {
      handleSubmit,
      error,
      errorMessage,
      submitFailed,
      settings,
      categories,
      subCategories,
      submitting,
      // channels,
      currentChannel,
      createSubcategory,
      deleteSubcategory,
      channelSubcategories,
      country,
    } = this.props;

    const subCategoriesList = channelSubcategories || []; //TODO: get subcategories
    const { channelId, infoMessage, message: infoMsg } = this.state;
    const message = error || errorMessage || infoMsg;
    const open = submitFailed && message;
    const defaultTimezone = currentChannel?.defaultTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    return (
      <div className="container-settings">
        {!settings && <h3>Setup your Channel</h3>}
        <form className="settings-form" onSubmit={handleSubmit((values) => this.submit(values))}>
          <Card style={styles.card}>
            <div className="photos-wrapper">
              <div className="upload-file-container">
                <div className="data-col upload-file">
                  <div className="label">Upload logo</div>
                  <Field
                    name="photo"
                    minWidth={200}
                    uploaderType={FILE_UPLOADER_TYPES.LOGO}
                    type="select-multiple"
                    component={FileUploader}
                    validate={!settings && [required('photo')]}
                  />
                  <p>Your logo must be 200px by 200px, white with transparent background.</p>
                </div>
              </div>
            </div>
            <div className="containerInput">
              <div className="labelContainer">
                <div className="label">Channel name</div>
              </div>
              <Field
                name="name"
                className="simple-input"
                component={FormFieldShowingError}
                type="text"
              />
              <div className="labelContainer">
                <div className="label">Description</div>
              </div>
              <Field
                name="about"
                className="aboutTextarea"
                maxLength="400"
                component={TextAreaShowingError}
              />
            </div>
            <div className="container-select">
              <div className="labelContainer">
                <div className="label">Country</div>
              </div>
              <Field name="country" className="select-profile" component={SelectFieldShowingError}>
                {!settings && (
                  <option id="default" value="">
                    Select Country
                  </option>
                )}
                {countries.map((item, index) => {
                  return (
                    <option key={index} selected={currentChannel?.country === item.id} value={item.id}>
                      {item.description}
                    </option>
                  );
                })}
              </Field>
            </div>
            <div className="container-select" style={{marginBottom: '25px'}}>
              <div className="labelContainer">
                <div className="label">State</div>
              </div>
              <Field name="state" disabled={currentChannel?.country !== US_COUNTRY.id} className="select-profile" component={SelectFieldShowingError}>
                {!settings && (
                  <option id="default" value="">
                    Select locations
                  </option>
                )}
                {locations.map((item, index) => {
                  return (
                    <option key={index} selected={currentChannel?.state === item.id} value={item.id}>
                      {item.description}
                    </option>
                  );
                })}
              </Field>
            </div>
            <div className="containerInput">
              <div className="labelContainer">
                <div className="label">Default Timezone</div>
              </div>
              <Field
                name="defaultTimezone"
                className="simple-input"
                component={SelectFieldShowingError}
              >
                <option value={defaultTimezone} selected>
                  {defaultTimezone}
                </option>
                {Object.keys(i18nTimezones).map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  )
                })}
                </Field>
            </div>
            <div className="containerInput" style={{ float: 'left', marginBottom: '25px' }}>
              <div className="labelContainer">
                <div className="label">URL</div>
              </div>
              <div className="urlslug-wrapper">
                <label className="label urlSlug-label" htmlFor="urlSlugs">
                  http://withpresence.co/in/
                </label>
                <Field
                  id="urlSlugs"
                  name="urlSlug"
                  className="simple-input"
                  component={FormFieldShowingError}
                  type="text"
                  value={currentChannel?.urlSlug}
                />
              </div>
            </div>
            <div className="containerInput">
              <div className="labelContainer">
                <div className="label">Website</div>
              </div>
              <Field
                name="website"
                className="simple-input"
                component={FormFieldShowingError}
                placeholder="www.mywebsite.com"
                type="text"
              />
            </div>
            <div className="containerInput" style={{ marginBottom: '40px' }}>
              <div className="labelContainer">
                <div className="label">Brand color</div>
              </div>
              <Field
                name="color"
                className="simple-input"
                component={FormFieldShowingError}
                placeholder="#00000"
                type="text"
              />
            </div>
          </Card>
          <Card style={styles.cardCategory}>
            <div className="container-select">
              <div className="labelContainer">
                <div className="label">Primary category</div>
              </div>
              <Field name="category" className="select-profile" component={SelectFieldShowingError}>
                {!settings && (
                  <option id="default" value="">
                    Select category
                  </option>
                )}
                {categories &&
                  categories.map((item) => {
                    // console.log(item)
                    return (<option key={item.id} selected={currentChannel?.category?.id === item.id} value={item.id}>
                      {item.description}
                    </option>
                  )})}
              </Field>
            </div>
            <div className="container-select">
              <div className="labelContainer">
                <div className="label">Secondary category</div>
              </div>
              <Field
                name="subCategory"
                className="select-profile"
                component={SelectFieldShowingError}
              >
                {!settings && (
                  <option id="default" value="">
                    Select subcategory
                  </option>
                )}
                {subCategories &&
                  subCategories.map((item, index) => (
                    <option key={index} selected={currentChannel?.subCategory?.id === item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
              </Field>
            </div>
            <div className="containerInput" style={{ float: 'left', marginTop: '20px' }}>
              <CreatorField
                placeholder="+ add"
                title="Sub categories"
                buttonLabel="Add category"
                create={(value) => createSubcategory(channelId, value)}
              />
              <RemovableList
                list={subCategoriesList}
                remove={(value) => deleteSubcategory(channelId, value)}
              />
            </div>
            <div className="containerInput" style={{ float: 'left', marginTop: '45px' }}>
              <div className="labelContainer">
                <Field
                  name="adultContent"
                  component={FieldCheckbox}
                  label="This channel contains sexual and mature content"
                  checked={currentChannel?.adultContent}
                />
              </div>
            </div>
            <div className="containerInput" style={{ float: 'left' }}>
              <div className="labelContainer">
                <Field
                  name="isFree"
                  component={FieldCheckbox}
                  label="This channel only admits free experiences"
                  checked={currentChannel?.isFree}
                />
              </div>
            </div>
            <div className="containerInput">
              {submitting ? (
                <CircularProgress style={{ 'marginTop': '1rem' }} color={'#976efa'} />
              ) : (
                <button
                  className="continueProfile-button button-settings"
                  onClick={this.resetShowErrors}
                >
                  <Check color="#FFFFFF" className="check" />
                  <div className={settings ? 'container-save' : 'container-continue'}>
                    {settings ? 'Save' : 'Continue'}
                  </div>
                </button>
              )}
            </div>
          </Card>
        </form>
        <Snackbar
          open={!!open || !!infoMessage}
          message={message}
          autoHideDuration={4000}
          onRequestClose={() => this.setState({ infoMessage: false })}
          bodyStyle={infoMessage ? styles.snackbarInfo : styles.snackbar}
        />
      </div>
    );
  }
}

CreateChannel.propTypes = {
  handleSubmit: PropTypes.func,
  saveChannelAction: PropTypes.func,
  fetchAllTags: PropTypes.func,
  channelId: PropTypes.string,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  submitFailed: PropTypes.bool,
  settings: PropTypes.bool,
  change: PropTypes.func,
  categories: PropTypes.array,
  subCategories: PropTypes.array,
  submitting: PropTypes.bool,
  channels: PropTypes.array,
  onSuccess: PropTypes.func,
};

CreateChannel = reduxForm({
  form: 'CreateChannelForm',
  validate,
  onChange: (values, dispatch, props) => {
    if (props.submitFailed && !props.submitting) {
      dispatch(stopSubmit('CreateChannelForm'));
    }
  },
})(CreateChannel);

const selector = formValueSelector('CreateChannelForm');

const mapStateToProps = (state) => {
  const form = state.form.CreateChannelForm;
  const errors = form && (form.syncErrors || (form.submitErrors && 'Submit failed!'));

  return {
    country: selector(state, 'country'),
    errors,
    user: state.auth.user,
    errorMessage: errors && errors[Object.keys(errors)[0]],
    channels: selectChannelsForUserToEdit(state),
    currentChannel: state.channels.currentChannel,
    categories: selectDefaultCategories(state),
    subCategories: selectSubCategories(state),
    channelSubcategories: selectChannelSubcategories(state),
    initialValuse: state.channels.currentChannel
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveChannelAction,
      createSubcategory,
      deleteSubcategory,
      getSubcategories,
      change,
      fetchAllTags,
      setCurrentChannel,
      fetchChannels,
      updateUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateChannel));
