import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getLogo } from 'utils/logos';
import { browserHistory } from 'react-router';
import { capitalize } from 'lodash';
import { getFullDate } from 'utils/dates';
import { getResizedImageURL } from 'utils/helpers';
import ImageNotFound from 'images/no-image-box.png';
import { EXPERIENCES_TYPES, IMAGE_SIZES, MOBILE_MAX_WIDTH } from 'appconstants';
import './styles.css';
import { object, bool, string } from 'prop-types';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { rsvpExperience } from 'actions/experiences';
import { selectRSVPedExp } from 'selectors/userExperiences';
import { TIME_FORMATS } from 'appconstants/time2';
import { dateFormat, datesAreOnSameDay, datesNotOnSameDay } from 'utils/dates';
import moment from 'moment';
const opacityValues = [1, 1, 1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0];
const scaleValues = [1, 1, 1, 0.99, 0.98, 0.97, 0.96, 0.95, 0.94, 0.93, 0.92, 0.91, 0.9];

const ScheduledItem = ({
  experience,
  lastStartTime,
  rsvpExperience,
  rsvpedExperiences,
  isLastItem,
  ctaButton,
  $borderColor,
  showTitle = true,
  ticketPage = false,
  festivals = false,
}) => {
  const [animationIndex, setAnimationIndex] = useState(0);
  const [experienceStartDate, setExperienceStartDate] = useState(null);
  const [experienceEndDate, setExperienceEndDate] = useState(null);
  useMemo(() => setExperienceStartDate(new Date(experience.startTime)), [experience]);
  useMemo(() => setExperienceEndDate(new Date(experience.endTime)), [experience]);
  const LogoIcon = getLogo(experience.location.platform);
  const experienceURL = `/in/${experience.channel.urlSlug}/${experience.isFestival ? 'f/' : ''}${
    experience.alias
  }`;

  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH);

  const ticketURL =
    experience.festival || experience.isFestival
      ? `/in/${experience.channel.urlSlug}/f/${experience.alias}`
      : `/tickets/${experience.channel.urlSlug}/${experience.alias}`;

  const now = new Date();
  const isHappeningNow =
    (experienceStartDate && experienceEndDate) &&
    moment(now).isBetween(moment(experienceStartDate), moment(experienceEndDate), 'minutes', '[)');
  const imageURL = experience.images[0]
    ? getResizedImageURL(experience.images[0].url || experience.images[0], IMAGE_SIZES.THUMBNAIL)
    : ImageNotFound;
  const informationTime = useMemo(
    () =>
      showTitle
        ? experienceStartDate && dateFormat(TIME_FORMATS.EVENT_TIME, experienceStartDate)
        : `${
            experienceStartDate
              ? dateFormat(TIME_FORMATS.TICKET_PAGE_DATE_AND_TIME, experienceStartDate)
              : ''
          }`,
    [experienceStartDate, showTitle]
  );

  useEffect(
    () =>
      setTimeout(() => {
        animationIndex === 12 ? setAnimationIndex(0) : setAnimationIndex((ctx) => ctx + 1);
      }, 150),
    []
  );

  const isRegistered = useMemo(
    () => rsvpedExperiences?.findIndex(({ id }) => id === experience?._id) !== -1,
    [rsvpedExperiences, experience]
  );

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (isRegistered) {
        return;
      } else if (!experience.festival) {
        browserHistory.push(ticketURL);
      } else if (experience.festivalUnlocked) {
        rsvpExperience({
          alias: experience.alias,
          channel: experience.channel.urlSlug,
          tickets: [],
        });

        return;
      } else if (experience.hasFestivalPass) {
        // TODO: change to upgrade logic
        browserHistory.push(
          `/in/${experience.festival.channel.urlSlug}/f/${experience.festival.alias}/checkout`
        );
      } else {
        browserHistory.push(
          `/in/${experience.festival.channel.urlSlug}/f/${experience.festival.alias}/checkout`
        );
      }
    },
    [experience, ticketURL, isRegistered, rsvpExperience]
  );

  const label = useMemo(() => {
    if (isRegistered) {
      return "I'm going";
    }

    if (experience.festival) {
      if (experience.festivalUnlocked) {
        return ' + Add to schedule';
      } else if (experience.hasFestivalPass) {
        return '+ Upgrade your pass';
      }
    }

    return 'Register »';
  }, [experience, isRegistered]);

  const irlTicket = useMemo(() => experience?.type === EXPERIENCES_TYPES.PHYSICAL && ticketPage, [
    experience,
    ticketPage,
  ]);
  const locationName = useMemo(() => {
    if (!irlTicket) {
      return `${capitalize(experience.location.platform)}`;
    }

    return `${capitalize(experience?.location?.platform?.replace(/_/g, ' '))}`;
  }, [irlTicket, experience]);

  const isEmpty = lastStartTime !== '';

  return (
    <div className="experience-list-component">
      {showTitle &&
      (lastStartTime === '' ||
        (experienceStartDate &&
          datesNotOnSameDay(experienceStartDate, new Date(lastStartTime)))) ? (
        <div className={`date-title ${isEmpty ? 'date-title--empty' : ''}`}>
          {!isEmpty && (
            <div className={`schedule-container `}>
              <div className={`schedule-title`} style={{ width: '100%' }}>
                {`Schedule - ${festivals ? 'Festivals' : 'Experiences'}`}
              </div>
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <DotContainer>
              <Dot isHappeningNow={isHappeningNow} animationIndex={animationIndex} />
              <Line />
            </DotContainer>
            <div className="date" style={{ paddingLeft: 10 }}>
              {isHappeningNow && `Happening now`}
              {!isHappeningNow && experienceStartDate
                ? getFullDate(new Date(experienceStartDate))
                : null}
            </div>
          </div>
        </div>
      ) : null}

      <ExperienceListItem
        isMobile={isMobile}
        $borderColor={$borderColor}
        onClick={() => browserHistory.push(experienceURL)}
        ticketPage={ticketPage}
      >
        <div className="image-container">
          <img className="thumb-image" src={imageURL} alt="thumb" />
          <div
            className={
              isLastItem ? 'last-circle' : `circle ${ticketPage ? 'circle--ticket-page' : ''}`
            }
          >
            {LogoIcon}
          </div>
        </div>
        <div className="information-container">
          <div className="location-and-time">
            {locationName} <span className="platform-slash">/</span> {experience && informationTime}
          </div>
          <div className="event-name">{experience.name}</div>
          {experience.artists?.length > 0 && (
            <div className="artist-name">{`with ${experience.artists[0].name}`}</div>
          )}
        </div>
        {ctaButton ? (
          ctaButton
        ) : (
          <button className="register-button" onClick={handleClick}>
            {label}
          </button>
        )}
      </ExperienceListItem>
    </div>
  );
};

const ExperienceListItem = styled.div`
  padding: 0 20px;
  ${({ isMobile, ticketPage }) =>
    isMobile
      ? `
      display: flex;
      max-width: 860px;
      box-sizing: border-box;
      margin: 20px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 104px;
      padding: 15px;
      gap: 10px;
  `
      : `
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: ${ticketPage ? 120 : 142}px auto 160px;
      width: 860px;
      height: 104px;
    `}
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
  align-items: center;
  border: 2px solid transparent;
  transition: border 0.25s;
  cursor: pointer;

  &:hover {
    border: 2px solid ${({ $borderColor }) => ($borderColor ? $borderColor : '#9b63f8')};
  }
`;

const Dot = styled.div`
  ${({ isHappeningNow, animationIndex }) =>
    isHappeningNow
      ? `
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-radius: 999px;
  background-color: #69d27c;
  z-index: 14;
  opacity: ${opacityValues[animationIndex]};
  transform: scale(${scaleValues[animationIndex]}) translateZ(0px);
  justify-self: center;
  align-self: center;
  `
      : `
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-radius: 999px;
  background-color: #999999;
  z-index: 14;
  justify-self: center;
  align-self: center;
  `}
`;

const Line = styled.div`
  &:before {
    content: '';
    position: absolute;
    border-left: 1px solid #ebebeb;
    top: 24px;
    z-index: 10;
    height: 77px;
    right: 24px;

    @media screen and ${MOBILE_MAX_WIDTH} {
      display: none;
    }
  }
`;

const DotContainer = styled.div`
  position: relative;
  display: flex;
  padding-left: 3px;
`;

ScheduledItem.propTypes = {
  experience: object,
  lastStartTime: string,
  isLastItem: bool,
};

const mapStateToProps = (state) => ({
  rsvpedExperiences: selectRSVPedExp(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      rsvpExperience,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledItem);
