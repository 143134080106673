import React from 'react';
import Button from '@material-ui/core/Button';
import { func, node, bool } from 'prop-types';
import styled from 'styled-components';

const SaveButton = ({ onClick, children = null, disabled = false }) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)`
  && {
    background-color: #9B63F8;
    border-radius: 26.5px;
    color: #ffffff;
    font-family: CircularStd Book;
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
    white-space: pre-wrap;
    width: 135px;
  }

  &&:hover {
    background-color: #864BE7;
  }

  &&:disabled {
    color: #fff;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

SaveButton.propTypes = {
  onClick: func.isRequired,
  children: node,
  disabled: bool,
}

export default SaveButton;
