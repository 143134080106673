import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Snackbar, CircularProgress, FlatButton, Divider } from 'material-ui';
import styled from 'styled-components';
import Gravatar from 'react-gravatar';
import find from 'lodash/find';

import FacebookAvatar from 'components/FacebookAvatar';
import * as actionsExperiences from 'actions/experiences';
import * as actionsAuth from 'actions/auth';
import { selectUser } from 'selectors/user';
import ExperienceDateAndLocation from 'components/ExperienceDateAndLocation';

const MemberButton = styled(FlatButton)`
  &:hover {
    backgroundColor: #FFFFFF !important;
  }

  &:hover span {
    color: #F57AA8 !important;
  }
`;

const styles = {

  modalSpinner: {
    display: 'block',
    zIndex: '1001',
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    filter: 'alpha(opacity=00)',
    background: 'linear-gradient(24deg, rgba(125, 152, 241, 0.9) 13%, rgba(229, 120, 162, 0.9) 100%)',
  },
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
  upgradeButton: {
    style: {
      backgroundColor: '#F57AA8',
      borderRadius: 20,
      height: 29,
      lineHeight: 0,
      marginTop: 3,
      marginRight: 30,
      padding: '0px 14px 1px 14px',
      minWidth: 81,
      width: 'auto',
    },
    memberStyle: {
      backgroundColor: '#7e9bf4',
      borderRadius: 20,
      height: 29,
      lineHeight: 0,
      marginTop: '18px',
      marginRight: 0,
      minWidth: 81,
      width: 'auto',
      paddingBottom: '2px',
    },
    label: {
      textTransform: 'none',
      color: '#FFFFFF',
      padding: '0px 0px 0px 1px',
      verticalAlign: 'none',
    },
  },
  userIcon: {
    borderRadius: '50%',
    backgroundColor: '#000000',
    marginRight: '10px',
  },
};

class ExperienceGuestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerModalOpened: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData() {
    const { loadExperience, loadGuestlist, user } = this.props;
    const { channel, alias } = this.props.params;
    loadExperience({ channel, alias });
    loadGuestlist({ channel, alias }).then((guestlist) => this.setState({ guestlist }));

    this.setState({ user });
  }

  closeErrorMessage = () => {
    this.setState({
      errorMessage: null,
    });
  };

  renderSnackbar() {
    return (
      <Snackbar
        open={!!this.state.errorMessage}
        message={this.state.errorMessage || ''}
        autoHideDuration={4000}
        onRequestClose={this.closeErrorMessage}
        bodyStyle={styles.snackbar}
      />
    );
  }


  renderSpinnerModal() {
    return (
      <div
        style={styles.modalSpinner}
      >
        <div style={{ textAlign: 'center', width: '100%', position: 'relative', top: '30%' }}>
          <CircularProgress size={250} thickness={12} color={'#fff'} />
        </div>
      </div>
    );
  }

  renderItem(guest) {
    const cart = [];
    if (guest && guest.rsvpTickets && guest.rsvpTickets.length > 0) {
      guest.rsvpTickets.map((ticket) => {
        const cartItem = find(cart, item => item.description.trim() === ticket.description.trim());

        const quantity = 1;
        const description = ticket.description;
        if (cartItem) {
          cartItem.quantity += 1;
        } else {
          cart.push({ description, quantity });
        }
        return cart;
      });
    }
    let tickets = '';
    if (cart.length > 0) {
      cart.map((ticket) => {
        if (ticket.quantity > 1) {
          tickets = `${tickets + ticket.description} + ${ticket.quantity - 1} | `;
        } else {
          tickets = `${tickets + ticket.description} | `;
        }
        return tickets;
      });
      tickets = tickets.substring(0, tickets.length - 2);
    }

    return (
      <div style={{ marginTop: '10px' }}>
        <Divider />
        <div style={{ marginTop: '20px' }}>
          <div style={{ float: 'left' }}>
            {guest.user && guest.user.facebook && guest.user.facebook.id && <FacebookAvatar style={{ width: '36px', height: '36px', marginRight: '10px' }} facebookId={guest.user.facebook.id} size="120" />}
            {(!guest.user.facebook || !guest.user.facebook.id) && guest.user.email && <Gravatar style={styles.userIcon} email={guest.user.email} size={36} />}
          </div>
          <div style={{ maxWidth: '400px', overflow: 'hidden' }} >
            <h4 style={{ fontWeight: 'bold', margin: '0px', lineHeight: '18px', fontSize: '16px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '75px' }}>{guest.user.firstname} {guest.user.lastname}</h4>
            <div style={{ opacity: '0.4', fontSize: '12px' }}>{guest.user.email ? `${guest.user.email} • ` : ''}{tickets || ''}</div>
          </div>
          <div style={{ float: 'right', marginTop: '-67px' }}>
            {guest.user && guest.user.subscription && guest.user.subscription === 'pro' &&
              <MemberButton
                rippleColor="transparent"
                label="Member"
                style={styles.upgradeButton.memberStyle}
                labelStyle={styles.upgradeButton.label}
              />
            }
          </div>
        </div>
      </div>
    );
  }

  renderGuestlist() {
    return (
      <div>
        {this.state.guestlist &&
            this.state.guestlist.map((guest) => {
              return this.renderItem(guest);
            })}
      </div>
    );
  }

  renderDesktop() {
    const { experience } = this.props;
    let image;
    if (experience && experience.images) {
      image = experience.images && experience.images.length ? experience.images[0].url : '';
    }

    return (
      <section className="page-container page-container-flex clearfix ticket-info-details">
        <div className="container container-inner clearfix">
          <div className="profile-content clearfix" style={{ maxWidth: '600px' }}>
            <div style={{ maxWidth: '600px' }}>
              <div style={{ overflow: 'hidden', float: 'left', marginRight: '18px', maxHeight: '150px', position: 'relative' }} >
                <img src={image} alt={experience.name} style={{ maxWidth: '180px' }} />
              </div>
              <ExperienceDateAndLocation
                startTime={experience.startTime}
                endTime={experience.endTime}
                location={experience.location}
              />
              <div>
                <h1 className="slide-title" style={{ lineHeight: '30px', fontSize: '30px', margin: '10px 0 0 0' }}>{experience.name}</h1>
              </div>
            </div>
          </div>
          {this.renderGuestlist()}
        </div>
      </section>
    );
  }

  renderMobile() {
    const { experience } = this.props;
    return (
      <section style={{ margin: '20px', marginTop: '-20px' }}>
        <div className="container container-inner clearfix">
          <div className="profile-content clearfix" style={{ maxWidth: '600px' }}>
            <div style={{ maxWidth: '600px' }}>
              <div>
                <h1 className="slide-title" style={{ lineHeight: '50px', fontSize: '20px' }}>{experience.name}</h1>
              </div>
              {this.renderGuestlist()}
            </div>
          </div>

        </div>
      </section>
    );
  }

  render() {
    const { experience } = this.props;

    return (
      <div>
        { experience &&
          <section>
            <MediaQuery query="(min-device-width: 1224px)">
              {this.renderDesktop()}
            </MediaQuery>
            <MediaQuery query="(max-device-width: 1224px)">
              {this.renderMobile()}
            </MediaQuery>
          </section>
        }

        {this.state.spinnerModalOpened && this.renderSpinnerModal()}
        {this.renderSnackbar()}
      </div>
    );
  }
}

ExperienceGuestList.propTypes = {
  params: PropTypes.object,
  experience: PropTypes.object,
  loadExperience: PropTypes.func.isRequired,
  loadGuestlist: PropTypes.func.isRequired,
  user: PropTypes.object
};

export default connect(state => ({
  experience: state.experiences.experience,
  user: selectUser(state)
}), {
  ...actionsExperiences,
  ...actionsAuth,
})(ExperienceGuestList);
