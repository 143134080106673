import React, { useMemo } from 'react';
import styled from 'styled-components';
import Close from 'images/close.png';

const RemovableList = ({ list, remove }) => {
  const listItems = useMemo(() => list, [list]);
  return (
    <Container onSubmit={() => {}}>
      {listItems.map((el) => {
        if (el) {
          return (<ListItem key={el.id}>
          {el.description}
          <RemoveButton
            onClick={(e) => {
              e.preventDefault();
              remove(el.id);
            }}
          >
            <RemoveIcon src={Close} alt="remove-icon" />
          </RemoveButton>
        </ListItem>)
        }

    })}
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px 5%;
`;

const ListItem = styled.div`
  height: 60px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
`;

const RemoveButton = styled.button`
    border: none;
    outline: none;
    width: 40px;
    height: 40px;
    background: transparent;
    cursor: pointer;
`;

const RemoveIcon = styled.img`
  height: 14px;
  width: 14px;
`;

export default RemovableList;
