import { dateIsBeforeNow, dateIsAfterNow } from 'utils/dates';
import { EXPERIENCES_STATUS } from 'appconstants/experiences';
import { createSelector } from 'reselect';
import { get } from 'lodash';
// import { ContactsOutlined } from '@material-ui/icons';

export const selectFestivals = (state) => {
  return  ([
    ...get(state, 'festivals.festivals'),
  ] || []);
};

// export const selectCMSExperiences = (state) => get(state, 'experiences.items.experiences') || [];

// export const selectExperiencesUsers = (state) => get(state, 'experiences.users', []) || [];
export const selectCurrentFestival = (state) => state.festivals.festivalInfo;
export const selectLoadingFestivals = (state) => state.festivals.loading;
// export const selectLoadingUserExperiences = (state) => state.experiences.loadingUserExperiences;
// export const selectLoadingChannelExperiences = (state) => state.experiences.loadingExperiences;
// export const selectNextPage = (state) => state.experiences?.upcomingNextPage || '';
// export const selectCanLoadMore = (state) => state.experiences?.upcomingExperiences?.length < state.experiences?.upcomingExperiencesCount;

// export const selectDraftExperiences = createSelector(selectCMSExperiences, (experiences) =>
//   experiences.filter((exp) => exp.status === EXPERIENCES_STATUS.DRAFT)
// );

// export const selectPublishedExperiences = createSelector(selectCMSExperiences, (experiences) =>
//   experiences.filter((exp) => exp.status === EXPERIENCES_STATUS.PUBLISHED)
// );

export const selectUpcomingFestivals = selectFestivals;

// export const selectUpcomingExperiencesCMS = createSelector(selectPublishedExperiences, (experiences) =>
//   experiences.filter((exp) => dateIsAfterNow(exp.endTime))
// );

// export const selectPastExperiences = createSelector(selectPublishedExperiences, (experiences) =>
//   experiences.filter((exp) => dateIsBeforeNow(exp.endTime))
// );

// export const selectReliveExperiences = (state) => get(state, 'experiences.reliveExperiences');

// export const selectFeaturedExperiences = (state) =>
//   get(state,'experiences.featuredExperiences').sort((a, b) => a.startTime - b.startTime).filter(exp => dateIsAfterNow(exp.endTime)).slice(0,4);

// createSelector(selectUpcomingExperiences, (published) => {
//   const featured = published.filter((exp) => exp.featured).slice(0, 4);
//   return featured.length ? featured : published.slice(0, 4);
// });
