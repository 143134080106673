import React, { PureComponent } from 'react';
import { object, string, bool, func } from 'prop-types';
import TGHoverButton from 'components/HoverButton';
import "./styles.css";
import { dateFormat } from 'utils/dates';

const styles = {
  rsvpButton: {
    style: {
      marginLeft: 6,
      height: '60px',
      borderRadius: '13px',
      float: 'right',
    },
    button: {
      backgroundImage: '#9764f0',
      backgroundColor: '#f07da8',
      cursor: 'pointer',
      width: '190px',
    },
    label: (rsvpLongText) => ({
      fontSize: rsvpLongText ? 'small' : '20px',
      color: '#fff',
      fontFamily: 'CircularStd Book',
      fontWeight: 'bold',
      lineHeight: '60px',
    }),
  },
  icon: {
    width: '30px',
    height: '30px',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  containerRight: {
    marginTop: 0,
    width: 300,
    float: 'right',
  },
  dialog: {
    width: '609px',
  },
  dialogBody: {
    padding: '5px',
    borderRadius: '4px',
  },
  dialogOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.541176)',
  },
};

class TopBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isHide: true };
    this.hideBar = this.hideBar.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.hideBar);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.hideBar);
  }
  hideBar() {
    const { isHide } = this.state;
    window.scrollY > 614 ?// eslint-disable-line
       isHide && this.setState({ isHide: false })
       :
       !isHide && this.setState({ isHide: true });

    this.prev = window.scrollY;
  }

  renderItem(experience) {
    const { rsvpLabel, rsvpDisable, rsvpVisible, onRSVP, rsvpLongText } = this.props;
    const startTime = experience.startTime ? dateFormat({hour: 'numeric', minutes: 'numeric', hour12: true}, experience.startTime) : '';
    const endTime = experience.endTime ? dateFormat({hour: 'numeric', minutes: 'numeric', hour12: true, timeZoneName: 'short'}, experience.endTime) : '';
    const imageUrl = experience.images && experience.images.length
    ? experience.images[0].url
    : '';

    return (
      <div className="exp-bottom animate-bar">
        <div className="left-container-bar">
          <div className="media-thumbnail">
            <img src={imageUrl} alt={experience.name} />
          </div>
          <div className="media-content">
            <div className="media-content-inner" style={{ lineHeight: '20px' }}>
              <h4 style={{ margin: 0 }}>{experience.name}</h4>
              <span>{experience.location ? experience.location.name : ''}</span>
              <span>{startTime} - {endTime} </span>
            </div>
          </div>
        </div>
        <div className="col-right-bar">
          <div className="content-right-bar right-content-btn slide-buttons">
            {rsvpVisible && <TGHoverButton
              id="btnRSVP"
              label={rsvpLabel}
              disabled={rsvpDisable}
              onClick={onRSVP}
              buttonStyle={styles.rsvpButton.button}
              labelStyle={styles.rsvpButton.label(rsvpLongText)}
              style={styles.rsvpButton.style}
              backgroundColor="#f07da8"
              backgroundColorHover="#9764f0"
            />}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { experience } = this.props;
    const classHide = this.state.isHide ? 'hide' : '';
    return (
      <div className={`topbar ${classHide}`}>
        {experience && this.renderItem(experience)}
      </div>
    );
  }
}

TopBar.propTypes = {
  experience: object,
  rsvpLabel: string,
  rsvpDisable: bool,
  rsvpVisible: bool,
  onLikeExperience: func,
  onUnlikeExperience: func,
  onRSVP: func,
};


export default TopBar;
