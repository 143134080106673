import { FETCH_TAGS }  from 'appconstants';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';

const initialState = {
  loading: false,
  customByChannel: {},
  food: [],
  drink: [],
  tag: [],
  category: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_TAGS.FETCH_ALL_TAGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TAGS.FETCH_ALL_TAGS_SUCCESS:
      {
        const allTags = get(action, 'response.response', []);
        const mainTags = groupBy(allTags.filter(tag => !tag.channel), 'type');
        const customByChannel = groupBy(allTags.filter(tag => !!tag.channel), 'channel');

        return {
          ...state,
          loading: false,
          ...mainTags,
          customByChannel
        };
      }
    case FETCH_TAGS.FETCH_ALL_TAGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
