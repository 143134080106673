import Description from './description';
import Dates from './dates';
import Tickets from './tickets';
import PageDesign from './pageDesign';
import Guidelines from './Guidelines'
import Publish from './publish'

const steps = {
  Description: {
    title: 'Tell the story of your camp.',
    component: Description,
  },
  Dates: {
    title: 'When it starts. When it ends.',
    component: Dates,
  },
  Tickets: {
    title: 'Your ticket information',
    component: Tickets,
  },
  Page: {
    title: 'Presentation matters! Make sure it looks beautiful.',
    component: PageDesign,
  },
  Guidelines: {
    title: "Let your guests know how to be present.",
    component: Guidelines
  },
  Publish: {
    title: "Make sure it's all good before you publish.",
    component: Publish
  }
};

export default steps
