import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Check from 'material-ui/svg-icons/navigation/check';

export default class FieldCheckbox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { input } = this.props;
    input.onChange(event.target.checked);
  }

  render() {
    const {
      input,
      label,
      parentClassName,
      labelClassName,
      checkClassName,
      ...custom
    } = this.props;
    const { value, ...inputProps } = input;

    return (
      <div className={`tg-checkbox ${value ? 'checked' : ''} ${parentClassName}`}>
        <input
          {...inputProps}
          {...custom}
          onChange={this.onChange}
          type="checkbox"
          checked={value}
        />
        <label htmlFor="Eslint" className={labelClassName} >{label}</label>
        <Check className={`check-icon ${checkClassName}`} color="#FFFFFF" />
      </div>
    );
  }
}

FieldCheckbox.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  parentClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  checkClassName: PropTypes.string,
};

FieldCheckbox.defaultProps = {
  parentClassName: "",
  labelClassName: "",
  checkClassName: ""
};
