import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import Footer from 'components/WizardComponents/Footer';
import ImageDropZone from 'components/WizardComponents/ImageDropzone';
import Snackbar from 'components/WizardComponents/Snackbar';
import ColorPicker from 'components/WizardComponents/ColorPicker';

import useFestivalInformation, { useUpdateFestival } from 'hooks/useFestivalInformation';

const PageDesign = ({
  params,
  nextStep,
  renderSteps,
}) => {
  const { isLoading, data } = useFestivalInformation(params.id);
  const mutation = useUpdateFestival(params.id, 'design');
  const pictureMutation = useUpdateFestival(params.id, 'image', true);
  const [image, setImage] = useState({});
  const [error, setError] = useState(null);
  const [colors, setColors] = useState({});
  const colorsMap = useMemo(() => [
    {
      key: 'actionButtonColor',
      label: 'Registration button',
      color: colors['actionButtonColor'],
    },
    {
      key: 'fontColor',
      label: 'Registration text',
      color: colors['fontColor'],
    },
    {
      key: 'descriptionColor',
      label: 'Desc text color',
      color: colors['descriptionColor']
    },
    {
      key: 'backgroundColor',
      label: 'Background color',
      color: colors['backgroundColor'],
    },
  ], [colors]);
  const heroPicture = data?.pageDesign?.heroPicture || ''
  const save = useCallback(() => {
    if (image.size && image !== heroPicture) {
      pictureMutation.mutate({ image }, true);
    }

    if (!image.preview) {
      setError('Please select an image for the festival');
      return;
    }

    const colorsToSend = {...colors};
    delete colorsToSend.heroPicture;

    mutation.mutate(colorsToSend);

    nextStep();
  }, [colors, nextStep, image, mutation, pictureMutation, heroPicture]);

  useEffect(() => {
    if (data && data.pageDesign) {
      setColors(data.pageDesign);

      setImage({
        preview: data.pageDesign.heroPicture,
      });
    }
  }, [data, heroPicture]);

  const setColor = useCallback((key, value) => {
    const newColors = {...colors};
    newColors[key] = value;
    setColors(newColors);
  }, [colors, setColors])

  const checkFile = useCallback(([image]) => {
    const reader = new window.FileReader();
    reader.onload = ({ target: { result }}) => {
      const imageSize = new Image();

      imageSize.onload = () => {
        const {
          width,
          height,
        } = imageSize;

        if (width < 1600) {
          setError('Image must be at least 1600px wide');
        } else if (height < 800) {
          setError('Image must be at least 800px high');
        } else {
          setImage(image);
        }
      }

      imageSize.src = result;
    };

    reader.readAsDataURL(image);
  }, [setImage, setError])

  if (isLoading) {
    return null;
  }

  const {
    updatedAt,
    published,
  } = data;

  return (
    <PageDesignWrapper>
      {renderSteps(save)}
      <Snackbar error={error} onClose={() => setError(null)} />
      <PageDesignBox
        preview={image && image.preview}
      >
        <ImageDropZone
          onSelect={checkFile}
          preview={image && image.preview}
        />
      </PageDesignBox>
      <PageDesignBox
        flexWrap="wrap"
        justifyContent="flex-start"
        marginTop={17}
      >
        {
          colorsMap.map(({key, label, color }) => (
            <ColorPicker
              key={key}
              label={label}
              colorKey={key}
              color={color}
              onChange={setColor}
            />
          ))
        }
      </PageDesignBox>
      <Footer
        onNextStep={save}
        status={published}
        lastSaved={updatedAt}
        saving={isLoading}
      />
    </PageDesignWrapper>
  )
};

const PageDesignWrapper = styled.div``;



const PageDesignBox = styled.div`
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.04);
  display: flex;
  position: relative;
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  padding: 20px 22px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`;

export default PageDesign;
