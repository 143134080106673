/* global _ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GridList, CircularProgress, Subheader } from 'material-ui';

export default class TGGridList extends Component {
  static propTypes = {
    renderItem: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    items: PropTypes.array,
    header: PropTypes.string,
    headers: PropTypes.array,
    headerField: PropTypes.string,
    cols: PropTypes.number,
  }

  createGrids(items, headers) {
    const { headerField } = this.props;

    // create one grid per header
    const grids = _.map(headers, (header) => {
      return {
        header: header.name || header,
        items: _.filter(items, (item) => {
          return item[headerField] === (header.id || header);
        }),
      };
    });
    return _.filter(grids, 'items.length');
  }

  renderLoading() {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  renderEmpty() {
    return (
      <div />
    );
  }

  renderGrids(items, headers) {
    const grids = this.createGrids(items, headers);
    return (
      <div>
        {_.map(grids, (grid) => {
          return this.renderGrid(grid.items, grid.header);
        })}
      </div>
    );
  }

  renderGrid(items, header) {
    const { renderItem, cols } = this.props;
    return (
      <div key={header}>
        <Subheader>{header}</Subheader>
        <GridList
          cols={cols || 2}
          cellHeight={110}
        >
          {_.map(items, (item) => {
            return renderItem(item);
          })}
        </GridList>
      </div>
    );
  }

  render() {
    const { loading, header, headers, items } = this.props;
    if (loading) {
      // loading
      return this.renderLoading();
    } else if (!items || !items.length) {
      // empty
      return this.renderEmpty();
    } else if (headers) {
      // render multiple grids
      return this.renderGrids(items, headers);
    }
      // render grid
    return this.renderGrid(items, header);
  }
}
