import React from "react";
import { func, object } from "prop-types";

import CancelIcon from "components/common/CancelIcon";
import BackArrowIcon from "components/common/BackArrowIcon";
import { formatDateLapse } from "utils/dates";

import "./styles.css";

const MobileHeader = ({
  onClose,
  onBack,
  experience: {
    images,
    name,
    startDate,
    endDate,
    channel: { name: channelName },
  },
}) => (
  <div className="ticket-header-mob">
    <div
      className="ticket-header-image-mob"
      style={{ backgroundImage: `url(${images[0].url})` }}
    >
      <button className="ticket-header-icon-wrapper-mob" onClick={onBack || onClose}>
        {onBack ? (
          <BackArrowIcon className="ticket-header-icon-mob" />
        ) : (
          <CancelIcon
            height={16}
            width={16}
            className="ticket-header-icon-mob"
          />
        )}
      </button>
    </div>
    <div className="ticket-header-info-mob">
      <h3 className="ticket-header-info-name-mob">{name}</h3>
      <p className="ticket-header-info-channel-mob">{`by ${channelName}`}</p>
      <p className="ticket-header-info-date-mob">
        {formatDateLapse(startDate, endDate)}
      </p>
    </div>
  </div>
);


MobileHeader.propTypes = {
  onClose: func.isRequired,
  onBack: func,
  experience: object.isRequired

};

export default MobileHeader;
