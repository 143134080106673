import React from 'react';
import PropTypes from 'prop-types';
import Intercom from 'react-intercom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaQuery from 'react-responsive';
import { browserHistory } from 'react-router';
import AppWrapper from 'components/AppWrapper';
// import AdminHeader from 'components/AdminHeader';
import { changeUserSubscription, subscribeUserToPlan } from 'actions/auth';
import { selectUser } from 'selectors/user';
import { APP_THEMES } from "appconstants/colors";
import NavigationHeader from 'components/NavigationHeader';

const App = ({ children, user, location }) => { // eslint-disable-line react/prefer-stateless-function
  const intercomUser = {
    user_id: user.email,
    email: user.email,
    name: user.name,
  };

  const goToExperienceProfile = (experienceId) => {
    browserHistory.push(`/in/${experienceId}`);
  };

  return (
    <div>
      <AppWrapper>
        <MediaQuery query="(min-device-width: 1224px)">
          <Intercom appID="xch7a1rk" {...intercomUser} />
          <div className="degradeDiv" id="degradeDiv" />
          <div className="blurDiv" id="blurredDiv" />
          <NavigationHeader
            location={location}
            user={user}
            theme={APP_THEMES.TRANSPARENT}
          />
        </MediaQuery>
        {React.cloneElement(children, {
          goToExperienceProfile,
          user,
        })}
      </AppWrapper>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = state => ({user: selectUser(state)});
const mapDispatchToProps = dispatch => bindActionCreators({ changeUserSubscription, subscribeUserToPlan }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
