// CheckoutForm.js
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectStripe, CardElement } from 'react-stripe-elements';

const genericErrorMessage = 'There was an error processing your payment. Please try again later';
class CheckoutForm extends React.Component {
  state = {
    errorMessage: '',
  };

  handleSubmit = async (ev) => {
    ev.preventDefault();
    this.setState({ disabledButton: 'disabled', errorMessage: '' });

    // Use Elements to get a reference to the Card Element mounted somewhere
    // in your <Elements> tree. Elements will know how to find your Card Element
    // because only one is allowed.
    // See our getElement documentation for more:
    // https://stripe.com/docs/stripe-js/reference#elements-get-element
    const cardElement = this.props.elements.getElement('card');

    // See our confirmCardPayment documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
    try {
      const { error, paymentIntent } = await this.props.stripe.confirmCardPayment(
        this.props.clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );
      this.setState({ disabledButton: '' });

      if (paymentIntent && paymentIntent.status === 'succeeded') {
        this.props.showGuidelines();
      }
      else if (error) {
        this.setState({
          errorMessage: error.type !== 'validation_error' ? genericErrorMessage : error.message,
        });
      }
    } catch (e) {
      this.setState({
        disabledButton: '',
        errorMessage: genericErrorMessage,
      });
    }
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  render() {
    const { className, total } = this.props;
    const { disabledButton, errorMessage } = this.state;
    return (
      <div className={className}>
        <form onSubmit={this.handleSubmit}>
          <div className={className}>
            <span>
              Card details
              <CardElement onChange={this.handleChange} />
            </span>
          </div>
          <button disabled={disabledButton}>{`Pay $${total} `}</button>
        </form>
        <div class="error" role="alert">
          {errorMessage}
        </div>
      </div>
    );
  }
}
CheckoutForm.propTypes = {
  className: PropTypes.string,
  showGuidelines: PropTypes.func,
  total: PropTypes.number,
};

export default injectStripe(styled(CheckoutForm)`
  max-width: 500px;
  margin: auto;
  * {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  button {
    display: block;
    width: 100%;
    height: 37px;
    background-color: #6772e5;
    border-radius: 2px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    appearance: none;
    outline: none;
    border-style: none;
    letter-spacing: 0.025em;
    text-transform: uppercase;
  }

  button:disabled {
    background-color: #999999;
  }

  span {
    color: #6b7c93;
    letter-spacing: 0.025em;
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    min-width: 200px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  .error {
    color: #c23d4b;
    margin: 5px 0;
  }
`);
