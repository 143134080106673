// MyStoreCheckout.js
import React from 'react';
import {Elements} from 'react-stripe-elements';

import CheckoutForm from './CheckoutForm';

const StripeWrapper = (props) => {
  return (
    <Elements>
      <CheckoutForm {...props} />
    </Elements>
  );
};

export default StripeWrapper;
