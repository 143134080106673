import React, { Component } from "react";
import { bool, func, array } from "prop-types";
import { connect } from "react-redux";
import cn from "classnames";
import { browserHistory } from "react-router";
import { selectChannels } from 'selectors/channels';

import presenceLogo from "images/presence-logo.png";
import CancelIcon from "components/common/CancelIcon";
import routes from "appconstants/routes";

import "./styles.css";

class SideBar extends Component {

  onChannelSelect = (urlSlug) => () => {
    browserHistory.push(`/in/${urlSlug}`);
    this.props.onClose();
  };

  onLogoClick = () => {
    browserHistory.push(routes.mainPage);
    this.props.onClose();
  }

  render() {
    const { open, onClose, channels } = this.props;

    if (!channels) return null;

    return (
      <div className={cn("sidebar-container", { "sidebar-in": open })}>
        <div className="sidebar-cancel">
          <button onClick={onClose}>
            <CancelIcon />
          </button>
        </div>
        <div className="sidebar-content">
          <div className="sidebar-logo">
            <button onClick={this.onLogoClick}>
              <img src={presenceLogo} alt="PESENCE" />
            </button>
          </div>
          <div>
            <div className="sidebar-title">
              <span>Browse channels</span>
            </div>
            {channels.map(({ id, name, urlSlug }) => (
              <div key={id} className="sidebar-channel-container">
                <button onClick={this.onChannelSelect(urlSlug)}>{name}</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {
  open: bool,
  onClose: func.isRequired,
  channels: array.isRequired
};

const mapStateToProps = (state) => ({
  channels: selectChannels(state)
});

export default connect(mapStateToProps)(SideBar);
