import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { findLast, compact, concat, filter } from 'lodash';
import Snackbar from 'material-ui/Snackbar';

import { FILE_UPLOADER_TYPES } from 'appconstants';

import FileDropZone from 'components/FileDropZone';
import FilePreview from 'components/FilePreview';
import './style.css';

import { ERROR_MESSAGES } from './constants';

const styles = {
  snackbar: {
    body: {
      backgroundColor: 'rgba(255, 0, 13, 0.88)',
    },
    root: {
      top: 0,
      bottom: 'auto',
    },
  },
};

const FileUploader = ({
  input: { value, onChange },
  minWidth,
  minHeight,
  uploaderType,
  meta,
  input,
  large,
  photo,
}) => {
  const [openSnackbarValue, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const openSnackbar = () => {
    setOpenSnackbar(true);
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  /* eslint no-param-reassign: ["error", { "props": false }]*/
  const addFiles = (acceptedFiles, isDefault) => {
    const reader = new window.FileReader();
    const file = acceptedFiles[0];
    file.hash = `img${Math.floor(Math.random() * 1000000)}`;
    reader.onload = (e) => {
      file.data = e.target.result;

      if (isDefault) {
        file.default = true;
      }

      imageVerifySize(file, (result) => {
        if (result === 2) {
          onChange(concat(value, [file]));
        }
      });
    };

    reader.readAsDataURL(file);
  };

  const imageVerifySize = (file, callback) => {
    if (file.size > 10000000) {
      setErrorMessage(ERROR_MESSAGES.LARGE);
      openSnackbar();
      callback(1);
      return;
    }
    const img = new Image(); // eslint-disable-line

    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if (width < 800 && height < 800 && uploaderType !== FILE_UPLOADER_TYPES.LOGO) {
        setErrorMessage(ERROR_MESSAGES.SMALL);
        openSnackbar();
        callback(1);
      } else if (minWidth && width < minWidth) {
        setErrorMessage(ERROR_MESSAGES.width(minWidth));
        openSnackbar();
        callback(1);
      } else if (minHeight && height < minHeight) {
        setErrorMessage(ERROR_MESSAGES.height(minHeight));
        openSnackbar();
        callback(1);
      } else {
        setErrorMessage('');
        closeSnackbar();
        callback(2);
      }
    };
  };
  const findLastFile = findLast(input.value, 'default');
  const onlyFile = findLastFile || (input.value.length ? input.value : undefined);
  // console.log('uploaderType', uploaderType);
  // console.log('findLastFile', findLastFile);
  // console.log('onlyFile', onlyFile);
  // console.log('photo', photo);
  // console.log('input', input); 
  // console.log('inputValue', input.value)
  // console.log('meta', meta);

  return (
    <div className="file-uploader">
      {onlyFile || photo ? (
        <FilePreview
          files={[onlyFile]}
          meta={meta}
          input={input}
          large={large}
          photo={photo || onlyFile.url}
          type={uploaderType}
        />
      ) : (
        <FileDropZone
          meta={meta}
          minHeight={minHeight}
          minWidth={minWidth}
          addFiles={addFiles}
          type={uploaderType}
        />
      )}
      <Snackbar
        open={openSnackbarValue}
        message={errorMessage}
        autoHideDuration={4000}
        onRequestClose={closeSnackbar}
        bodyStyle={styles.snackbar.body}
        style={{
          ...styles.snackbar.root,
          left: (window.innerWidth - 288) / 2,
          transform: openSnackbarValue ? 'translate3d(0, 0, 0)' : 'translate3d(0, -50px, 0)',
        }}
      />
    </div>
  );
};

FileUploader.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  minWidth: PropTypes.number,
  uploaderType: PropTypes.oneOf([
    FILE_UPLOADER_TYPES.SQUARE,
    FILE_UPLOADER_TYPES.LOGO,
    FILE_UPLOADER_TYPES.INLINE,
    FILE_UPLOADER_TYPES.MULTIPLE,
  ]),
};

export default FileUploader;
