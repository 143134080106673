export const USER_TYPES = {
  HOST: 'host',
  GUEST: 'guest',
  CREATOR: 'creator',
  PRODUCER: 'producer',
  ADMIN: 'admin'
};

export const BASIC_SETTINGS_TABS = {
  ACCOUNT: 'Account',
  NOTIFICATIONS: 'Notifications',
  PASSWORD: 'Password',
};

export const CREATOR_SETTINGS_TABS = {
  ACCOUNT: 'Account',
  PAYMENT: 'Payment',
  NOTIFICATIONS: 'Notifications',
  PASSWORD: 'Password',
};

export const PRODUCER_SETTINGS_TABS = {
  ACCOUNT: 'Account',
  PAYMENT: 'Payment',
  CHANNELS: 'Settings',
  NOTIFICATIONS: 'Notifications',
  PASSWORD: 'Password',
  TEAM: 'Team',
};

export const OWNER_SETTINGS_TABS = {
  ACCOUNT: 'Account',
  CHANNELS: 'Settings',
  PAYMENT: 'Payment',
  NOTIFICATIONS: 'Notifications',
  PASSWORD: 'Password',
  SPLIT: 'Split',
  TEAM: 'Team',
};

export const SETTINGS_TABS = {
  ACCOUNT: {
    CREATOR: [
      OWNER_SETTINGS_TABS.ACCOUNT,
      OWNER_SETTINGS_TABS.PAYMENT,
      OWNER_SETTINGS_TABS.NOTIFICATIONS,
      OWNER_SETTINGS_TABS.PASSWORD,
    ],
    GUEST: [
      OWNER_SETTINGS_TABS.ACCOUNT,
      OWNER_SETTINGS_TABS.NOTIFICATIONS,
      OWNER_SETTINGS_TABS.PASSWORD,
    ],
  },
  CHANNEL: {
    OWNER: [
      OWNER_SETTINGS_TABS.CHANNELS,
      OWNER_SETTINGS_TABS.SPLIT,
      OWNER_SETTINGS_TABS.TEAM,
    ],
    PRODUCER: [
      OWNER_SETTINGS_TABS.CHANNELS,
      OWNER_SETTINGS_TABS.TEAM,
    ],
  },
}

export const LOCATION = [
  {
    id: 'art_center',
    description: 'Art center',
  },
  {
    id: 'museum',
    description: 'Museum',
  },
  {
    id: 'private_residence',
    description: 'Private residence',
  },
  {
    id: 'theatre',
    description: 'Theatre',
  },
  {
    id: 'co-living_space',
    description: 'Co-living space',
  },
  {
    id: 'spiritual_center',
    description: 'Spiritual center',
  },
  {
    id: 'co-working_space',
    description: 'Co-working space',
  },
  {
    id: 'nature',
    description: 'Nature',
  },
  {
    id: 'apartment',
    description: 'Apartment',
  },
  {
    id: 'backyard',
    description: 'Backyard',
  }];

export const PLAYERS = {
  YOUTUBE: "Youtube",
  VIMEO: "Vimeo",
  OTHER: "Other",
};

export const VIRTUAL_LOCALIZATION = [
  {
    id: 'Facebook LIVE',
    description: 'Facebook LIVE',
  },
  {
    id: 'Instagram',
    description: 'Instagram',
  },
  {
    id: 'NOWHERE',
    description: 'NOWHERE',
  },
  {
    id: 'Topia',
    description: 'Topia',
  },
  {
    id: 'Twitch',
    description: 'Twitch',
  },
  {
    id: PLAYERS.VIMEO,
    description: 'Vimeo',
  },
  {
    id: PLAYERS.YOUTUBE,
    description: 'YouTube',
  },
  {
    id: 'Zoom',
    description: 'Zoom',
  },
  {
    id: PLAYERS.OTHER,
    description: 'Other',
  },
];

export const EXPERIENCES_TYPES = {
  PHYSICAL: 'physical',
  VIRTUAL: 'virtual',
};

export const TYPES_SELECTOR = {
  FOOD: "food",
  DRINK: "drinks",
  TAG: 'tag',
  CATEGORIES: 'categories'

};

export const ADMIN_PATHS = ['/experiences'];
export const NO_CHANNEL_MENU_PATHS = ['/create-experience', '/select-channel', '/experiences/', '/in/:channel/:alias/dashboard'];
export const STATIC_HEADER_PATHS = ['/create-profile', '/create-channel/:id'];

export const VALIDATION = {
  NAME: 2,
  DESCRIPTION: 2,
  PLATFORM: 2,
  URL: 3,
  ADDRESS: 3,
  COUNTRY: 2,
  ZIP: 3,
  QUOTE: 2,
  MIN_ABOUT: 10,
  MAX_ABOUT: 400,
  PASSWORD_MIN: 6,
  PASSWORD_MAX: 20,
};

export const TICKET_TYPES = {
  FREE_TICKET: "Free",
  DONATION_TICKET: "Donation",
  PAID_TICKET: "General Admission",
};

export const TIME_OUT = {
  DEFAULT: 3000,
};


export const USER_REQUEST = {
  SUBMIT_CREATEUSER_REQUEST: 'SUBMIT_CREATEUSER_REQUEST',
  SUBMIT_CREATEUSER_SUCCESS: 'SUBMIT_CREATEUSER_SUCCESS',
  SUBMIT_CREATEUSER_FAILURE: 'SUBMIT_CREATEUSER_FAILURE',
  LOGOUT: 'LOGOUT'
};

export const COMPONENT_PAGES = {
  DESCRIPTION_PAGE: "DESCRIPTION_PAGE",
  DATE_AND_TIME_PAGE: "DATE_AND_TIME_PAGE",
  TICKETS_PAGE: "TICKETS_PAGE",
  LOCALIZATION_PAGE: "LOCALIZATION_PAGE",
  ADD_ARTISTS_PAGE: "ADD_ARTISTS_PAGE",
  ADD_FOOD_DRINKS_PAGE: "ADD_FOOD_DRINKS_PAGE",
  ADD_GUIDELINE_PAGE: "ADD_GUIDELINE_PAGE",
  PRESENTATION_PAGE: "PRESENTATION_PAGE",
  SAFETY_AGREEMENTS_PAGE: "SAFETY_AGREEMENTS_PAGE",
  CONFIRM_PAGE: "CONFIRM_PAGE",
  VIRTUAL_LOCALIZATION_PAGE: "VIRTUAL_LOCALIZATION_PAGE",
  CONFIRM_VIRTUAL_PAGE: "CONFIRM_VIRTUAL_PAGE",
};

export const USER_NOTIFICATIONS = {
  SUBMIT_NOTIFICATIONS_REQUEST: 'SUBMIT_NOTIFICATIONS_REQUEST',
  SUBMIT_NOTIFICATIONS_SUCCESS: 'SUBMIT_NOTIFICATIONS_SUCCESS',
  SUBMIT_NOTIFICATIONS_FAILURE: 'SUBMIT_NOTIFICATIONS_FAILURE',
};

export const USER_NEW_PASSWORD = {
  SUBMIT_NEW_PASSWORD_REQUEST: 'SUBMIT_NEW_PASSWORD_REQUEST',
  SUBMIT_NEW_PASSWORD_SUCCESS: 'SUBMIT_NEW_PASSWORD_SUCCESS',
  SUBMIT_NEW_PASSWORD_FAILURE: 'SUBMIT_NEW_PASSWORD_FAILURE',
};

export const FETCH_PROFILE = {
  SAVE_PROFILE_REQUEST: 'SAVE_PROFILE_REQUEST',
  SAVE_PROFILE_SUCCESS: 'SAVE_PROFILE_SUCCESS',
  SAVE_PROFILE_FAILURE: 'SAVE_PROFILE_FAILURE',
};

export const STRIPE_ACTIONS = {
  OAUTH_URL_REQUEST: 'GET_STRIPE_OAUTH_URL_REQUEST',
  OAUTH_URL_SUCCESS: 'GET_STRIPE_OAUTH_URL_SUCCESS',
  OAUTH_URL_FAILURE: 'GET_STRIPE_OAUTH_URL_FAILURE',
  LINK_REQUEST: 'GET_STRIPE_LINK_REQUEST',
  LINK_SUCCESS: 'GET_STRIPE_LINK_SUCCESS',
  LINK_FAILURE: 'GET_STRIPE_LINK_FAILURE',
};

export const FETCH_CHANNEL = {
  SAVE_CHANNEL_REQUEST: 'SAVE_CHANNEL_REQUEST',
  SAVE_CHANNEL_SUCCESS: 'SAVE_CHANNEL_SUCCESS',
  SAVE_CHANNEL_FAILURE: 'SAVE_CHANNEL_FAILURE',
  FETCH_CHANNELS_REQUEST: 'FETCH_CHANNELS_REQUEST',
  FETCH_CHANNELS_SUCCESS: 'FETCH_CHANNELS_SUCCESS',
  FETCH_CHANNELS_FAILURE: 'FETCH_CHANNELS_FAILURE',
};

export const GET_SUBCATEGORIES = {
  GET_SUBCATEGORIES_REQUEST: 'GET_SUBCATEGORIES_REQUEST',
  GET_SUBCATEGORIES_SUCCESS: 'GET_SUBCATEGORIES_SUCCESS',
  GET_SUBCATEGORIES_FAILURE: 'GET_SUBCATEGORIES_FAILURE',
}

export const GET_REV_SHARE = {
  GET_REV_SHARE_REQUEST: 'GET_REV_SHARE_REQUEST',
  GET_REV_SHARE_SUCCESS: 'GET_REV_SHARE_SUCCESS',
  GET_REV_SHARE_FAILURE: 'GET_REV_SHARE_FAILURE',
}

export const POST_REV_SHARE = {
  POST_REV_SHARE_REQUEST: 'POST_REV_SHARE_REQUEST',
  POST_REV_SHARE_SUCCESS: 'POST_REV_SHARE_SUCCESS',
  POST_REV_SHARE_FAILURE: 'POST_REV_SHARE_FAILURE',
}

export const CREATE_SUBCATEGORY = {
  CREATE_SUBCATEGORY_REQUEST: 'CREATE_SUBCATEGORY_REQUEST',
  CREATE_SUBCATEGORY_SUCCESS: 'CREATE_SUBCATEGORY_SUCCESS',
  CREATE_SUBCATEGORY_FAILURE: 'CREATE_SUBCATEGORY_FAILURE',
}

export const DELETE_SUBCATEGORY = {
  DELETE_SUBCATEGORY_REQUEST: 'DELETE_SUBCATEGORY_REQUEST',
  DELETE_SUBCATEGORY_SUCCESS: 'DELETE_SUBCATEGORY_SUCCESS',
  DELETE_SUBCATEGORY_FAILURE: 'DELETE_SUBCATEGORY_FAILURE',
}

export const GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES = {
  GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_REQUEST: 'GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_REQUEST',
  GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_SUCCESS: 'GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_SUCCESS',
  GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_FAILURE: 'GET_EXPERIENCE_AVAILABLE_SUBCATEGORIES_FAILURE',
}

export const CREATE_NEW_EXPERIENCE = {
  CREATE_NEW_EXPERIENCE_REQUEST: 'CREATE_NEW_EXPERIENCE_REQUEST',
  CREATE_NEW_EXPERIENCE_SUCCESS: 'CREATE_NEW_EXPERIENCE_SUCCESS',
  CREATE_NEW_EXPERIENCE_FAILURE: 'CREATE_NEW_EXPERIENCE_FAILURE',
};

export const SELECT_CURRENT_CHANNEL = 'SELECT_CURRENT_CHANNEL';

export const FETCH_EXPERIENCES = {
  FETCH_RELIVE_EXPERIENCES_REQUEST: 'FETCH_RELIVE_EXPERIENCES_REQUEST',
  FETCH_RELIVE_EXPERIENCES_SUCCESS: 'FETCH_RELIVE_EXPERIENCES_SUCCESS',
  FETCH_RELIVE_EXPERIENCES_FAILURE: 'FETCH_RELIVE_EXPERIENCES_FAILURE',
  FETCH_FEATURED_EXPERIENCES_REQUEST: 'FETCH_FEATURED_EXPERIENCES_REQUEST',
  FETCH_FEATURED_EXPERIENCES_SUCCESS: 'FETCH_FEATURED_EXPERIENCES_SUCCESS',
  FETCH_FEATURED_EXPERIENCES_FAILURE: 'FETCH_FEATURED_EXPERIENCES_FAILURE',
  FETCH_EXPERIENCES_REQUEST: 'FETCH_EXPERIENCES_REQUEST',
  FETCH_EXPERIENCES_SUCCESS: 'FETCH_EXPERIENCES_SUCCESS',
  FETCH_EXPERIENCES_FAILURE: 'FETCH_EXPERIENCES_FAILURE',
  FETCH_CMS_EXPERIENCES_REQUEST: 'FETCH_CMS_EXPERIENCES_REQUEST',
  FETCH_CMS_EXPERIENCES_SUCCESS: 'FETCH_CMS_EXPERIENCES_SUCCESS',
  FETCH_CMS_EXPERIENCES_FAILURE: 'FETCH_CMS_EXPERIENCES_FAILURE',
  FETCH_USER_EXPERIENCES_REQUEST: 'FETCH_USER_EXPERIENCES_REQUEST',
  FETCH_USER_EXPERIENCES_SUCCESS: 'FETCH_USER_EXPERIENCES_SUCCESS',
  FETCH_USER_EXPERIENCES_FAILURE: 'FETCH_USER_EXPERIENCES_FAILURE',
  LOAD_EXPERIENCE_REQUEST: 'LOAD_EXPERIENCE_REQUEST',
  LOAD_EXPERIENCE_SUCCESS: 'LOAD_EXPERIENCE_SUCCESS',
  LOAD_EXPERIENCE_FAILURE: 'LOAD_EXPERIENCE_FAILURE',
  LOAD_EXPERIENCE_FINISH: 'LOAD_EXPERIENCE_FINISH',
  LOAD_CMS_EXPERIENCE_REQUEST: 'LOAD_CMS_EXPERIENCE_REQUEST',
  LOAD_CMS_EXPERIENCE_SUCCESS: 'LOAD_CMS_EXPERIENCE_SUCCESS',
  LOAD_CMS_EXPERIENCE_FAILURE: 'LOAD_CMS_EXPERIENCE_FAILURE',
  LOAD_CMS_EXPERIENCE_FINISH: 'LOAD_CMS_EXPERIENCE_FINISH',
  SAVE_EXPERIENCE_REQUEST: 'SAVE_EXPERIENCE_REQUEST',
  SAVE_EXPERIENCE_SUCCESS: 'SAVE_EXPERIENCE_SUCCESS',
  SAVE_EXPERIENCE_FAILURE: 'SAVE_EXPERIENCE_FAILURE',
  PUBLISH_EXPERIENCE_REQUEST: 'PUBLISH_EXPERIENCE_REQUEST',
  PUBLISH_EXPERIENCE_SUCCESS: 'PUBLISH_EXPERIENCE_SUCCESS',
  PUBLISH_EXPERIENCE_FAILURE: 'PUBLISH_EXPERIENCE_FAILURE',
  UNPUBLISH_EXPERIENCE_REQUEST: 'UNPUBLISH_EXPERIENCE_REQUEST',
  UNPUBLISH_EXPERIENCE_SUCCESS: 'UNPUBLISH_EXPERIENCE_SUCCESS',
  UNPUBLISH_EXPERIENCE_FAILURE: 'UNPUBLISH_EXPERIENCE_FAILURE',
  GUESTLIST_EXPERIENCE_REQUEST: 'GUESTLIST_EXPERIENCE_REQUEST',
  GUESTLIST_EXPERIENCE_SUCCESS: 'GUESTLIST_EXPERIENCE_SUCCESS',
  GUESTLIST_EXPERIENCE_FAILURE: 'GUESTLIST_EXPERIENCE_FAILURE',
  RELIVE_GUESTLIST_EXPERIENCE_REQUEST: 'RELIVE_GUESTLIST_EXPERIENCE_REQUEST',
  RELIVE_GUESTLIST_EXPERIENCE_SUCCESS: 'RELIVE_GUESTLIST_EXPERIENCE_SUCCESS',
  RELIVE_GUESTLIST_EXPERIENCE_FAILURE: 'RELIVE_GUESTLIST_EXPERIENCE_FAILURE',
  RSVP_EXPERIENCE_REQUEST: 'RSVP_EXPERIENCE_REQUEST',
  RSVP_EXPERIENCE_SUCCESS: 'RSVP_EXPERIENCE_SUCCESS',
  RSVP_EXPERIENCE_FAILURE: 'RSVP_EXPERIENCE_FAILURE',
  SAVE_CHANNEL_EXPERIENCE_REQUEST: 'SAVE_CHANNEL_EXPERIENCE_REQUEST',
  SAVE_CHANNEL_EXPERIENCE_SUCCESS: 'SAVE_CHANNEL_EXPERIENCE_SUCCESS',
  SAVE_CHANNEL_EXPERIENCE_FAILURE: 'SAVE_CHANNEL_EXPERIENCE_FAILURE',
  SAVE_EXPERIENCE_RELIVE_REQUEST: 'SAVE_EXPERIENCE_RELIVE_REQUEST',
  SAVE_EXPERIENCE_RELIVE_SUCCESS: 'SAVE_EXPERIENCE_RELIVE_SUCCESS',
  SAVE_EXPERIENCE_RELIVE_FAILURE: 'SAVE_EXPERIENCE_RELIVE_FAILURE',
};

export const USERS_ACTIONS_TYPES = {
  USERS_RELIVE_REQUEST: "USERS_RELIVE_REQUEST",
  USERS_RELIVE_SUCCESS: "USERS_RELIVE_SUCCESS",
  USERS_RELIVE_FAILURE: "USERS_RELIVE_FAILURE",
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",
};

export const GET_CHANNEL_TEAM = {
  GET_CHANNEL_TEAM_REQUEST: 'GET_CHANNEL_TEAM_REQUEST',
  GET_CHANNEL_TEAM_SUCCESS: 'GET_CHANNEL_TEAM_SUCCESS',
  GET_CHANNEL_TEAM_FAILURE: 'GET_CHANNEL_TEAM_FAILURE',
}

export const FETCH_TAGS = {
  FETCH_ALL_TAGS_REQUEST: 'FETCH_ALL_TAGS_REQUEST',
  FETCH_ALL_TAGS_SUCCESS: 'FETCH_ALL_TAGS_SUCCESS',
  FETCH_ALL_TAGS_FAILURE: 'FETCH_ALL_TAGS_FAILURE'
};

export const LIKE_ACTIONS = {
  LIKE_REQUEST: 'LIKE_REQUEST',
  LIKE_SUCCESS: 'LIKE_SUCCESS',
  LIKE_FAILURE: 'LIKE_FAILURE',
  UNLIKE_REQUEST: 'UNLIKE_REQUEST',
  UNLIKE_SUCCESS: 'UNLIKE_SUCCESS',
  UNLIKE_FAILURE: 'UNLIKE_FAILURE'
};

export const SUBMIT_FORGOT_PASSWORD = {
  SUBMIT_FORGOT_PASSWORD_REQUEST: 'SUBMIT_FORGOT_PASSWORD_REQUEST',
  SUBMIT_FORGOT_PASSWORD_SUCCESS: 'SUBMIT_FORGOT_PASSWORD_SUCCESS',
  SUBMIT_FORGOT_PASSWORD_FAILURE: 'SUBMIT_FORGOT_PASSWORD_FAILURE',
};

export const SUBMIT_RESET_PASSWORD = {
  SUBMIT_RESET_PASSWORD_REQUEST: 'SUBMIT_RESET_PASSWORD_REQUEST',
  SUBMIT_RESET_PASSWORD_SUCCESS: 'SUBMIT_RESET_PASSWORD_SUCCESS',
  SUBMIT_RESET_PASSWORD_FAILURE: 'SUBMIT_RESET_PASSWORD_FAILURE',
};

export const SUBMIT_LOGIN = {
  SUBMIT_LOGIN_REQUEST: 'SUBMIT_LOGIN_REQUEST',
  SUBMIT_LOGIN_SUCCESS: 'SUBMIT_LOGIN_SUCCESS',
  SUBMIT_LOGIN_FAILURE: 'SUBMIT_LOGIN_FAILURE',
};

export const FILE_UPLOADER_TYPES = {
  SQUARE: 'square',
  LOGO: 'logo',
  INLINE: 'inline',
  MULTIPLE: 'multiple'
};

export const BUTTON_STYLES = {
  GREEN: 'green',
  PURPLE_SQUARE: 'purple-square',
  PURPLE_PILL: 'purple-pill',
  TRANSPARENT: 'transparent',
};

export const SELECT_STYLES = {
  GREY: 'grey',
  TRANSPARENT: 'transparent'
};

export const SIGNUP_TABS = {
  signup: 'signup',
  signin: 'signin'
};

export const SNACKBAR_TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

export const IMAGE_SIZES = {
  XXX_LARGE: 2000,
  XX_LARGE: 1600,
  X_LARGE: 1080,
  LARGE: 724,
  MEDIUM: 600,
  SMALL: 300,
  THUMBNAIL: 144
};

export const ONBOARDING_STEPS = {
  CREATE_PROFILE: 'CREATE_PROFILE',
  CREATE_CHANNEL: 'CREATE_CHANNEL',
  SET_PAYMENT: 'SET_PAYMENT',
  INVITE_TEAM: 'INVITE_TEAM',
};

export const TICKET_ABSTRACT_LENGTH = 24;

export const FESTIVAL_ACTION_TYPES = {
  SET_MAIN_CHANNEL: 'FESTIVALS::SET_MAIN_CHANNEL',
  SET_MAIN_CHANNEL_SUCCESS: 'FESTIVALS::SET_MAIN_CHANNEL_SUCCESS',
  SET_MAIN_CHANNEL_ERROR: 'FESTIVALS::SET_MAIN_CHANNEL_ERROR',
  UPDATE_FESTIVAL_INFORMATION: 'FESTIVALS::UPDATE_FESTIVAL_INFORMATION',
  UPDATE_FESTIVAL_INFORMATION_SUCCESS: 'FESTIVALS::UPDATE_FESTIVAL_INFORMATION_SUCCESS',
  UPDATE_FESTIVAL_INFORMATION_ERROR: 'FESTIVALS::UPDATE_FESTIVAL_INFORMATION_ERROR',
  GET_FESTIVALS_REQUEST: 'FESTIVALS::GET_FESTIVALS_REQUEST',
  GET_FESTIVALS_SUCCESS: 'FESTIVALS::GET_FESTIVALS_SUCCESS',
  GET_FESTIVALS_ERROR: 'FESTIVALS::GET_FESTIVALS_ERROR',
};

export const GET_PAYOUTS_INFO_TYPES = {
  REQUEST: 'GET_PAYOUTS_INFO_REQUEST',
  SUCCESS: 'GET_PAYOUTS_INFO_SUCCESS',
  FAILURE: 'GET_PAYOUTS_INFO_FAILURE',
}

export const DEFAULT_TICKET_DESCRIPTIONS = {
  ALL_ACCESS: 'This pass gives you all access to the experiences happening during this festival.',
  RESTRICTED_ACCESS: 'This pass gives you access to experiences available on the selected days.',
}

export const MOBILE_MAX_WIDTH = '(max-width: 1224px)';

export const PAYOUT_LIST_TYPES = {
  CHANNEL_OWNER: 'CHANNEL_OWNER',
  CREATOR: 'CREATOR',
}
