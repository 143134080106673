import React from 'react';
import { array } from 'prop-types';
import ScheduledItem from './ScheduledItem';
import styled from 'styled-components';

const ScheduledExperience = ({ experiences, ctaButton, festivals=false }) =>
  experiences != null ? (
    <ExperienceListView className="experience-list-view">
      {experiences.map((experience, index) => (
        <ScheduledItem
          // key={experience._id}
          key={index}
          experience={experience}
          lastStartTime={index !== 0 ? experiences[index - 1].startTime : ''}
          isLastItem={experiences.length - 1 === index}
          ctaButton={ctaButton}
          festivals={festivals}

        />
      ))}
    </ExperienceListView>
  ) : null;

const ExperienceListView = styled.div`
  display: grid;
  grid-gap: 12px;
  background-color: #f3f3f4;
  align-self: center;
  width: 100%;
  padding: 20px 0;
  justify-content: center;
  padding-bottom: 60px;
`;

ScheduledExperience.propTypes = {
  experiences: array,
};
export default ScheduledExperience;
