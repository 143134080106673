import React from 'react';
import {string, object, shape, bool} from 'prop-types';

const TextAreaShowingError = ({
    input,
    meta: { touched, error },
    className,
    ...other
  }) =>  {
  return (
    <textarea {...input} {...other} className={`${className || ''} ${touched && error && 'input-error'}`} />
  );
};

TextAreaShowingError.propTypes = {
  input: object,
  className: string,
  meta: shape({error: string, touched: bool}),
};

export default TextAreaShowingError;
