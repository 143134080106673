import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TIME_FORMATS } from 'appconstants/time2';
import styled from 'styled-components';
import { PAYOUT_LIST_TYPES } from 'appconstants';
import { dateFormat } from 'utils/dates';

const PayoutsList = ({ loading, payouts, layout = PAYOUT_LIST_TYPES.CHANNEL_OWNER }) => {
  if (loading) {
    return <CircularProgress />
  }

  const getStatus = (status, delegatedFrom) => {
    if (status === 'pending' || status === 'transferred') {
      return 'pending';
    }

    return !!delegatedFrom ? 'delegated' : 'paid';
  }

  const headers = layout === PAYOUT_LIST_TYPES.CHANNEL_OWNER ? [
    'Creator',
    'Status',
    'Experience',
    'Time',
    'Payout',
  ] : [
    'Experience',
    'Made to',
    'Time',
    'Total ticket sales',
    'Rev share',
    'Payout',
  ];

  const renderItem = ({
    _id,
    experience,
    festival,
    amount,
    totalAmount,
    updatedAt,
    delegatedFrom,
    status,
    receiver
  }) => layout === PAYOUT_LIST_TYPES.CHANNEL_OWNER ? (
    <TableRow key={_id}>
      <CreatorName>{delegatedFrom || receiver}</CreatorName>
      <TableCell>
        <Status className={getStatus(status, delegatedFrom)}>
          {getStatus(status, delegatedFrom)}
        </Status>
      </TableCell>
      <Cell>{experience || festival}</Cell>
      <Cell>{dateFormat(TIME_FORMATS.NAMED_DAY_AND_MONTH, updatedAt)}</Cell>
      <Cell color="#44AF69">${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Cell>
    </TableRow>
  ) : (
    <TableRow key={_id}>
      <CreatorName>{experience || festival}</CreatorName>
      <Cell>{receiver}</Cell>
      <Cell>{dateFormat(TIME_FORMATS.NAMED_DAY_AND_MONTH, updatedAt)}</Cell>
      <Cell>${totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Cell>
      <Cell>${(totalAmount - amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Cell>
      <Cell color="#44AF69">${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Cell>
    </TableRow>
  );

  return (
    <Table>
      {
        payouts.length > 0 ? (
          <>
            <TableHead>
              <TableRow>
                {
                  headers.map(header => (
                    <HeaderCell key={header}>{header}</HeaderCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {payouts.map(renderItem)}
            </TableBody>
          </>
        ) : (
          <TableBody>
            No payouts yet
          </TableBody>
        )
      }
    </Table>
  )
}

const CreatorName = styled(TableCell)`
  && {
    color: #4D4D4D;
    font-family: CircularStd Book;
    font-size: 18px;
    font-weight: 300;
    max-width: 220px;
    overflow: hidden;
    padding-left: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }
`;

const Cell = styled(TableCell)`
  && {
    color: ${({ color }) => color || '#999999'};
    font-family: CircularStd Book;
    font-size: 14px;
    max-width: 200px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const HeaderCell = styled(TableCell)`
  && {
    color: #D5D5D5;
    font-size: 11px;
    font-family: CircularStd Medium;
    padding-bottom: 11px;
    text-transform: uppercase;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const Status = styled.div`
  border-radius: 5px;
  font-family: CircularStd Book;
  font-size: 14px;
  font-weight: normal;
  height: 23px;
  text-transform: capitalize;
  width: 83px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  &.paid {
    color: #44AF69;
    background-color: #44AF693D;
  };

  &.delegated {
    color: #E66767;
    background-color: #F07DA83D;
  }

  &.pending {
    color: #FA8C00;
    background-color: #FEBD213D;
  }
`

export default PayoutsList;
