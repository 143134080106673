import React, { useMemo, useRef, useEffect } from "react";
import { object, func, bool } from "prop-types";
import { Field, formValueSelector} from "redux-form";
import { DatePicker } from "redux-form-material-ui";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";
import ArrowDropdownIcon from "material-ui/svg-icons/navigation/arrow-drop-down";

import Loading from "components/Loading";
import TimePicker from "components/TimePicker";

import { canEditExperienceDates } from "utils/helpers";

import calendarImage from "images/calendar.png";

const datepickerStyle = {
  height: "65px",
};

const datepickerTextFieldStyle = {
  border: "none",
};

const DateAndTimePage = (params) => {
  const {
    experience,
    festival,
    festivals,
    loading,
    onStartDateChange,
    onStartTimeChange,
    startTime,
    startDate,
    endDate,
    endTime,
    change,
    handleFestivalDates
  } = params
  const startDateRef = useRef();
  const endDateRef = useRef();

  const { maxDate, minDate, hasFestival, selectedFestival } = useMemo(() => {
    if (!festival || !festivals?.length) {
      return ({
        minDate: new Date(),
      });
    }

    const selectedFestival = festivals.find(({ id }) => id === festival);
    if (!selectedFestival) {
      return ({
        minDate: new Date(),
      });
    }

    return ({
      selectedFestival,
      hasFestival: true,
      minDate: new Date(selectedFestival.startTime),
      maxDate: new Date(selectedFestival.endTime),
    });
  }, [festival, festivals]);

  useEffect(() => {
    if (festival && festivals.length) {
      const selectedFestival = festivals.find(({ id }) => id === festival);

      if (!selectedFestival) {
        return;
      }
      const { startTime: festivalStart, endTime: festivalEnd } = selectedFestival;
      const festivalStartDate = moment(festivalStart).toDate()
      const festivalEndDate = moment(festivalEnd).toDate()
      handleFestivalDates(festivalStartDate, festivalEndDate)
      if (moment(startDate).isBefore(moment(festivalStart)) || moment(startDate).isAfter(moment(festivalEnd))) {
        change('endDate', moment(festivalStart).add(2, 'days').toDate());
        change('endTime', moment(festivalStart).add(2, 'days').toDate());
        change('startDate', moment(festivalStart).toDate());
        change('startTime', moment(festivalStart).toDate());
      }
    }
  }, [
    festival,
  ])

  if (loading) {
    return <Loading message="Loading Date and time ..." />;
  }

  const isDisabled = !canEditExperienceDates(experience);

  return (
    <div className="datetime-wrapper experience-wrapper">
      <div className="datetime-group">
        <div className="datepicker-container" onClick={() => startDateRef?.current?.getRenderedComponent()?.focus()}>
          <span className="field-label">START DATE</span>
          <img src={calendarImage} alt="" />
          <Field
            className="datepicker"
            component={(props) => (
              <DatePicker {...props} disabled={isDisabled} ref={startDateRef} />
            )}
            disabled={isDisabled}
            id="startDate"
            maxDate={hasFestival ? maxDate : undefined}
            minDate={minDate}
            name="startDate"
            onChange={onStartDateChange}
            style={{ ...datepickerStyle, opacity: isDisabled ? "0.5" : "1" }}
            textFieldStyle={datepickerTextFieldStyle}
          />
          <ArrowDropdownIcon color="#B9B9B9" />
        </div>
        <div className="timepicker-container">
          <span className="field-label">START TIME</span>
          <Field
            className="timepicker"
            id="startTime"
            name="startTime"
            disabled={isDisabled}
            component={TimePicker}
            onChange={onStartTimeChange}
            props={{
              defaultTimezone: experience.channel.defaultTimezone
            }}
          />
        </div>
      </div>
      <div className="datetime-group">
      <div className="datepicker-container" onClick={() => endDateRef?.current?.getRenderedComponent()?.focus()}>
          <span className="field-label">END DATE</span>
          <img src={calendarImage} alt="" />
          <Field
            className="datepicker"
            id="endDate"
            name="endDate"
            component={(props) => (
              <DatePicker {...props} ref={endDateRef} />
            )}
            disabled={isDisabled}
            style={{ ...datepickerStyle, opacity: isDisabled ? "0.5" : "1" }}
            textFieldStyle={datepickerTextFieldStyle}
            minDate={hasFestival ? minDate : undefined}
            maxDate={hasFestival ? maxDate : undefined}
          />
          <ArrowDropdownIcon color="#B9B9B9" />
        </div>
        <div className="timepicker-container">
          <span className="field-label">END TIME</span>
          <Field
            className="timepicker"
            id="endTime"
            disabled={isDisabled}
            name="endTime"
            component={TimePicker}
            props={{
              defaultTimezone: experience.channel.defaultTimezone
            }}
          />
        </div>
      </div>
    </div>
  );
};

DateAndTimePage.propTypes = {
  onStartDateChange: func,
  onStartTimeChange: func,
  loading: bool,
  experience: object,
};

const selector = formValueSelector('ExperienceForm');

const mapStateToProps = (state) => {
  return {
  festival: selector(state, 'festival'),
  startDate: selector(state, 'startDate'),
  startTime: selector(state, 'startTime'),
  endDate: selector(state, 'endDate'),
  endTime: selector(state, 'endTime'),
  festivals: state.festivals.userFestivals,
}};

// const mapDispatchToProps = dispatch => bindActionCreators({

// }, dispatch);

export default connect(mapStateToProps)(DateAndTimePage);
