import { CALL_API } from 'redux_store/middleware/api';

const SINGLE_PAYMENT_CARD_REQUEST = 'SINGLE_PAYMENT_CARD_REQUEST';
const SINGLE_PAYMENT_CARD_SUCCESS = 'SINGLE_PAYMENT_CARD_SUCCESS';
const SINGLE_PAYMENT_CARD_FAILURE = 'SINGLE_PAYMENT_CARD_FAILURE';
const SINGLE_PAYMENT_REQUEST = 'SINGLE_PAYMENT_REQUEST';
const SINGLE_PAYMENT_SUCCESS = 'SINGLE_PAYMENT_SUCCESS';
const SINGLE_PAYMENT_FAILURE = 'SINGLE_PAYMENT_FAILURE';
const CHANGE_SUBS_PLAN_REQUEST = 'CHANGE_SUBS_PLAN_REQUEST';
const CHANGE_SUBS_PLAN_SUCCESS = 'CHANGE_SUBS_PLAN_SUCCESS';
const CHANGE_SUBS_PLAN_FAILURE = 'CHANGE_SUBS_PLAN_FAILURE';
const SUBSCRIBE_CUS_PLAN_REQUEST = 'SUBSCRIBE_CUS_PLAN_REQUEST';
const SUBSCRIBE_CUS_PLAN_SUCCESS = 'SUBSCRIBE_CUS_PLAN_SUCCESS';
const SUBSCRIBE_CUS_PLAN_FAILURE = 'SUBSCRIBE_CUS_PLAN_FAILURE';
const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';
const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';
const GET_CHARGES_REQUEST = 'GET_CHARGES_REQUEST';
const GET_CHARGES_SUCCESS = 'GET_CHARGES_SUCCESS';
const GET_CHARGES_FAILURE = 'GET_CHARGES_FAILURE';

/**
 *
 * @param {*} subscription
 * @param {*} plan
 */
export function changeSubscriptionPlan(subscription, plan) {
  const method = 'put';
  const endpoint = `/users/subscription/change`;

  const body = {
    subscription,
    plan,
  };

  return {
    [CALL_API]: {
      types: [
        CHANGE_SUBS_PLAN_REQUEST,
        CHANGE_SUBS_PLAN_SUCCESS,
        CHANGE_SUBS_PLAN_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

/**
 *
 *
 * @export
 * @param {any} value
 * @param {any} customer
 */
export function processSinglePayment(value, customer) {
  const method = 'post';
  const endpoint = `/stripe/processSinglePayment`;

  const body = {
    value, customer,
  };

  return {
    [CALL_API]: {
      types: [
        SINGLE_PAYMENT_REQUEST,
        SINGLE_PAYMENT_SUCCESS,
        SINGLE_PAYMENT_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

/**
 *
 *
 * @export
 * @param {any} value
 * @param {any} cardData
 * @param {any} email
 */
export function processSinglePaymentCard(value, card, email) {
  const method = 'post';
  const endpoint = `/stripe/processSinglePaymentCard`;

  const body = {
    value, card, email,
  };

  return {
    [CALL_API]: {
      types: [
        SINGLE_PAYMENT_CARD_REQUEST,
        SINGLE_PAYMENT_CARD_SUCCESS,
        SINGLE_PAYMENT_CARD_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

/**
 *
 * @param {*} customer
 * @param {*} plan
 */
export function subscribeCustomerToPlan(customer, plan) {
  const method = 'post';
  const endpoint = `/stripe/subscribeCustomerToPlan`;

  const body = {
    customer, plan,
  };

  return {
    [CALL_API]: {
      types: [
        SUBSCRIBE_CUS_PLAN_REQUEST,
        SUBSCRIBE_CUS_PLAN_SUCCESS,
        SUBSCRIBE_CUS_PLAN_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

/**
 *
 * @param {*} customer
 */
export function createCustomer(customer) {
  const method = 'post';
  const endpoint = `/stripe/createCustomer`;

  const body = {
    customer,
  };

  return {
    [CALL_API]: {
      types: [
        CREATE_CUSTOMER_REQUEST,
        CREATE_CUSTOMER_SUCCESS,
        CREATE_CUSTOMER_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

/**
 *
 */
export function getCharges() {
  const method = 'get';
  const endpoint = `/stripe/getCharges`;
  return {
    [CALL_API]: {
      types: [
        GET_CHARGES_REQUEST,
        GET_CHARGES_SUCCESS,
        GET_CHARGES_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

/**
 *
 * @param {*} id
 */
export function getCustomer(id) {
  const method = 'get';
  const endpoint = `/stripe/getCustomer/${id}`;
  return {
    [CALL_API]: {
      types: [
        GET_CUSTOMER_REQUEST,
        GET_CUSTOMER_SUCCESS,
        GET_CUSTOMER_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

/**
 *
 */
export function getCustomers() {
  const method = 'get';
  const endpoint = `/stripe/getCustomer`;
  return {
    [CALL_API]: {
      types: [
        GET_CUSTOMERS_REQUEST,
        GET_CUSTOMERS_SUCCESS,
        GET_CUSTOMERS_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}
