import React from "react";
import { string, number } from "prop-types";

const CancelIcon = ({ color = "#FFF", height = 18, width = 18, ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={height}
    height={width}
    viewBox="0 0 18 18"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g stroke={color} strokeWidth="2" transform="translate(-16 -24)">
        <g transform="translate(17 25)">
          <path d="M15.085 0L0 15.084" />
          <path d="M15.085 15.085L0 0.001" />
        </g>
      </g>
    </g>
  </svg>
);

CancelIcon.propTypes = {
  color: string,
  height: number,
  width: number,
};

export default CancelIcon;
