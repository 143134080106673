import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Snackbar, CircularProgress, Dialog } from 'material-ui';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import moment from 'moment';

import * as actionsExperiences from 'actions/experiences';
import * as actionsDiscounts from 'actions/discounts';
import * as actionsAuth from 'actions/auth';

import discountIcon from 'images/discount-icon.png';
import './styles.css';
import { TIME_FORMATS } from 'appconstants/time2';
import { dateFormat } from 'utils/dates';

const styles = {

  modalSpinner: {
    display: 'block',
    zIndex: '1001',
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    filter: 'alpha(opacity=00)',
    background: 'linear-gradient(24deg, rgba(125, 152, 241, 0.9) 13%, rgba(229, 120, 162, 0.9) 100%)',
  },
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
  upgradeButton: {
    style: {
      backgroundColor: '#F57AA8',
      borderRadius: 20,
      height: 29,
      lineHeight: 0,
      marginTop: 3,
      marginRight: 30,
      padding: '0px 14px 1px 14px',
      minWidth: 81,
      width: 'auto',
    },
    memberStyle: {
      backgroundColor: '#7e9bf4',
      borderRadius: 20,
      height: 29,
      lineHeight: 0,
      marginTop: '18px',
      marginRight: 0,
      minWidth: 81,
      width: 'auto',
      paddingBottom: '2px',
    },
    label: {
      textTransform: 'none',
      color: '#FFFFFF',
      padding: '0px 0px 0px 1px',
      verticalAlign: 'none',
    },
  },
  userIcon: {
    borderRadius: '50%',
    backgroundColor: '#000000',
    marginRight: '10px',
  },
};

class Discounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerModalOpened: false,
      discounts: [],
      discount: {},
      dialogTitle: '',
      saveButton: '',
      showDialog: false,
      errorMessage: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.experience.id !== this.props.experience.id) {
      this.loadData();
    }
  }

  loadData = () => {
    const { experience } = this.props;
    if (experience.discounts == null) {
      return;
    }

    const discounts = experience.discounts.sort((a, b) => moment(b.createdOn).diff(moment(a.createdOn)));

    discounts.forEach((item) => {
      const discount = item;
      const amount = discount.amount;
      if (discount.isPercentage) {
        discount.amountPercentage = amount;
      } else {
        discount.amountAbsolute = amount;
      }
    });
    this.setState({ discounts, spinnerModalOpened: false });
  }

  closeErrorMessage = () => {
    this.setState({
      errorMessage: null,
    });
  };

  handleCodeChange = (event) => {
    const discount = this.state.discount;
    discount.code = event.target.value;

    this.setState({ discount });
  }

  handleAmountAbsoluteChange = (event) => {
    const discount = this.state.discount;
    discount.amountAbsolute = event.target.value;
    discount.isPercentage = false;
    discount.amountPercentage = '';

    this.setState({ discount });
  }

  handleAmountPercentageChange = (event) => {
    const discount = this.state.discount;
    discount.amountPercentage = event.target.value;
    discount.isPercentage = true;
    discount.amountAbsolute = '';

    this.setState({ discount });
  }

  handleTotalChange = (event) => {
    const discount = this.state.discount;
    discount.total = event.target.value;

    if (discount.total === '') {
      discount.total = null;
    }

    this.setState({ discount });
  }

  handleEditDiscount = (discount) => {
    const dialogTitle = 'Copy discount code';
    const saveButton = 'Save';
    const discountToEdit = Object.assign({}, discount);
    this.setState({ showDialog: true, discount: discountToEdit, dialogTitle, saveButton });
  }

  handleNewDiscount = () => {
    const dialogTitle = 'New discount code';
    const saveButton = 'Create';
    this.setState({ showDialog: true, discount: {}, dialogTitle, saveButton });
  }

  isValidDiscount = () => {
    let isValid = true;
    if (this.state.discount.code.includes(' ')) {
      const errorMessage = 'Code cannot contain white space';
      this.setState({ errorMessage });
      isValid = false;
    }

    return isValid;
  }

  submitSaveDiscount = async () => {
    const { experience, saveDiscount, saveExperience, loadData } = this.props;
    const shouldUpdateExperience = typeof this.state.discount.id === 'undefined';
    const discountToSave = Object.assign({}, this.state.discount);

    discountToSave.amount = discountToSave.isPercentage ? discountToSave.amountPercentage : discountToSave.amountAbsolute;
    discountToSave.amountAbsolute = null;
    discountToSave.amountPercentage = null;

    if (!this.isValidDiscount()) {
      return;
    }

    this.setState({ spinnerModalOpened: true });
    saveDiscount(discountToSave).then(async (discount) => {
      this.setState({ showDialog: false });

      if (shouldUpdateExperience) {
        if (experience.discounts == null) {
          experience.discounts = [];
        }

        experience.idOriginal = experience.id;
        this.setState({loading: true});
        await saveExperience({data: experience, changedValues: {discounts: discount.id}});
      }
    }).finally(() => {
      loadData(() => {
        this.setState({ spinnerModalOpened: false });
        this.loadData();
      });
    });
  }

  closeDialog = () => {
    this.setState({ showDialog: false });
  }

  renderDiscounts = () => {
    if (this.state.spinnerModalOpened) return null;
    return (
      <div>
        {
          this.state.discounts &&
          this.state.discounts.map(this.renderItem)
        }
      </div>
    );
  }

  renderDesktop() {
    return (
      <div>
        <div className="discounts-title-container">
          <span className="discounts-title">Discounts</span>
          <button className="discounts-new-btn" onClick={this.handleNewDiscount}>New</button>
        </div>
        {this.renderDiscounts()}
      </div>
    );
  }

  renderUses(discount) {
    const total = discount.total ? discount.total : '∞';
    return (
      <div key={discount.id}>
        <span className="used">{discount.used}</span><span className="total">/{total}</span>
      </div>
    );
  }

  renderItem = (discount) => {
    let {id} = discount;
    return (
      <div className="discount-box" key={id}>
        <img className="icon" alt="discount icon" src={discountIcon} />
        <div className="code-container">
          <div className="value">{discount.code}</div>
          <div className="label">Created {dateFormat(TIME_FORMATS.NAMED_DAY_AND_MONTH, discount.createdOn)}</div>
        </div>
        <div className="float-right">
          <div className="amount-container">
            <div className="value">{discount.isPercentage ? `${discount.amountPercentage}%` : `$${discount.amountAbsolute}`}</div>
            <div className="label">discount</div>
          </div>
          <div className="uses-container">
            <div className="value">{this.renderUses(discount)}</div>
            <div className="label">uses</div>
          </div>
          <button className="edit-discount-btn" onClick={() => this.handleEditDiscount(discount)}>Edit</button>
        </div>
      </div>
    );
  }

  renderSpinner() {
    return (
      <div style={{ textAlign: 'center', width: '100%', paddingTop: '50px' }}>
        <CircularProgress size={90} />
      </div>
    );
  }

  renderSnackbar() {
    return (
      <Snackbar
        open={!!this.state.errorMessage}
        message={this.state.errorMessage || ''}
        autoHideDuration={4000}
        onRequestClose={this.closeErrorMessage}
        bodyStyle={styles.snackbar}
      />
    );
  }

  renderDialog = () => {
    return (
      <Dialog
        bodyClassName="ticketing-dialog-body"
        bodyStyle={styles.bodyStyle}
        contentStyle={styles.contentStyle}
        contentClassName="ticketing-dialog-content"
        open={this.state.showDialog}
        onRequestClose={this.closeDialog}
        title={(
          <div>{this.state.dialogTitle}<CloseIcon color="#999999" onClick={this.closeDialog} /></div>
        )}
        titleClassName="ticketing-dialog-title"
        titleStyle={styles.titleStyle}
      >
        <span className="label">Code</span>
        <div className="gray-form-field">
          <input className="input" type="text" placeholder="Enter discount code" value={this.state.discount.code} onChange={this.handleCodeChange} />
        </div>
        <div>
          <div className="amount">
            <span className="label">Amount</span>
            <div className="gray-form-field">
              <input className="input" type="text" placeholder="$" value={this.state.discount.amountAbsolute} onChange={this.handleAmountAbsoluteChange} />
            </div>
          </div>
          <div className="amount">
            <div className="gray-form-field">
              <input className="input" type="text" placeholder="%" value={this.state.discount.amountPercentage} onChange={this.handleAmountPercentageChange} />
            </div>
          </div>
        </div>
        <div className="uses">
          <span className="label">Uses</span>
          <div className="gray-form-field">
            <input className="input" type="text" placeholder="Leave blank for unlimited" value={this.state.discount.total} onChange={this.handleTotalChange} />
          </div>
        </div>
        <div className="button-wrapper" onClick={this.submitSaveDiscount}>
          <button className="popup-link button-label">{this.state.saveButton}</button>
        </div>
      </Dialog>
    );
  }

  render() {
    const { experience } = this.props;

    return (
      <div>
        { experience &&
          <section>
            {this.renderDesktop()}
          </section>
        }

        {this.renderDialog()}
        {this.state.spinnerModalOpened && this.renderSpinner()}
        {this.renderSnackbar()}
      </div>
    );
  }
}

Discounts.propTypes = {
  experience: PropTypes.object,
  saveExperience: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  saveDiscount: PropTypes.func.isRequired,
};

export default connect(() => state => ({ experience: state.experiences.experience }), {
  ...actionsExperiences,
  ...actionsDiscounts,
  ...actionsAuth,
})(Discounts);
