import { CALL_API } from 'redux_store/middleware/api';

const SAVE_TICKET_REQUEST = 'SAVE_TICKET_REQUEST';
const SAVE_TICKET_SUCCESS = 'SAVE_TICKET_SUCCESS';
const SAVE_TICKET_FAILURE = 'SAVE_TICKET_FAILURE';

const LOAD_TICKET_REQUEST = 'LOAD_TICKET_REQUEST';
const LOAD_TICKET_SUCCESS = 'LOAD_TICKET_SUCCESS';
const LOAD_TICKET_FAILURE = 'LOAD_TICKET_FAILURE';

const FETCH_TICKETS_REQUEST = 'FETCH_TICKETS_REQUEST';
const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
const FETCH_TICKETS_FAILURE = 'FETCH_TICKETS_FAILURE';
const FETCH_TICKETS_FINISH = 'FETCH_TICKETS_FINISH';

function fetchTicketsFinish(response) {
  return {
    type: FETCH_TICKETS_FINISH,
    tickets: response.response,
  };
}

function getTicketsByType(type) {
  const method = 'get';
  const endpoint = `/tickets?type=${type}`;
  return {
    [CALL_API]: {
      types: [
        FETCH_TICKETS_REQUEST,
        FETCH_TICKETS_SUCCESS,
        FETCH_TICKETS_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function getTagById(id) {
  const method = 'get';
  const endpoint = `/tickets/${id}`;
  return {
    [CALL_API]: {
      types: [
        LOAD_TICKET_REQUEST,
        LOAD_TICKET_SUCCESS,
        LOAD_TICKET_FAILURE,
      ],
      method,
      endpoint,
    },
  };
}

function postTicket(body) {
  const method = 'post';
  const endpoint = '/tickets';
  return {
    [CALL_API]: {
      types: [
        SAVE_TICKET_REQUEST,
        SAVE_TICKET_SUCCESS,
        SAVE_TICKET_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}


function putTicket(body, id) {
  const method = 'put';
  const endpoint = `/tickets/${id}`;
  return {
    [CALL_API]: {
      types: [
        SAVE_TICKET_REQUEST,
        SAVE_TICKET_SUCCESS,
        SAVE_TICKET_FAILURE,
      ],
      method,
      endpoint,
      body,
    },
  };
}

export function fetchTicketsByType(type) {
  return (dispatch) => {
    return dispatch(getTicketsByType(type))
      .then((tickets) => {
        dispatch(fetchTicketsFinish(tickets));
        return tickets;
      });
  };
}

export function loadTickets(ids) {
  return (dispatch) => {
    const getActions = ids.map((id) => {
      return dispatch(getTagById(id));
    });
    return Promise.all(getActions);
  };
}

export function saveTicket(data) {
  return (dispatch) => {
    const body = {
      ...data,
    };
    if (body.id) {
      return dispatch(putTicket(body, body.id))
        .then((res) => {
          return res;
        });
    }
    return dispatch(postTicket(body))
      .then((res) => {
        return res;
      });
  };
}

export function saveTickets(tickets) {
  return (dispatch) => {
    const saveActions = tickets.map((item) => {
      return dispatch(saveTicket(item));
    });
    return Promise.all(saveActions);
  };
}
