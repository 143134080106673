import React, { useEffect, useMemo, useState } from 'react';
import { browserHistory } from 'react-router';
import styled from 'styled-components';
import ScheduleIcon from '../../images/list-icon.jsx';
import TiledIcon from '../../images/tile-icon.jsx';
import IconButton from 'material-ui/IconButton';
import ScheduledExperience from 'components/ScheduledExperience/ScheduledExperience.jsx';
import ListExperiences from 'components/ListExperiences';
import HeroView from './HeroView.jsx';
import PresenceSlider from 'components/PresenceSlider/PresenceSlider.jsx';
import ThumbExperiences from 'components/ThumbExperiences';
import { connect } from 'react-redux';
import { selectExperiencesUsers } from 'selectors/experiences.js';
import { bindActionCreators } from 'redux';
import { setCurrentChannel, fetchChannels } from 'actions/channels';
import { selectChannels, selectCurrentChannel } from 'selectors/channels.js';
import useFestivalInformation from 'hooks/useFestivalInformation.js';
import { selectRSVPedExp } from 'selectors/userExperiences';
import MediaQuery from 'react-responsive';
import AdminHeaderMob from 'components/AdminHeaderMob';
import FestivalLandingMobile from './FestivalLandingMobile.jsx';
import { Helmet } from 'react-helmet';
import { MOBILE_MAX_WIDTH } from 'appconstants/index.js';
import { useMediaQuery } from '@material-ui/core';
import AdminFooter from 'components/AdminFooter/index.jsx';
import ChannelDescription from 'components/ChannelDescription/index.jsx';

const settings = {
  arrows: false,
  centerMode: false,
  dots: false,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  draggable: false,
};

const FestivalLadingPage = ({
  channels,
  currentChannel,
  experiencesUsers,
  fetchChannels,
  params,
  setCurrentChannel,
}) => {
  const [category, setCategory] = useState('');
  const { data: festival, refetch } = useFestivalInformation(
    `${params.channel}/${params.alias}${category ? `?category=${category}` : ''}`
  );
  const [view, setView] = useState(window.location.hash === '#schedule' ? 'schedule' : 'tiled');
  const featured = useMemo(() => {
    // TODO: Commenting out the next 3 lines, since the featuredExperiences array used does not have the `festivalUnlocked` information
    // to properly handle the call to action of a featued experience. Backend should be fixed or refactored accordingly

    // if (festival?.featuredExperiences?.length > 0) {
    //   return festival.featuredExperiences.map((exp) => ({ ...exp, festival }));
    // }

    const feat =
      festival?.experiences?.experiences?.filter((experience) => experience.featured) || [];

    if (feat.length === 0) {
      return (festival?.experiences?.experiences?.slice(0, 4) || []).map((exp) => ({
        ...exp,
        festival,
      }));
    }

    return feat;
  }, [festival]);
  const [slideChangeTo, setSlideChangeTo] = useState(-1);

  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH);

  const isRegistered = useMemo(() => festival?.rsvp, [festival]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    async function fetchChannelsAsync() {
      if (!channels.length) {
        fetchChannels().then(() => {
          setCurrentChannel(params.channel);
        });
      } else {
        setCurrentChannel(params.channel);
      }
    };
    fetchChannelsAsync()
  }, [setCurrentChannel, channels, params]);

  useEffect(() => {
    const divDgr = document.getElementById('degradeDiv');

    if (divDgr) {
      divDgr.setAttribute(
        'style',
        `height: 100%; background:${festival?.pageDesign.backgroundColor}`
      );
    }
  }, [festival]);

  const hasContent = useMemo(() => festival?.experiencesWithDates?.length > 0, [festival]);

  const topbar = useMemo(
    () => (
      <TopBar>
        <IconButton
          onClick={() => {
            setView('schedule');
            browserHistory.push(`${window.location.pathname}#schedule`);
          }}
        >
          <ScheduleIconContainer>
            <ScheduleIcon active={view === 'schedule'} />
          </ScheduleIconContainer>
        </IconButton>
        <IconButton
          onClick={() => {
            setView('tiled');
            browserHistory.push(window.location.pathname);
          }}
        >
          <TiledIconContainer>
            <TiledIcon active={view === 'tiled'} />
          </TiledIconContainer>
        </IconButton>
        {!isMobile && (
          <Categories>
            <Category onClick={() => setCategory(null)} className={!category && 'active'}>
              Browse All
            </Category>
            {festival?.categories?.map(({ _id, description }) => (
              <Category
                key={_id}
                onClick={() => setCategory(_id)}
                className={category === _id ? 'active' : ''}
              >
                {description}
              </Category>
            ))}
          </Categories>
        )}
      </TopBar>
    ),
    [view, isMobile, festival, category]
  );

  const content = useMemo(
    () => (
      <>
        <ExperiencesContainer hasContent={hasContent} className="festival-experiences">
          {view === 'schedule' ? (
            <ScheduledExperience experiences={festival?.experiences?.experiences} />
          ) : (
            <ListExperiences experiences={festival?.experiences?.experiences} />
          )}
        </ExperiencesContainer>
        <SpeakersContainer></SpeakersContainer>
        {currentChannel && <ChannelDescription channel={currentChannel} />}
        <AdminFooter />
      </>
    ),
    [hasContent, currentChannel, view, festival]
  );

  return (
    <>
      {festival && (
        <Helmet>
          <title>{`${festival?.channel?.name} | ${festival?.name}`}</title>
          <meta property="og:title" content={`PRESENCE | ${festival?.name}`} />
          <meta property="og:description" name="description" content={festival?.description} />
          <meta property="og:image" name="og:image" content={festival?.pageDesign.heroPicture} />
          <meta
            property="og:image:secure_url"
            name="og:image:secure_url"
            content={festival?.pageDesign.heroPicture}
          />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_APP_URL}/in/${festival?.channel.urlSlug}/${festival?.alias}`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:image" content={festival?.pageDesign.heroPicture} />
          <link rel="icon" href={currentChannel?.images[0]} />
        </Helmet>
      )}
      <MediaQuery query="(max-device-width: 1224px)">
        <AdminHeaderMob />
        <FestivalLandingMobile
          backgroundColor={festival?.pageDesign?.backgroundColor || currentChannel?.color }
          festival={festival}
          view={view}
          topbar={topbar}
          experiences={festival?.experiences?.experiences}
        />
      </MediaQuery>
      <MediaQuery query="(min-device-width: 1224px)">
        <Container
          designBackgroundColor={festival?.pageDesign?.backgroundColor || currentChannel?.color}
          designFontColor={festival?.pageDesign.fontColor}
        >
          {isRegistered && featured.length > 0 ? (
            <div>
              <SliderContainer>
                <PresenceSlider
                  backgroundColor={
                    festival ? `background: ${currentChannel?.color || '#000'};` : ''
                  }
                  settings={settings}
                  featured={featured}
                  channel={festival?.channel}
                  experiencesUsers={experiencesUsers}
                  slideChangeTo={slideChangeTo}
                />
              </SliderContainer>
              {featured?.length > 1 && (
                <ThumbExperiences
                  featured={featured}
                  onClickThumb={(slide) => setSlideChangeTo(slide)}
                  hasShadow
                />
              )}
            </div>
          ) : (
            festival && <HeroView festival={festival} hasContent={hasContent} />
          )}
          {hasContent && topbar}
          {hasContent && content}
        </Container>
      </MediaQuery>
    </>
  );
};

const Categories = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex: 0 0 90%;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-height: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Category = styled.div`
  height: 81px;
  display: flex;
  font-family: CircularStd Book;
  font-size: 18px;
  color: #626262;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;

  &.active,
  &:hover {
    border-bottom: 1px solid #9b63f8;
    color: #9b63f8;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ designBackgroundColor }) => designBackgroundColor};
  color: ${({ designFontColor }) => designFontColor};
`;

const SliderContainer = styled.div`
  flex: 1;
  padding-bottom: 60px;
`;

const TopBar = styled.div`
  height: 80px;
  background-color: #f3f3f4;
  border: 1px solid #d5d5d5;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 32px;

  @media screen and ${MOBILE_MAX_WIDTH} {
    height: 39px;
    width: 100px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 12px auto 12px;
    border-color: transparent;
    padding: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      height: 40px;
      border-right: 1px solid #ebebeb;
    }
  }
`;

const ScheduleIconContainer = styled.div`
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: 25px;
    width: 25px;
  }
`;

const TiledIconContainer = styled.div`
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: 23px;
    width: 23px;
  }
`;

const ExperiencesContainer = styled.div`
  background-color: inherit;

  & .section-experience-list {
    ${({ hasContent }) => (hasContent ? '' : 'padding-top: 0')}
  }
`;

const SpeakersContainer = styled.div``;

const mapStateToProps = (state) => ({
  rsvpedExperiences: selectRSVPedExp(state),
  currentChannel: selectCurrentChannel(state),
  channels: selectChannels(state),
  experiencesUsers: selectExperiencesUsers(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentChannel, fetchChannels }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FestivalLadingPage);
