import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import TabMenu from 'components/TabMenu';
import {
  BASIC_SETTINGS_TABS,
  SETTINGS_TABS,
  OWNER_SETTINGS_TABS,
  PRODUCER_SETTINGS_TABS,
  USER_TYPES,
} from 'appconstants';

import NotificationSettings from 'containers/NotificationSettings';
import CreateChannel from 'components/CreateChannel';
import PasswordSettings from 'components/PasswordSettings';
import CreateProfile from 'components/CreateProfile';
import TeamSettings from 'components/TeamSettings';
import PaymentSettings from 'components/PaymentSettings';
import DefaultHelmet from 'components/DefaultHelmet';
import SplitSettings from 'components/SplitSettings';
import Snackbar from 'material-ui/Snackbar';
import { updateUser } from 'actions/auth';
import { selectUserMaxRole, selectIsChannelOwner } from 'selectors/user';
import { withRouter } from 'react-router';
import { SETTINGS_TABS_MAP } from 'appconstants/routes';

const styles = {
  snackbar: {
    backgroundColor: 'rgba(255, 0, 13, 0.88)',
  },
};

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
    };
    this.handlerSelect = this.handlerSelect.bind(this);
    this.showSelected = this.showSelected.bind(this);
    this.loadUserUpdated = this.loadUserUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadUserUpdated();
  }

  handlerSelect(selected) {
    const { currentChannel } = this.props;
    const [path] = Object.entries(SETTINGS_TABS_MAP(currentChannel?.urlSlug)).find(([path, label]) => label === selected);

    this.props.router.replace(path);
  }

  showSelected() {
    const {
      router: {
        location: {
          pathname,
        },
      },
      currentChannel,
    } = this.props;

    const settingsTabsMap = SETTINGS_TABS_MAP(currentChannel?.urlSlug);

    switch (settingsTabsMap[pathname]) {
      case BASIC_SETTINGS_TABS.ACCOUNT:
        return <CreateProfile settings />;
      case PRODUCER_SETTINGS_TABS.CHANNELS:
        return <CreateChannel settings />;
      case OWNER_SETTINGS_TABS.PAYMENT:
        return <PaymentSettings />;
      case BASIC_SETTINGS_TABS.NOTIFICATIONS:
        return <NotificationSettings />;
      case BASIC_SETTINGS_TABS.PASSWORD:
        return <PasswordSettings />;
      case PRODUCER_SETTINGS_TABS.TEAM:
        return <TeamSettings />;
      case OWNER_SETTINGS_TABS.SPLIT:
        return <SplitSettings />;
      default:
        return <CreateProfile settings />;
    }
  }

  async loadUserUpdated() {
    try {
      const { updateUser: updateUserData } = this.props;
      await updateUserData();
    } catch (e) {
      this.setState({ errorMessage: 'There was an error loading user data.' });
    }
  }

  getSettingsTabs() {
    const { isChannelOwner, maxRole, router: { location: { pathname }} } = this.props;

    if (pathname.includes('/account/')) {
      return maxRole === USER_TYPES.GUEST ? SETTINGS_TABS.ACCOUNT.GUEST : SETTINGS_TABS.ACCOUNT.CREATOR;
    }

    if (isChannelOwner || maxRole === USER_TYPES.ADMIN) {
      return SETTINGS_TABS.CHANNEL.OWNER;
    } else if (maxRole === USER_TYPES.PRODUCER) {
      return SETTINGS_TABS.CHANNEL.PRODUCER;
    }
    return BASIC_SETTINGS_TABS;
  }

  render() {
    const {
      state: {
        errorMessage
      },
      props: {
        router: {
          location: {
            pathname,
          },
        },
        currentChannel,
      },
    } = this;

    const settingsTabs = this.getSettingsTabs();

    return (
      <div className="settings-container">
        <DefaultHelmet />
        <div>
          <TabMenu
            items={settingsTabs}
            itemSelected={SETTINGS_TABS_MAP(currentChannel?.urlSlug)[pathname]}
            handlerSelect={this.handlerSelect}
          />
          {this.showSelected()}
        </div>
        <Snackbar
          open={!!errorMessage}
          message={errorMessage}
          autoHideDuration={4000}
          bodyStyle={styles.snackbar}
          onRequestClose={() => this.setState({ errorMessage: false })}
        />
      </div>
    );
  }
}

SettingsPage.propTypes = {
  updateUser: PropTypes.func,
  isChannelOwner: PropTypes.bool,
  maxRole: PropTypes.string,
  currentChannel: PropTypes.shape({
    urlSlug: PropTypes.string,
  }),
};

SettingsPage = reduxForm({
  form: 'SettingsForm',
})(SettingsPage);

const mapStateToProps = (state) => ({
  maxRole: selectUserMaxRole(state),
  currentChannel: state.channels.currentChannel,
  isChannelOwner: selectIsChannelOwner(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsPage));
