import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'material-ui';


const styles = {
  root: {
    width: '100%',
    height: 25,
  },
};

const TGErrorMessage = props => {
  const { error } = props;
  if (error) {
    return (
      <TextField
        style={styles.root}
        name="error"
        errorText={error}
        disabled
      />
    );
  }
  return <div />;
};

TGErrorMessage.propTypes = {
  error: PropTypes.string,
};

export default TGErrorMessage;
