import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Loading from 'components/Loading';
// import FieldCheckbox from 'components/FieldCheckbox';
import _, { isEmpty, filter } from 'lodash';
import RemovableList from 'components/CreateChannel/RemovableList';
import { getExperienceAvailableFestivals } from 'actions/festivals';
import { getExperienceAvailableSubcategories } from 'actions/experiences';
import './styles.css';

const DescriptionPage = (params) => {
  const {
    // experienceSubcategories,
    // isPrivate,
    experience,
    availableSubcategories,
    change,
    experienceCategories,
    experienceId,
    festival,
    festivals = [],
    getExperienceAvailableFestivals,
    getExperienceAvailableSubcategories,
    loading,
    loadingFestivals,
  } = params;
  const [experienceSubcategories, setExperienceSubcategories] = useState([]);
  useEffect(() => {
    getExperienceAvailableFestivals(experienceId);
    getExperienceAvailableSubcategories(experienceId);
  }, [experienceId, getExperienceAvailableFestivals, getExperienceAvailableSubcategories]);

  useEffect(() => {
    if (festivals.length) {
      change('festival', festival);
    }
  }, [festivals, festival, change]);
  useEffect(() => {
    if (experience?.subcategories) {
      const experienceSubcats = experience.subcategories.map((el) =>
        availableSubcategories.find((el2) => el2.id === el.id)
      );
      setExperienceSubcategories(experienceSubcats);
    } else if (experience?.subcategory) {
      const experienceSubCat = availableSubcategories.find(el => el.id === experience.subcategory)
      setExperienceSubcategories([experienceSubCat])
    }
  }, [experience, availableSubcategories]);

  const subcategoryAdd = (id) => {
    const addedCategory = availableSubcategories.find((el) => el.id === id);
    const newCategories = [...experienceSubcategories, addedCategory];
    const newCategoriesIdsOnly = newCategories.map((el) => el.id);
    change('subcategories', newCategoriesIdsOnly);
    setExperienceSubcategories(newCategories);
  };
  const subcategoryDelete = (id) => {
    const newCategories = filter(experienceSubcategories, (el) => el.id !== id);
    const newCategoriesIdsOnly = newCategories.map((el) => el.id);
    change('subcategories', newCategoriesIdsOnly);
    setExperienceSubcategories(newCategories);
  };
  return loading || loadingFestivals ? (
    <Loading message="Loading Description..." />
  ) : (
    <div>
      <div className="description-wrapper experience-wrapper">
        <Field
          name="name"
          placeholder="What is the name of your experience?"
          maxLength={64}
          component="input"
          type="text"
        />
        <Field name="description" component="textarea" placeholder="Describe your experience" />
        {/* <Field className="total-guests" name="total" placeholder="Total number of guests" component="input" type="number" /> */}
        <div className="category-container">
          <Field name="category" component="select">
            <option value="" selected disabled hidden>
              Select category
            </option>
            {isEmpty(experienceCategories) ? (
              <option disabled>No options available </option>
            ) : (
              experienceCategories.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.description}
                </option>
              ))
            )}
          </Field>
        </div>
        {!isEmpty(festivals) && (
          <div className="category-container subcategory-container">
            <Field name="festival" component="select" value={festival}>
              <option value="" selected disabled hidden>
                Select festival or camp
              </option>
              <option value={null}>None</option>
              {festivals.map((festivalData) => (
                <option key={festivalData.id} value={festivalData.id}>
                  {festivalData.name}
                </option>
              ))}
            </Field>
          </div>
        )}
        {!isEmpty(availableSubcategories) && (
          <div
            className="category-container subcategory-container"
            style={{ flexDirection: 'column', marginLeft: '0px' }}
          >
            <Field
              name="subcategory"
              component="select"
              style={{marginLeft: '0px'}}
              onChange={(e) => {
                if (e.target.value) subcategoryAdd(e.target.value);
              }}
            >
              <option value="" selected disabled hidden>
                Select subcategory
              </option>
              {availableSubcategories.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.description}
                </option>
              ))}
            </Field>
            <RemovableList list={experienceSubcategories} remove={(id) => subcategoryDelete(id)} />
          </div>
        )}
        {/* <div className="checkboxes-container">
        <div className="single-check">
          <Field name="private" component={FieldCheckbox} label="Private" />
        </div>
      </div>
      { isPrivate &&
        <Field
          className="small-placeholder"
          name="password"
          component="input"
          type="text"
          placeholder="optional password"
        />
      } */}
      </div>
    </div>
  );
};

DescriptionPage.propTypes = {
  experienceCategories: PropTypes.array,
  experienceSubcategories: PropTypes.array,
  isPrivate: PropTypes.bool,
  loading: PropTypes.bool,
  experienceId: PropTypes.string,
  festivals: PropTypes.array,
  getExperienceAvailableFestivals: PropTypes.func,
};

const selector = formValueSelector('ExperienceForm');

export default connect(
  (state) => ({
    isPrivate: selector(state, 'private'),
    festival: selector(state, 'festival.id'),
    festivals: state.festivals.userFestivals,
    loadingFestivals: state.festivals.loadingFestivals,
    availableSubcategories: state.experiences.subcategories,
  }),
  {
    getExperienceAvailableFestivals,
    getExperienceAvailableSubcategories,
  }
)(DescriptionPage);
