import { CALL_API } from 'redux_store/middleware/api';
import { GET_PAYOUTS_INFO_TYPES } from 'appconstants';

export function getPayouts(channel) {
  const method = 'get';
  const endpoint = `/payouts${channel ? `/${channel}` : ''}`;

  const action = {
    [CALL_API]: {
      types: [
        GET_PAYOUTS_INFO_TYPES.REQUEST,
        GET_PAYOUTS_INFO_TYPES.SUCCESS,
        GET_PAYOUTS_INFO_TYPES.FAILURE,
      ],
      method,
      endpoint,
    },
  };

  return async (dispatch) =>
    dispatch(action);
}
