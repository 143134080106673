import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import clock from 'images/clock.png';
import calendar from 'images/calendar.png'
import { TIME_FORMATS } from 'appconstants/time';

const DateTimeInput = ({ onClick = () => {}, value, format, type = 'date', onChange, disabled }) => {
  const date = useMemo(() => moment(value, format).format('MM/DD/YYYY'), [value, format]);
  const timezone = useMemo(() => moment().tz(moment.tz.guess()).zoneAbbr(), []);

  const [time, setTime] = useState(moment(value, format).format(TIME_FORMATS.EVENT_TIME_WITHOUT_ERA));
  const [era, setEra] = useState(moment(value, format).format(TIME_FORMATS.ERA));

  useEffect(() => {
    if (type === 'date') {
      return;
    }

    setTime(moment(value, format).format(TIME_FORMATS.EVENT_TIME_WITHOUT_ERA));
    setEra(moment(value, format).format(TIME_FORMATS.ERA));
  }, [type, value, format, setTime, setEra]);

  useEffect(() => {
    if (type === 'date') {
      return;
    }

    if (
      time.toLowerCase() === moment(value, format).format(TIME_FORMATS.EVENT_TIME_WITHOUT_ERA).toLowerCase()
      && era.toLowerCase() === moment(value, format).format(TIME_FORMATS.ERA).toLowerCase()
    ) {
      return;
    }

    const eraRegex = /^[ap]m$/i;
    const timeRegex = /^([0-1]?[0-9]):([0-5][0-9])$/;

    const eraTest = eraRegex.test(era);
    const timeExec = timeRegex.exec(time);

    // eraTest tests that the era input is AM or PM (case insensitive)
    // timeExect checks that time is h:mm and if so captures the value of h and mm
    if (!eraTest || !timeExec) {
      return;
    }

    const [_res, hoursString, minutes] = timeExec;
    const hours = parseInt(hoursString, 10);

    const newTime = new Date();

    const isAm = era.toLowerCase() === 'am';

    if (hours === 12) {
      newTime.setHours(isAm ? 0 : 12);
    } else if (hours < 12) {
      newTime.setHours(hours + (isAm ? 0 : 12));
    }

    newTime.setMinutes(minutes);
    newTime.setSeconds(0);
    newTime.setMilliseconds(0);

    onChange(newTime);
  }, [type, time, era, format, onChange, value]);

  return (
    <DateTimeWrapper onClick={onClick} type={type} disabled={disabled}>
      <DateTimeValue>
        <DateTimeElement>
          <Icon src={type === 'date' ? calendar : clock} />
          {
            type === 'date' ? date : <input disabled={disabled} type="text" value={time} onChange={({ target: { value }}) => setTime(value)} className="date-time-input" maxLength="5" />
          }
        </DateTimeElement>
        <DateTimeElement>
          {
            type !== 'date' && (<>
              <input type="text" value={era} disabled={disabled} onChange={({ target: { value }}) => setEra(value)} className="date-time-input" maxLength="2" />
              <Timezone>{timezone}</Timezone>
            </>)
          }
          {
            type === 'date' && (
              <ArrowDropDownIcon htmlColor="#B9B9B9" />
            )
          }
        </DateTimeElement>
      </DateTimeValue>
    </DateTimeWrapper>
  )
};

const DateTimeWrapper = styled.div`
  width: ${({ type }) => type === 'date' ? '378' : '224'}px;
  ${({ disabled }) => disabled && `
    opacity: 0.5;

    & * {
      cursor: not-allowed;
      user-select: none;
    }
  `}
`;

const DateTimeValue = styled.div`
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.04);
  box-sizing: border-box;
  color: #838383;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 100;
  height: 65px;
  justify-content: space-between;
  margin-bottom: 14px;
  padding: 0 14px;
  width: 100%;
`;

const Timezone = styled.span`
  color: #D5D5D5;
  display: inline;
  margin-left: 8px;
`;

const DateTimeElement = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  height: 21px;
  margin-right: 9px;
  width: 21px;
`;

export default DateTimeInput;
