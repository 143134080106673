import React, { useState, useCallback, useRef } from 'react';
import { BlockPicker } from 'react-color';
import styled from 'styled-components';

const ColorPicker = ({
  color,
  onChange,
  label,
  colorKey,
}) => {
  const [pickerOpened, setPickerOpened] = useState(false);
  const colorSquare = useRef();
  const handleChange = useCallback(({ hex }) => {
    onChange(colorKey, hex);
    setPickerOpened(true);
  }, [onChange, colorKey]);

  const handleClick = useCallback(() => {
    if (!pickerOpened) {
      setPickerOpened(true);
    }
  }, [pickerOpened, setPickerOpened]);

  return (
    <PickerContainer>
      <Label>{label}</Label>
      {
        pickerOpened && (
          <Overlay onClick={() => setPickerOpened(false)} />
        )
      }
      <PickerBlock onClick={handleClick}>
        <ColorBlock>
          <ColorSquare color={color} ref={colorSquare}>
            {
              pickerOpened && (
                  <StyledBlockPicker colors={[]} onChange={handleChange} color={color} />
              )
            }
          </ColorSquare>

        </ColorBlock>
        <LabelBlock>
          {color}
        </LabelBlock>
      </PickerBlock>
    </PickerContainer>
  );
}

const PickerBlock = styled.div`
  background-color: #F3F3F4;
  display: flex;
  border-radius: 8px;
  flex-direction: row;
  height: 47px;
  width: 162px;
`;

const LabelBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1;
  font-family: 'CircularStd Book';
  font-size: 18px;
  justify-content: center;
  color: #4D4D4D;
`;

const ColorBlock = styled.div`
  align-items: center;
  background-color: #EAEAEA;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex: 0 0 53px;
  justify-content: center;
`;

const ColorSquare = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 4px;
  cursor: pointer;
  height: 19px;
  position: relative;
  width: 19px;
  overflow: display;
`;

const StyledBlockPicker = styled(BlockPicker)`
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 999;
  pointer-event: none;
  transform: translateX(-50%);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Label = styled.div`
  font-family: 'CircularStd Book';
  font-size: 16px;
  margin-bottom: 11px;
  color: #999999;
`;

const PickerContainer = styled.div`
  & + & {
    margin-left: 26px;
  }

  &:last-of-type {
    margin-left: 0;
    margin-top: 29px;
  }
`;

export default ColorPicker;
