import React, { Component } from "react";
import { func, object } from "prop-types";
import { connect } from 'react-redux';
import { browserHistory } from "react-router";

import { updateUser } from "actions/auth";
import { selectUser } from "selectors/user";
import { selectCurrentChannel } from "selectors/channels";

import SetupStripePage from "containers/SetupStripe/SetupStripePage";
import CreateProfile from "components/CreateProfile";
import CreateChannel from "components/CreateChannel";
import Breadcrumbs from "components/Breadcrumbs";

import { hasPendingOnboarding } from "utils/helpers";
import { ONBOARDING_STEPS, USER_TYPES } from "appconstants";
import routes from "appconstants/routes";

import "./styles.css";
import { bindActionCreators } from "redux";

const stepsLabels = {
  [ONBOARDING_STEPS.CREATE_PROFILE]: "Create profile",
  [ONBOARDING_STEPS.CREATE_CHANNEL]: "Channel setup",
  [ONBOARDING_STEPS.SET_PAYMENT]: "Payment setup",
  [ONBOARDING_STEPS.INVITE_TEAM]: "Invite team",
};

class OnBoardingPage extends Component {
  constructor(props) {
    super(props);
    this.loadData();
    this.state = { steps: [] };
  }

  getSteps = () => {
    const { user: { channels, maxRole } } = this.props;
    const channelsOwned = channels && channels.filter(channel => channel.isOwner);

    let steps = [];

    if (maxRole !== USER_TYPES.GUEST) {
      steps.push(ONBOARDING_STEPS.CREATE_PROFILE);
    }

    if (channelsOwned.length) {
      steps.push(ONBOARDING_STEPS.CREATE_CHANNEL);
    }

    if (maxRole !== USER_TYPES.GUEST) {
      steps.push(ONBOARDING_STEPS.SET_PAYMENT);
    }

    // TODO: add invite team last step
    // if (![USER_TYPES.GUEST, USER_TYPES.CREATOR].includes(maxRole)) {
    //   steps.push(ONBOARDING_STEPS.INVITE_TEAM);
    // }

    return steps;
  }

  getCurrentStep = (steps) => {
    const { user: { about, photo, profession, state, stripeSetup, channels } } = this.props;

    const channelsOwned = channels && channels.filter(channel => channel.isOwner);
    const channelsNeedSetup = channelsOwned && channelsOwned.filter(channel => channel.setupPending);
    if(steps.includes(ONBOARDING_STEPS.CREATE_PROFILE) && (!about || !photo || !profession)) {
      return { selected: ONBOARDING_STEPS.CREATE_PROFILE };
    }

    if (steps.includes(ONBOARDING_STEPS.CREATE_CHANNEL) && channelsNeedSetup && channelsNeedSetup.length) {
      return { selected: ONBOARDING_STEPS.CREATE_CHANNEL, channelId: channelsNeedSetup[0].id };
    }

    if (steps.includes(ONBOARDING_STEPS.SET_PAYMENT) && !stripeSetup) {
      return { selected: ONBOARDING_STEPS.SET_PAYMENT };
    }
    return null;
  }

  loadData = async () => {
    await this.props.updateUser();
    await this.handleRedirect();
    const steps = this.getSteps();
    this.setState({ steps, ...this.getCurrentStep(steps) });
  };

  handleRedirect = async (isFree = false, successMessage) => {
    const { user } = this.props;
    if (!hasPendingOnboarding(user) || isFree) {
      browserHistory.push({pathname: routes.mainPage, state: successMessage });
    }
  }

  handleSuccess = (isFree = false, successMessage) => {
    this.handleRedirect(isFree, successMessage);
    this.setState({ ...this.getCurrentStep(this.state.steps) });
  }

  render() {
    const { selected, steps, channelId } = this.state;
    const breadcrumbSteps = steps.map(step => ({ id: step, label: stepsLabels[step] }));

    return (
      <div className="onboarding-container">
        <Breadcrumbs
          className="onboarding-breadcrumb"
          steps={breadcrumbSteps}
          selected={selected}
        />
        <div>
          {selected === ONBOARDING_STEPS.CREATE_PROFILE && (
            <CreateProfile onSuccess={this.handleSuccess} />
          )}
          {selected === ONBOARDING_STEPS.CREATE_CHANNEL && (
            <CreateChannel
              channelId={channelId}
              onSuccess={this.handleSuccess}
            />
          )}
          {selected === ONBOARDING_STEPS.SET_PAYMENT && <SetupStripePage />}
        </div>
      </div>
    );
  }
}

OnBoardingPage.propTypes = {
  user: object.isRequired,
  updateUser: func.isRequired
};

const mapStateToProps = state => ({ user: selectUser(state), currentChannel: selectCurrentChannel(state) });
const mapDispatchToProps = dispatch => bindActionCreators(({ updateUser }), dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingPage);
