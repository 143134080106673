const DATETIME_RANGE_TEMPLATE_REPLACE_STRING = 'END_OF_RANGE';
const MONTH_AND_DATE_WITH_ABBREV = 'MMM Do';
const FESTIVAL_EXPERIENCE_DATE = 'MMM DD'
export const TIME_FORMATS = {
  DATETIME: 'ddd, MMM Do | h:mma', // e.g. Tue, Dec 8th | 7:03pm
  // DATETIME_RANGE_TEMPLATE_REPLACE_STRING to be later replaced. Within brackets for escaping
  DATETIME_RANGE_TEMPLATE_REPLACE_STRING, // String to be replaced and complete a date range string.
  DATETIME_RANGE_TEMPLATE: `ddd, ${FESTIVAL_EXPERIENCE_DATE} - [${DATETIME_RANGE_TEMPLATE_REPLACE_STRING}]`, // e.g. Tue, Dec 8th - 10th | 7:03pm
  ERA: 'A', // e.g AM
  EVENT_DAY_WITH_ABBREV: 'Do', // e.g. 8th
  EVENT_DAY: 'D', // e.g. 8
  EVENT_MONTH: 'MMM', // e.g. DEC
  EVENT_TIME: 'h:mma', // e.g. 7:30pm
  EVENT_TIME_WITHOUT_ERA: 'h:mm', // e.g. 7:30
  FESTIVAL_EXPERIENCE_DATE, // e.g. Feb 08
  FESTIVAL_EXPERIENCE_DATE_FULL_MONTH: 'MMMM Do', // e.g. Feb 08th
  MONTH_AND_DATE_WITH_ABBREV, // e.g. Dec 10th
  MONTH_AND_DATETIME: 'lll', // e.g. Dec 8, 2020 7:30 PM
  NAMED_DAY_AND_MONTH: 'ddd, MMMM Do', // e.g. Mon, February 8th
  FULL_NAMED_DAY_AND_MONTH: 'dddd, MMMM Do', // e.g. Monday, February 8th
  TICKET_PAGE_DATE_AND_TIME: 'MMM D, h:mm a',
};

export const DATE_RANGE_FORMATS = {
  DATE: 'DATE',
  MONTH: 'MONTH',
  FULL_DATE: 'FULL_DATE'
};
