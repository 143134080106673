import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { map } from 'lodash';

const ListImages = ({ list, onClick }) => (
  <div className="container-list">
    { list && map(list, (item, index) => {
      return (
        <div key={index}>
          <div className="container-item" style={{ backgroundColor: `${item.color}` }}>
            <Link onClick={() => onClick(item)} >
              <img className="image-list" alt={item.name} src={item.images[0]} width="75px" height="75px" />
            </Link>
          </div>
          <Link className="name-item" onClick={() => onClick(item)}>
            {item.name}
          </Link>
        </div>
      );
    }) }
  </div>
);

ListImages.propTypes = {
  list: PropTypes.array,
  onClick: PropTypes.func
};

export default ListImages;
