import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Container, Icon, IconContainer, TimeInput, AmPmInput, InputContainer, TimeZone } from './styles';
import clockIcon from 'images/clock.png';

export default class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment(props.input.value).format('h:mm'),
      ampm: moment(props.input.value).format('A'),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      time: moment(newProps.input.value).format('h:mm'),
      ampm: moment(newProps.input.value).format('A'),
    });
  }

  onChange = (event, input) => {
    this.setState({ [input]: event.target.value });
  }

  onBlurTime = (event) => {
    const { input: { onChange, value } } = this.props;

    const previousValue = moment(value);
    const stringValue = event.target.value;
    const previousAmpm = previousValue.format('A');
    const time = moment(`${stringValue.trim()} ${previousAmpm}`, ['h A', 'h:mm A', 'h.mm A'], true);
    const previousTime = previousValue.format('h:mm');

    if (time.isValid()) {
      const currentTime = time.format('h:mm');

      if (currentTime !== previousTime) {
        const newValue = moment(previousValue);

        newValue.hour(time.hour());
        newValue.minute(time.minute());
        onChange(newValue.toDate());
      }
    } else {
      this.setState({ time: previousTime });
    }
  }

  onBlurAmPm = (event) => {
    const { input: { onChange, value } } = this.props;

    const previousValue = moment(value);
    const stringValue = event.target.value.toUpperCase();
    const previousAmpm = previousValue.format('A');

    if (stringValue === 'AM' || stringValue === 'PM') {
      if (stringValue !== previousAmpm) {
        const newValue = moment(previousValue);

        if (stringValue === 'AM') {
          newValue.subtract(12, 'h');
        } else {
          newValue.add(12, 'h');
        }

        onChange(newValue.toDate());
      }
    } else {
      this.setState({ ampm: previousAmpm });
    }
  }

  onFocus = (event) => {
    event.target.select();
  }

  render() {
    const { disabled, defaultTimezone } = this.props;
    const timezone = defaultTimezone ? moment().tz(defaultTimezone).zoneAbbr() : moment().tz(moment.tz.guess()).zoneAbbr();

    return (
      <Container disabled={disabled}>
        <IconContainer>
          <Icon alt="clock" src={clockIcon} />
        </IconContainer>
        <InputContainer>
          <TimeInput
            onBlur={this.onBlurTime}
            onChange={(e) => { this.onChange(e, 'time'); }}
            onFocus={this.onFocus}
            value={this.state.time}
            disabled={disabled}
          />
        </InputContainer>
        <div style={{ flexGrow: '1' }} />
        <InputContainer>
          <AmPmInput
            onBlur={this.onBlurAmPm}
            onChange={(e) => { this.onChange(e, 'ampm'); }}
            onFocus={this.onFocus}
            value={this.state.ampm}
            disabled={disabled}
          />
        </InputContainer>
        <TimeZone>{timezone}</TimeZone>
      </Container>
    );
  }
}

TimePicker.propTypes = {
  input: PropTypes.any,
  disabled: PropTypes.bool
};
