import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import './styles.css';
import { Link } from 'react-router'

const Wrapper = styled.div`
  float: left;
  width: 100%;
`;

const FacilityList = styled.ul`
  text-align: center;
  margin: 0;
  padding: ${({ isFestival }) => isFestival ? `12px 0 94px` : `0px 0 110px`};
  font-size: 15px;
  font-family: "CircularStd Book";
  display: block`
  ;

const FacilityItem = styled.li`
  padding-right: 50px;
  list-style-type: none;
  float: left;
`;

const ExperienceTagsDetails = props => {
  const { experience } = props;
  const [food] = experience.food;
  const [drink] = experience.drinks;

  let guestsCount = experience.totalTickets;

  if (experience.festival) {
    guestsCount = '';
  } else if (guestsCount === 0 || typeof guestsCount === 'undefined') {
    guestsCount = 'free';
  } else if (guestsCount > 0) {
    guestsCount += ' guests max';
  }

  let drinkClass = 'tagDetails drinkSpan';
  if (drink && drink.name) {
    if (drink.name.indexOf('beer') > -1) {
      drinkClass = 'tagDetails beerSpan';
    } else if (drink.name.indexOf('tea') > -1) {
      drinkClass = 'tagDetails teaSpan';
    }
  }
  return (
    <Wrapper className="details-wrapper">
      <FacilityList isFestival={experience.festival}>
        {
          experience.festival ? (
            <FestivalDetails>
              <FestivalDetailText>
                This experience is part of
              </FestivalDetailText>
              <FestivalDetailsLink to={`/in/${experience.festival.channel.urlSlug}/f/${experience.festival.alias}`}>
                {experience.festival.name}
              </FestivalDetailsLink>
            </FestivalDetails>
          ) : (
            <>
              {food && <FacilityItem><span className="tagDetails foodSpan" />{food.description}</FacilityItem>}
              {drink && <FacilityItem><span className={drinkClass} /> {drink.description} </FacilityItem>}
              {experience.location && experience.location.space && <FacilityItem><span className="tagDetails locationSpan" />{capitalize(experience.location.space.replace('_', ' '))}</FacilityItem>}
              {guestsCount && <FacilityItem><span className="tagDetails peopleSpan" />{guestsCount}</FacilityItem>}
            </>
          )
        }
      </FacilityList>
      <div className="details-line" />
    </Wrapper>
  );
};

const FestivalDetails = styled.div`
  text-align: left;
  height: 0;
`;

const FestivalDetailText = styled.div``;

const FestivalDetailsLink = styled(Link)`
  color: #A55BFF;
  font-size: 26px;

  &:hover {
    text-decoration: none;
  }
`;

ExperienceTagsDetails.propTypes = {
  experience: PropTypes.object,
};

export default ExperienceTagsDetails;
