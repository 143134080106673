/* eslint-disable react/self-closing-comp */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Router } from 'react-router';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Favicon from 'react-favicon';
import { QueryClient, QueryClientProvider } from 'react-query'

import { store, persistor } from 'redux_store/configureStore';
import configureTheme from 'theme/configureTheme';
import routes from './routes';
import LogRocket from 'logrocket';
import { CircularProgress } from 'material-ui';
import { history } from './utils/history';

const muiTheme = configureTheme();
const faviconAddress = 'https://s3-us-west-2.amazonaws.com/with-presence/favicon.png';

if(process.env.REACT_APP_LOGROCKET){
  LogRocket.init(process.env.REACT_APP_LOGROCKET);
}

const queryClient = new QueryClient();

ReactDOM.render(
  <div>
    <Helmet>
      {process.env.NODE_ENV === "production" && (
        <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0099/5578.js" async="async"></script>
      )}
    </Helmet>
    <Favicon url={faviconAddress} />
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider muiTheme={muiTheme}>
          <PersistGate loading={<CircularProgress />} persistor={persistor}>
            <Router history={history} routes={routes} />
          </PersistGate>
        </MuiThemeProvider>
      </QueryClientProvider>
    </Provider>
  </div>,
  document.getElementById('app'),
);
