import React from 'react';


const styles = {
  root: {
    textAlign: 'center',
    margin: 20,
    marginTop: 40,
  },
};

const NotFoundPage = () => {
  return (
    <div style={styles.root}>
      <h2>404 page not found</h2>
      <p>We are sorry but the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundPage;
