import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardMedia, CardTitle, CardText } from 'material-ui';
import { browserHistory } from 'react-router';
import ImageNotFound from 'images/no-image-box.png';
import { getResizedImageURL } from 'utils/helpers';
import { IMAGE_SIZES } from 'appconstants';
import { Typography } from '@material-ui/core';

const TGTicket = props => {
  const { title, date, imageUrl, mobFont, channel } = props;
  const newImageUrl = imageUrl ? getResizedImageURL(imageUrl, IMAGE_SIZES.MEDIUM) : ImageNotFound;

  let fontName = '15px';
  let fontArtist = '12px';
  let fontDate = '13px';
  let titleLineHeight = '0';
  if (mobFont) {
    fontName = '25px';
    fontArtist = '17px';
    fontDate = '15px';
    titleLineHeight = '16px';
  }

  const onClickHandler = () => {
    const { ticketURL, urlSlug, alias, isFestival } = props;
    const experienceURL = `/in/${urlSlug}/${isFestival ? 'f/' : ''}${alias}`;

    if(ticketURL){
      window.open(ticketURL);
    }
    else {
      browserHistory.push(experienceURL);
    }
  };

  const styles = {
    ticket: {
      height: '300px',
      backgroundImage: `url(${newImageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    },
    container: {
      lineHeight: titleLineHeight,
      margin: '10px',
    },
    titleStyle: {
      fontSize: fontName,
      fontFamily: 'CircularStd Medium',
      color: '#000000',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    subtitleStyle: {
      fontSize: fontArtist,
      fontFamily: 'CircularStd Book',
      color: '#A6A6A6',
    },
    dateStyle: {
      fontSize: fontDate,
      fontFamily: 'CircularStd Book',
      // paddingTop: '10px',
      color: '#9b87f6',
      padding: 0
      // textOverflow: 'wrap'
      // height: '50px'
    },
  };
  return (
    <span style={styles.container}>
      <Card className="my-ticket" style={{ boxShadow: 'rgba(0, 0, 0, 0) -18px 2px 7px 11px, rgba(0, 0, 0, 0.12) 0px 11px 20px 0px' }}>
        <CardMedia
          onClick={onClickHandler}
          style={styles.ticket}
        />
        <CardTitle
          title={title}
          titleStyle={styles.titleStyle}
          subtitle={channel}
          subtitleStyle={styles.subtitleStyle}
        />
        <CardText style={{padding: 0, paddingLeft: 16, paddingRight: 16}}>
          <Typography style={styles.dateStyle}>
          {date}
          </Typography>

        </CardText>
      </Card>
    </span>
  );
};

TGTicket.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  imageUrl: PropTypes.string,
  urlSlug: PropTypes.string,
  alias: PropTypes.string,
  mobFont: PropTypes.bool,
  ticketURL: PropTypes.string,
  channel: PropTypes.string,
};

const mapStateToProps = state => ({ artist: state.artist });

export default connect(mapStateToProps)(TGTicket);
