import React, { Component } from 'react';
import { Dialog, RaisedButton } from 'material-ui';

import { browserHistory } from 'react-router';

import feedback from 'images/feedback.png';

import Loading from 'components/Loading';

const styles = {
  bodyStyle: {
    padding: '0',
  },
  title: {
    fontSize: '24px',
    backgroundColor: '#fff',
    color: "#000",
    textAlign: 'center'
  },
  contentStyle: {
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    maxHeight: '510px',
    width: '437px',
  },
  description: {
    color: "#000000",
    fontFamily: "CircularStd Book",
    fontSize: "16px",
    fontWeight: "300",
    letterSpacing: "0",
    lineHeight: "29px",
    textAlign: "center",
    margin: "30px"
  },
  link: {
    color: '#BBBBBB',
    fontFamily: "CircularStd Book",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "27.5px",
    textAlign: "center",
  },
  buttonFontStyle: {
    color: "#FFFFFF",
    fontFamily: "CircularStd Book",
    fontSize: "23px",
    fontWeight: "300",
    letterSpacing: "0",
    lineHeight: "60px",

  }
};

class DialogFeedBack extends Component {
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.goToExperiences = this.goToExperiences.bind(this)
    this.state = {
      dialogOpen: true,
      loading: false,
    };
  }

  goToExperiences() {
    browserHistory.push(`/${this.props.channelSlug}/dashboard`);
  }
  closeDialog() {
    this.setState({
      loading: true,
    });
    this.goToExperiences();
  }

  openDialog() {
    this.setState({
      dialogOpen: true,
    });
  }

  render() {
    const {loading} = this.state;
    return (loading
      ?
        <Loading />
      :
        <Dialog
          contentStyle={styles.contentStyle}
          open={this.state.dialogOpen}
          onRequestClose={this.closeDialog}
        >
          <div style={styles.title}>Help us improve</div>
          <div style={{textAlign: 'center'}}>
            <img src={feedback} alt="" />
            <div style={styles.description} >The PRESENCE team is working on improving our user experience and we’d like your feedback along the way.</div>
            <RaisedButton
              label="Yes, let's do it"
              backgroundColor={'#9B63F8'}
              labelStyle={styles.buttonFontStyle}
              href="https://withpresence.typeform.com/to/OV6I9s"
              target="_blank"
              labelPosition="before"
              style={{width: "260px", height: "60px", margin: "30px"}}
            /><br />
            <button onClick={this.closeDialog} style={styles.link}>No thanks</button>
          </div>
        </Dialog>
    );
  }

}

export default DialogFeedBack;
